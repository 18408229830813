import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { fetchUrl, handleLogoutOnSessionExpire } from '../../Module/Fetch';
import i18n from '../../i18n';
import { UploadOnAddEvent } from '@progress/kendo-react-upload';
import { stat } from 'fs';
import { ORDER_STATUS_ID_SHIPPED } from '../constants';
import { Carrier } from '../interfaces';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { InputChangeEvent } from '@progress/kendo-react-inputs';


// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface InvoiceState {
    isLoading: boolean;
    invoiceUploaded: boolean,
    uploadInvoiceDialogIsVisible: boolean,
    viewInvoiceWindowIsVisible: boolean,
    binaryInvoiceFile: string | null,
    message: string | null,
    errorMessage: string | null,
    invoiceFile: File | undefined,
    invoiceNumber: string | null,
    orderId: number,
    displayShippingForm: boolean,
    carriers: Carrier[],
    carrierId: number | null,
    wayBillNumber: string | null
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestUploadInvoiceOrdersAction {
    type: 'REQUEST_UPLOAD_INVOICE_ORDER';
}

interface UploadInvoiceOrdersAction {
    type: 'UPLOAD_INVOICE_ORDER';
    invoiceUploaded: boolean,
    uploadInvoiceDialogIsVisible: boolean,
    errorMessage: string | null,
    invoiceFile: File | undefined,
    updateOrderStatus: boolean,
    orderId: number,
}

interface RequestFetchInvoiceAction {
    type: 'REQUEST_FETCH_INVOICE';
}

interface RecieveFetchInvoiceAction {
    type: 'RECEIVE_REQUEST_FETCH_INVOICE';
    binaryInvoiceFile: string | null,
    viewInvoiceWindowIsVisible: boolean,
}

interface RequestUpdateOrderStatusAction {
    type: 'REQUEST_UPDATE_ORDER_STATUS';
}

interface ReceiveUpdateOrderStatusAction {
    type: 'RECEIVE_UPDATE_ORDER_STATUS';
    confirmDialogIsVisible: boolean,
    alertDialogIsVisible: boolean,
    message: string | null
}

interface SetInvoiceFileAction {
    type: 'SET_INVOICE_FILE_ACTION';
    invoiceFile: File | undefined,
}

interface SetInvoiceNumberAction {
    type: 'SET_INVOICE_NUMBER_ACTION';
    invoiceNumber: string | null,
}

interface SetShippingFormVisibilityAction {
    type: 'SET_SHIPPING_FORM_VISIBILITY';
    displayShippingForm: boolean,
}

interface ReceiveCarriersAction {
    type: 'RECEIVE_CARRIERS';
    carriers: Carrier[],
}

interface CarrierIdChangeAction {
    type: 'CARRIER_ID_CAHNGED';
    carrierId: number,
}

interface WayBillNumberChangeAction {
    type: 'WAY_BILL_NUMBER_CAHNGED';
    wayBillNumber: string,
}

interface OrderTrackingSavedAction {
    type: 'ORDER_TRACKING_SAVED';
}

interface DisplayErrorMessageAction {
    type: 'DISPLAY_ERROR_MESSAGE';
    errorMessage: string
}

interface OnOpenUploadInvoiceAction {
    type: 'OPEN_UPLOAD_INVOICE_ACTION';
    orderId: number
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = UploadInvoiceOrdersAction | RequestUploadInvoiceOrdersAction |
    RequestFetchInvoiceAction | RecieveFetchInvoiceAction |
    RequestUpdateOrderStatusAction | ReceiveUpdateOrderStatusAction |
    SetInvoiceFileAction | SetInvoiceNumberAction | SetShippingFormVisibilityAction |
    ReceiveCarriersAction | CarrierIdChangeAction | WayBillNumberChangeAction |
    OrderTrackingSavedAction | DisplayErrorMessageAction | OnOpenUploadInvoiceAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).


export const actionCreators = {
    uploadInvoiceFile: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'REQUEST_UPLOAD_INVOICE_ORDER' });
        var invoiceFile = undefined;
        var invoiceNumber = null;
        var orderId = 0;
        var appState = getState();
        var saveShippingInformation = false;
        var carrierId = null;
        var wayBillNumber = null;
        if (appState.invoices != undefined) {
            invoiceFile = appState.invoices.invoiceFile;
            invoiceNumber = appState.invoices.invoiceNumber
            orderId = appState.invoices.orderId
            saveShippingInformation = appState.invoices.displayShippingForm
            carrierId = appState.invoices.carrierId;
            wayBillNumber = appState.invoices.wayBillNumber
        }
        if (invoiceFile == undefined) {
            dispatch({ type: 'DISPLAY_ERROR_MESSAGE', errorMessage: i18n.t("invoiceFileIsRequired") });
        } else if (saveShippingInformation && (!carrierId || !wayBillNumber)) {
            dispatch({ type: 'DISPLAY_ERROR_MESSAGE', errorMessage: i18n.t("shippingInformationAreRequired") });
        } else {
            const formData = new FormData();
            formData.append('invoiceFile', invoiceFile);
            formData.append('orderId', orderId as unknown as string);
            formData.append('invoiceNumber', invoiceNumber as unknown as string);
            fetchUrl("/orders/UploadInvoice", "POST", { body: formData }, undefined)
                .then((response) => {
                    handleLogoutOnSessionExpire(response);
                    return response.json()
                })
                .then((results) => {
                    if (results == 1) {
                        dispatch({
                            type: 'UPLOAD_INVOICE_ORDER',
                            invoiceUploaded: true,
                            uploadInvoiceDialogIsVisible: false,
                            errorMessage: null,
                            invoiceFile: undefined,
                            updateOrderStatus: true,
                            orderId: orderId
                        });
                    } else {
                        dispatch({
                            type: 'UPLOAD_INVOICE_ORDER',
                            invoiceUploaded: false,
                            uploadInvoiceDialogIsVisible: true,
                            errorMessage: i18n.t("invoiceFileUploadFailed"),
                            invoiceFile: undefined,
                            updateOrderStatus: false,
                            orderId: orderId
                        });
                    }
                });

            if (saveShippingInformation && carrierId && wayBillNumber) {
                const formData = new FormData();
                formData.append('carrierId', carrierId as unknown as string);
                formData.append('orderId', orderId as unknown as string);
                formData.append('wayBillNumber', wayBillNumber as unknown as string);
                fetchUrl("/OrderTracking/AddOrderTracking", "POST", { body: formData }, undefined)
                    .then((response) => {
                        handleLogoutOnSessionExpire(response);
                        return response.json()
                    })
                    .then((results) => {
                        dispatch({ type: 'ORDER_TRACKING_SAVED' });
                    });
            }
        }
    },
    onSetInvoiceNumber: (invoiceNumber: string | null): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'SET_INVOICE_NUMBER_ACTION',
            invoiceNumber: invoiceNumber
        });
    },
    onToggleUploadInvoiceDialog: (state: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'UPLOAD_INVOICE_ORDER',
            invoiceUploaded: false,
            uploadInvoiceDialogIsVisible: state,
            errorMessage: null,
            invoiceFile: undefined,
            updateOrderStatus: false,
            orderId: 0
        });
    },
    onCloseUploadInvoiceDialog: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'UPLOAD_INVOICE_ORDER',
            invoiceUploaded: false,
            uploadInvoiceDialogIsVisible: false,
            errorMessage: null,
            invoiceFile: undefined,
            updateOrderStatus: false,
            orderId: 0
        });
    },
    onOpenUploadInvoiceDialog: (orderId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({type: 'OPEN_UPLOAD_INVOICE_ACTION', orderId: orderId});
    },
    onFetchInvoice: (orderId: number | null): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'REQUEST_FETCH_INVOICE' });
        fetchUrl("/orders/GetInvoice?orderId=" + orderId, "GET")
            .then((response) => {
                handleLogoutOnSessionExpire(response);
                return response.json()
            })
            .then((results) => {
                if (results != null) {
                    dispatch({ type: 'RECEIVE_REQUEST_FETCH_INVOICE', binaryInvoiceFile: results, viewInvoiceWindowIsVisible: true });
                }
            });
    },
    onCloseViewInvoiceWindow: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'RECEIVE_REQUEST_FETCH_INVOICE', binaryInvoiceFile: null, viewInvoiceWindowIsVisible: false });
    },
    onInvoiceChange: (event: UploadOnAddEvent): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (event.affectedFiles != undefined) {
            var el = event.affectedFiles[0];
            if (el !== undefined) {
                var file = el.getRawFile?.();
                dispatch({ type: 'SET_INVOICE_FILE_ACTION', invoiceFile: file });
            }
        }
    },
    onUpdateOrderStatus: (ordersIds: number[], statusId: number | null): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (statusId != null) {
            dispatch({ type: "REQUEST_UPDATE_ORDER_STATUS" });
            var appState = getState();
            fetchUrl("/orders/UpdateCustomerOrdersStatus", "POST", { body: JSON.stringify({ orderIds: ordersIds, statusId: statusId }) }, { "Content-Type": "application/json" })
                .then((response) => {
                    handleLogoutOnSessionExpire(response);
                    return response.json()
                })
                .then((json) => {
                    var message = "";
                    var updatedOrders = 0;
                    var notUpdatedOrders = 0;
                    json.forEach((orderResult: any) => {
                        if (orderResult.isUpdated) {
                            updatedOrders++;
                        } else {
                            notUpdatedOrders++;
                        }
                    });

                    if (updatedOrders > 0) {
                        message += updatedOrders + " " + i18n.t("ordersStatusUpdateSuccessMessage") + "<br/>";
                    }

                    if (notUpdatedOrders > 0) {
                        message += "<br/>" + notUpdatedOrders + " " + i18n.t("ordersStatusUpdateFailureMessage");
                    }

                    var ds = {}

                    if (appState.customerOrders != undefined) {
                        ds = appState.customerOrders.dataState;
                    }

                    dispatch({ type: "RECEIVE_UPDATE_ORDER_STATUS", alertDialogIsVisible: true, confirmDialogIsVisible: false, message: message });
                });
        }

    },
    onCloseConfirmDialog: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "RECEIVE_UPDATE_ORDER_STATUS", alertDialogIsVisible: false, confirmDialogIsVisible: false, message: null });
    },
    onOpenConfirmDialog: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "RECEIVE_UPDATE_ORDER_STATUS", alertDialogIsVisible: false, confirmDialogIsVisible: true, message: null });
    },
    onCloseAlertDialog: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "RECEIVE_UPDATE_ORDER_STATUS", alertDialogIsVisible: false, confirmDialogIsVisible: false, message: null });
    },
    onOpenAlertDialog: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "RECEIVE_UPDATE_ORDER_STATUS", alertDialogIsVisible: true, confirmDialogIsVisible: true, message: null });
    },
    setShippingFormVisiblilty: (orderStatusId: number | null): AppThunkAction<KnownAction> => (dispatch, getState) => {
        var displayShippingForm = false;
        if (orderStatusId != undefined && orderStatusId == ORDER_STATUS_ID_SHIPPED) {
            displayShippingForm = true
        }
        dispatch({ type: "SET_SHIPPING_FORM_VISIBILITY", displayShippingForm: displayShippingForm });
    },
    onFetchCarriers: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetchUrl("/carriers/GetCarriers", "GET")
            .then((response) => {
                handleLogoutOnSessionExpire(response);
                return response.json()
            })
            .then((carriers) => {
                dispatch({ type: "RECEIVE_CARRIERS", carriers: carriers });
            });
    },
    onCarrierIdChange: (e: DropDownListChangeEvent): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "CARRIER_ID_CAHNGED", carrierId: e.target.value.id });
    },
    onWayBillNumberChange: (e: InputChangeEvent): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "WAY_BILL_NUMBER_CAHNGED", wayBillNumber: e.target.value as unknown as string });
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: InvoiceState = {
    isLoading: false,
    invoiceUploaded: false,
    uploadInvoiceDialogIsVisible: false,
    viewInvoiceWindowIsVisible: false,
    binaryInvoiceFile: null,
    message: null,
    errorMessage: null,
    invoiceFile: undefined,
    invoiceNumber: null,
    orderId: 0,
    displayShippingForm: false,
    carriers: [],
    carrierId: null,
    wayBillNumber: null
};

export const reducer: Reducer<InvoiceState> = (state: InvoiceState | undefined, incomingAction: Action): InvoiceState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'UPLOAD_INVOICE_ORDER':
            return {
                isLoading: false,
                invoiceUploaded: action.invoiceUploaded,
                uploadInvoiceDialogIsVisible: action.uploadInvoiceDialogIsVisible,
                viewInvoiceWindowIsVisible: state.viewInvoiceWindowIsVisible,
                binaryInvoiceFile: state.binaryInvoiceFile,
                message: state.message,
                errorMessage: state.errorMessage,
                invoiceFile: state.invoiceFile,
                invoiceNumber: state.invoiceNumber,
                orderId: action.orderId,
                displayShippingForm: state.displayShippingForm,
                carriers: state.carriers,
                carrierId: state.carrierId,
                wayBillNumber: state.wayBillNumber
            };
        case 'REQUEST_UPLOAD_INVOICE_ORDER':
            return {
                isLoading: false,
                invoiceUploaded: state.invoiceUploaded,
                uploadInvoiceDialogIsVisible: state.uploadInvoiceDialogIsVisible,
                viewInvoiceWindowIsVisible: state.viewInvoiceWindowIsVisible,
                binaryInvoiceFile: null,
                message: state.message,
                errorMessage: null,
                invoiceFile: state.invoiceFile,
                invoiceNumber: state.invoiceNumber,
                orderId: state.orderId,
                displayShippingForm: state.displayShippingForm,
                carriers: state.carriers,
                carrierId: state.carrierId,
                wayBillNumber: state.wayBillNumber
            };
        case 'REQUEST_FETCH_INVOICE':
            return {
                isLoading: true,
                invoiceUploaded: state.invoiceUploaded,
                uploadInvoiceDialogIsVisible: state.uploadInvoiceDialogIsVisible,
                viewInvoiceWindowIsVisible: state.viewInvoiceWindowIsVisible,
                binaryInvoiceFile: state.binaryInvoiceFile,
                message: state.message,
                errorMessage: state.errorMessage,
                invoiceFile: state.invoiceFile,
                invoiceNumber: state.invoiceNumber,
                orderId: state.orderId,
                displayShippingForm: state.displayShippingForm,
                carriers: state.carriers,
                carrierId: state.carrierId,
                wayBillNumber: state.wayBillNumber
            };
        case 'RECEIVE_REQUEST_FETCH_INVOICE':
            return {
                isLoading: false,
                invoiceUploaded: state.invoiceUploaded,
                uploadInvoiceDialogIsVisible: state.uploadInvoiceDialogIsVisible,
                viewInvoiceWindowIsVisible: action.viewInvoiceWindowIsVisible,
                binaryInvoiceFile: action.binaryInvoiceFile,
                message: state.message,
                errorMessage: state.errorMessage,
                invoiceFile: state.invoiceFile,
                invoiceNumber: state.invoiceNumber,
                orderId: state.orderId,
                displayShippingForm: state.displayShippingForm,
                carriers: state.carriers,
                carrierId: state.carrierId,
                wayBillNumber: state.wayBillNumber
            };
        case 'SET_INVOICE_FILE_ACTION':
            return {
                isLoading: false,
                invoiceUploaded: state.invoiceUploaded,
                uploadInvoiceDialogIsVisible: state.uploadInvoiceDialogIsVisible,
                viewInvoiceWindowIsVisible: state.viewInvoiceWindowIsVisible,
                binaryInvoiceFile: state.binaryInvoiceFile,
                message: state.message,
                errorMessage: state.errorMessage,
                invoiceFile: action.invoiceFile,
                invoiceNumber: state.invoiceNumber,
                orderId: state.orderId,
                displayShippingForm: state.displayShippingForm,
                carriers: state.carriers,
                carrierId: state.carrierId,
                wayBillNumber: state.wayBillNumber
            };

        case 'SET_INVOICE_NUMBER_ACTION':
            return {
                isLoading: false,
                invoiceUploaded: state.invoiceUploaded,
                uploadInvoiceDialogIsVisible: state.uploadInvoiceDialogIsVisible,
                viewInvoiceWindowIsVisible: state.viewInvoiceWindowIsVisible,
                binaryInvoiceFile: state.binaryInvoiceFile,
                message: state.message,
                errorMessage: state.errorMessage,
                invoiceFile: state.invoiceFile,
                invoiceNumber: action.invoiceNumber,
                orderId: state.orderId,
                displayShippingForm: state.displayShippingForm,
                carriers: state.carriers,
                carrierId: state.carrierId,
                wayBillNumber: state.wayBillNumber
            };
        case 'SET_SHIPPING_FORM_VISIBILITY':
            return {
                isLoading: false,
                invoiceUploaded: state.invoiceUploaded,
                uploadInvoiceDialogIsVisible: state.uploadInvoiceDialogIsVisible,
                viewInvoiceWindowIsVisible: state.viewInvoiceWindowIsVisible,
                binaryInvoiceFile: state.binaryInvoiceFile,
                message: state.message,
                errorMessage: state.errorMessage,
                invoiceFile: state.invoiceFile,
                invoiceNumber: state.invoiceNumber,
                orderId: state.orderId,
                displayShippingForm: action.displayShippingForm,
                carriers: state.carriers,
                carrierId: state.carrierId,
                wayBillNumber: state.wayBillNumber
            };
        case 'RECEIVE_CARRIERS':
            return {
                isLoading: false,
                invoiceUploaded: state.invoiceUploaded,
                uploadInvoiceDialogIsVisible: state.uploadInvoiceDialogIsVisible,
                viewInvoiceWindowIsVisible: state.viewInvoiceWindowIsVisible,
                binaryInvoiceFile: state.binaryInvoiceFile,
                message: state.message,
                errorMessage: state.errorMessage,
                invoiceFile: state.invoiceFile,
                invoiceNumber: state.invoiceNumber,
                orderId: state.orderId,
                displayShippingForm: state.displayShippingForm,
                carriers: action.carriers,
                carrierId: state.carrierId,
                wayBillNumber: state.wayBillNumber
            };
        case 'CARRIER_ID_CAHNGED':
            return {
                isLoading: false,
                invoiceUploaded: state.invoiceUploaded,
                uploadInvoiceDialogIsVisible: state.uploadInvoiceDialogIsVisible,
                viewInvoiceWindowIsVisible: state.viewInvoiceWindowIsVisible,
                binaryInvoiceFile: state.binaryInvoiceFile,
                message: state.message,
                errorMessage: state.errorMessage,
                invoiceFile: state.invoiceFile,
                invoiceNumber: state.invoiceNumber,
                orderId: state.orderId,
                displayShippingForm: state.displayShippingForm,
                carriers: state.carriers,
                carrierId: action.carrierId,
                wayBillNumber: state.wayBillNumber
            };
        case 'WAY_BILL_NUMBER_CAHNGED':
            return {
                isLoading: false,
                invoiceUploaded: state.invoiceUploaded,
                uploadInvoiceDialogIsVisible: state.uploadInvoiceDialogIsVisible,
                viewInvoiceWindowIsVisible: state.viewInvoiceWindowIsVisible,
                binaryInvoiceFile: state.binaryInvoiceFile,
                message: state.message,
                errorMessage: state.errorMessage,
                invoiceFile: state.invoiceFile,
                invoiceNumber: state.invoiceNumber,
                orderId: state.orderId,
                displayShippingForm: state.displayShippingForm,
                carriers: state.carriers,
                carrierId: state.carrierId,
                wayBillNumber: action.wayBillNumber
            };
        case 'ORDER_TRACKING_SAVED':
            return {
                isLoading: false,
                invoiceUploaded: state.invoiceUploaded,
                uploadInvoiceDialogIsVisible: state.uploadInvoiceDialogIsVisible,
                viewInvoiceWindowIsVisible: state.viewInvoiceWindowIsVisible,
                binaryInvoiceFile: state.binaryInvoiceFile,
                message: state.message,
                errorMessage: state.errorMessage,
                invoiceFile: state.invoiceFile,
                invoiceNumber: state.invoiceNumber,
                orderId: state.orderId,
                displayShippingForm: state.displayShippingForm,
                carriers: state.carriers,
                carrierId: state.carrierId,
                wayBillNumber: state.wayBillNumber
            };
        case 'DISPLAY_ERROR_MESSAGE':
            return {
                isLoading: false,
                invoiceUploaded: state.invoiceUploaded,
                uploadInvoiceDialogIsVisible: state.uploadInvoiceDialogIsVisible,
                viewInvoiceWindowIsVisible: state.viewInvoiceWindowIsVisible,
                binaryInvoiceFile: state.binaryInvoiceFile,
                message: state.message,
                errorMessage: action.errorMessage,
                invoiceFile: state.invoiceFile,
                invoiceNumber: state.invoiceNumber,
                orderId: state.orderId,
                displayShippingForm: state.displayShippingForm,
                carriers: state.carriers,
                carrierId: state.carrierId,
                wayBillNumber: state.wayBillNumber
            };
            case 'OPEN_UPLOAD_INVOICE_ACTION':
                return {
                    isLoading: false,
                    invoiceUploaded: false,
                    uploadInvoiceDialogIsVisible: true,
                    viewInvoiceWindowIsVisible: false,
                    binaryInvoiceFile: null,
                    message: null,
                    errorMessage: null,
                    invoiceFile: undefined,
                    invoiceNumber: null,
                    orderId: action.orderId,
                    displayShippingForm: state.displayShippingForm,
                    carriers: state.carriers,
                    carrierId: null,
                    wayBillNumber: null
                };
        default:
            return state;
    }
};
