import * as React from 'react';
import './NavMenu.css';
import { fetchUrl, handleLogoutOnSessionExpire } from '../Module/Fetch';

export default class SearchBar extends React.PureComponent<{ accessToken: string | null }, { logo: string }> {
    public state = {
        logo: ""
    };

    private fetchLogo() {
        fetchUrl("/dealers/GetLogo", "GET")
            .then(response => {
                handleLogoutOnSessionExpire(response);
                return response.json()
            })
            .then(logo => {
                this.setState({ logo: logo })
            });
    }

    componentDidMount = () => {
        this.fetchLogo();
    }

    public render() {
        return (
            <>
                <div className="row justify-content-end ml-sm-auto col-lg-12 px-md-4">
                    <div className="col-lg-4 col-md-4 pb-3 margin-logo-auto">
                        <img src={this.state.logo} className="img-fluid" />
                    </div>
                    <div className="col-lg-6 col-md-8">

                    </div>
                </div>

            </>
        );
    }
}
