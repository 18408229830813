import { CompositeFilterDescriptor, GroupDescriptor, SortDescriptor, State, toDataSourceRequestString } from '@progress/kendo-data-query';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { DealerModuleParams, Tab } from '../interfaces';
import { TabStripSelectEventArguments } from '@progress/kendo-react-layout';
import { fetchUrl, handleLogoutOnSessionExpire } from '../../Module/Fetch';



// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ConnectWiseState {
    connectwiseParams: DealerModuleParams | null
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface ReceiveDealerModuleParamsAction {
    type: 'RECEIVE_DEALER_MODULE_PARAMS';
    connectwiseParams: any
}

interface RequestDealerModuleParamsAction {
    type: 'REQUEST_DEALER_MODULE_PARAMS';
}


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = ReceiveDealerModuleParamsAction | RequestDealerModuleParamsAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).


export const actionCreators = {
    fetchModuleParams: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState.customerOrders !== undefined) {
            fetchUrl(`/dealers/GetModulesParams?moduleName=TONY-CONNECTWISE`, "GET")
                .then((response) => {
                    handleLogoutOnSessionExpire(response);
                    return response.json() as Promise<DealerModuleParams>
                })
                .then((params) => {
                    dispatch({ type: 'RECEIVE_DEALER_MODULE_PARAMS', connectwiseParams: params });
                });
            dispatch({ type: 'REQUEST_DEALER_MODULE_PARAMS' });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: ConnectWiseState = { connectwiseParams: null };

export const reducer: Reducer<ConnectWiseState> = (state: ConnectWiseState | undefined, incomingAction: Action): ConnectWiseState => {
    if (state === undefined) {
        return unloadedState;
    }


    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'RECEIVE_DEALER_MODULE_PARAMS':
            return {
                ...state,
                connectwiseParams: action.connectwiseParams
            };
        case 'REQUEST_DEALER_MODULE_PARAMS':
            return {
                ...state,
                connectwiseParams: null
            };
        
        default:
            return state;
    }
};
