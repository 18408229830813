import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { CopiedItemsResult, CustomerOrder, CustomerOrderHeader, CustomerOrderItem, CustomerOrderItemsDataRecievedResults, ItemToCopy, OrderAddress, OrderStatus, OrderTotal, OrderTracking, ShippingMethod, SplitOrderItem, SplitOrderItemsResult } from '../interfaces';
import { fetchUrl, handleLogoutOnSessionExpire } from '../../Module/Fetch';
import { State, toDataSourceRequestString } from '@progress/kendo-data-query';
import i18n from '../../i18n';
import { CookiesUtil } from '../../Module/CookiesUtil';
import { InputChangeEvent } from '@progress/kendo-react-inputs';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';


export interface CustomerOrderItemsState {
    init: boolean,
    isLoading: boolean;
    orderItems: CustomerOrderItem[];
    dataState: State | null,
    total: number,
    splitOrderDialogIsVisible: boolean,
    confirmRestimateShipping: boolean,
    confirmShippingRateDialogIsVisible: boolean,
    suggestedShippingAmount: number | null,
    enterEditShipping: boolean,
    refreshCutomerOrderItmeTab: boolean,
    estimateShipping: boolean,
    displaySearchOrderDialog: boolean,
    trackingWindowIsVisible: boolean,
    trackingData: OrderTracking | null,
    displayConfirmDialog: boolean,
    displayAddItemWindow: boolean,
    displayConfirmRemoveItemsWindow: boolean,
    refreshHeaders: boolean,
    splitOrderItemsDialogIsVisible: boolean,
    splitOrderItems: SplitOrderItem[] | null,
    selectedOrderId: number | null,
    copyItemsformStepNumber: number,
    itemsToCopy: ItemToCopy[],
    displayOrdersGrid: boolean,
    shippingAddresses: OrderAddress | null,
    newShippingAddress: OrderAddress | null,
    canEdit: boolean,
    message: string | null,
    confirmResitimateShipping: boolean
    refreshData: boolean,
    shippingAmount: number | undefined,
    enterEditOrderStatus: boolean | undefined,
    orderStatuses: OrderStatus[]
    orderStatus: OrderStatus | null,
    displayUploadInvoiceDialog: boolean,
    uploadInvoiceComponentCallNumber: number,
    refreshParentGrid: boolean,
    orderEditableStates: [],
    orderTotals: OrderTotal[]
    orderHeader: CustomerOrderHeader | null,
    isTransfer: boolean
    shippingMethods: ShippingMethod[] | null,
    selectedShippingMethod: ShippingMethod | null
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestCustomerOrderHeaderAction {
    type: 'REQUEST_ORDER_HEADER';
}

interface RecieveCustomerOrderHeaderAction {
    type: 'RECEIVE_ORDER_HEADER';
    orderHeader: CustomerOrderHeader,
}

interface RequestCustomerOrderItemsAction {
    type: 'REQUEST_CUSTOMER_ORDER_ITEMS';
}

interface ReceiveCustomerOrderItemsAction {
    type: 'RECEIVE_CUSTOMER_ORDER_ITEMS';
    orderItems: CustomerOrderItem[];
    total: number,
    dataState: State,
    maxTransfertToSubOrderQuantity: number
}

interface EnterEditOrderItemAction {
    type: 'ENTER_EDIT_ORDER_ITEM';
    orderItems: CustomerOrderItem[];
}

interface DisplaySplitOrderDialogAction {
    type: 'DISPLAY_SPLIT_ORDER_DIALOG';
}

interface HideSplitOrderDialogAction {
    type: 'HIDE_SPLIT_ORDER_DIALOG';
}

interface OrderItemChangeAction {
    type: 'ORDER_ITEM_CHANGE';
    orderItems: CustomerOrderItem[];
}

interface RequestUpdateOrderItemAction {
    type: 'REQUEST_UPDATE_ORDER_ITEM';
}

interface ReceiveUpdateOrderItemAction {
    type: 'RECEIVE_UPDATE_ORDER_ITEM';
}

interface RequestShippingEstimateAction {
    type: 'REQUEST_SHIPPING_ESTIMATE';
}

interface ReceiveShippingEstimateAction {
    type: 'RECEIVE_SHIPPING_ESTIMATE';
    suggestedShippingAmount: number
}

interface OnCloseShippingRateDialogAction {
    type: 'CLOSE_SHIPPING_RATE_DIALOG';
}

interface RequestSaveShippingAmountAction {
    type: 'REQUEST_SAVE_SHIPPING_AMOUNT';
}

interface ReceiveSaveShippingAmountAction {
    type: 'RECEIVE_SAVE_SHIPPING_AMOUNT';
}

interface RequestTransferOrderItemsAction {
    type: 'REQUEST_TRANSFER_ORDER_ITEMS';
}

interface ReceiveTransferOrderItemsAction {
    type: 'RECEIVE_TRANSFER_ORDER_ITEMS';
    message: string;
}

interface ReceiveCopyOrderItemsAction {
    type: 'RECEIVE_COPY_ORDER_ITEMS';
}

interface RequestSplitOrderAction {
    type: 'REQUEST_SPLIT_ORDER';
}

interface ReceiveSplitOrderAction {
    type: 'RECEIVE_SPLIT_ORDER';
}

interface openTrackingWindowAction {
    type: 'OPEN_TRACKING_WINDOW';
}

interface closeTrackingWindowAction {
    type: 'CLOSE_TRACKING_WINDOW';
}

interface setTrackingDataAction {
    type: 'SET_TRACKING_DATA';
    trackingData: OrderTracking
}

interface RequestRemoveItemsAction {
    type: 'REQUEST_REMOVE_ITEMS';
}

interface ReceiveRemoveItemsAction {
    type: 'RECEIVE_REMOVE_ITEMS';
}

interface CloseSearchOrderDialogAction {
    type: 'CLOSE_SEARCH_ORDER_DIALOG';
}

interface CloseConfirmEstimateShippingAction {
    type: 'CLOSE_CONFIRM_ESTIMATE_SHIPPING';
}

interface OpenConfirmEstimateShippingAction {
    type: 'OPEN_CONFIRM_ESTIMATE_SHIPPING';
}


interface OpenSearchDialogAction {
    type: 'OPEN_SEARCH_ORDER_DIALOG';
    displayOrdersGrid: boolean,
    stepNumber: number,
    itemsToCopy: ItemToCopy[],
    isTransfer: boolean
}

interface CloseConfirmTransferItemsAction {
    type: 'CLOSE_CONFIRM_TRANSFER_ITEMS';
}

interface OpenConfirmTransferItemsAction {
    type: 'OPEN_CONFIRM_TRANSFER_ITEMS';
}

interface CloseAddItemWindowAction {
    type: 'CLOSE_ADD_ITEM_WINDOW';
}

interface OpenAddItemWindowAction {
    type: 'OPEN_ADD_ITEM_WINDOW';
}

interface OpenConfirmRemoveItemsWindowAction {
    type: 'OPEN_CONFIRM_REMOVE_ITEMS_WINDOW';
}

interface CloseConfirmRemoveItemsWindowAction {
    type: 'CLOSE_CONFIRM_REMOVE_ITEMS_WINDOW';
}

interface DisplaySplitOrderItemsDialogAction {
    type: 'DISPLAY_SPLIT_ORDER_ITEMS_DIALOG';
    splitOrderItems: SplitOrderItem[]
}

interface HideSplitOrderItemsDialogAction {
    type: 'HIDE_SPLIT_ORDER_ITEMS_DIALOG';
}

interface SetSPlitOrderItemsAction {
    type: 'SET_SPLIT_ORDER_ITEMS';
    splitOrderItems: SplitOrderItem[]
}

interface RequestSplitOrderItemsAction {
    type: 'REQUEST_SPLIT_ORDER_ITEMS';
}

interface ReceiveSplitOrderItemsAction {
    type: 'RECEIVE_SPLIT_ORDER_ITEMS';
}

interface SetSelectedOrderIdsAction {
    type: 'SET_SELECTED_ORDERS_IDS';
    orderId: number | null
}

interface RequestCopyItemsFormStepAction {
    type: 'REQUEST_COPY_ITEMS_FORM_STEP';
    step: number,
    itemsToCopy: ItemToCopy[]
}

interface SetItemsToCopyAction {
    type: 'SET_ITEMS_TO_COPY';
    itemsToCopy: ItemToCopy[]
}

interface ReceiveCustomerOrderHeaderTotlasAction {
    type: 'RECEIVE_CUSTOMER_ORDER_HEADER_TOTALS';
    totals: OrderTotal[]
}

interface RequestShippingAdressesAction {
    type: 'REQUEST_SHIPPING_ADDRESSES';
}

interface ReceiveShippingAdressesAction {
    type: 'RECEIVE_SHIPPING_ADDRESSES';
    shippingAddresses: OrderAddress | null
}

interface OnShippingEstimateAction {
    type: 'ON_SHIPPING_ESTIMATE';
    suggestedShippingAmount: number;
}

interface onCloseShippingEstimateDialogAction {
    type: 'ON_CLOSE_SHIPPING_ESTIMATE_DIALOG';
}

interface onCloseConfirmShippingRateDialogAction {
    type: 'ON_CLOSE_CONFIRM_SHIPPING_RATE_DIALOG';
}

interface OnSaveShippingAmountAction {
    type: 'ON_SAVE_SHIPPING_AMOUNT';
}

interface EnterEditShippingAction {
    type: 'ENTER_EDIT_SHIPPING';
    newShippingAddress: OrderAddress | null
    shippingMethod: ShippingMethod | null
    shippingAmount: number | undefined
}

interface RequestSaveShippingAddressAction {
    type: 'REQUEST_SAVE_SHIPPING_ADDRESS';
}

interface ExitEditShippingAction {
    type: 'EXIT_EDIT_SHIPPING';
}

interface OnShippingAmountChangesAction {
    type: 'ON_SHIPPING_AMOUNT_CHANGES';
    shippingAmount: number | undefined
}

interface OnEditShippingAction {
    type: 'ON_EDIT_SHIPPING';
    newShippingAddress: OrderAddress | null
}

interface OnSaveShippingAddressAction {
    type: 'ON_SAVE_SHIPPING_ADDRESS';
    shippingAddresses: OrderAddress | null
}

interface OnReceiveShippingMethodsAction {
    type: 'ON_RECEIVE_SHIPPING_METHODS';
    shippingMethods: ShippingMethod[] | null
    selectedShippingMethod: ShippingMethod | null
}

interface OnChangeShippingMethodAction {
    type: 'ON_CHANGE_SHIPPING_METHOD';
    shippingMethod: ShippingMethod | null
}

interface OnRequestSaveShippingMethodAction {
    type: 'ON_REQUEST_SAVE_SHIPPING_METHOD';
}

interface OnReceiveSaveShippingMethodAction {
    type: 'ON_RECEIVE_SAVE_SHIPPING_METHOD';
}

type CustomerOrderItensKnownAction =
    RequestSplitOrderItemsAction | ReceiveSplitOrderItemsAction |
    RequestCustomerOrderItemsAction | ReceiveCustomerOrderItemsAction |
    EnterEditOrderItemAction | DisplaySplitOrderDialogAction | HideSplitOrderDialogAction |
    OrderItemChangeAction | RequestUpdateOrderItemAction | ReceiveUpdateOrderItemAction |
    RequestShippingEstimateAction | ReceiveShippingEstimateAction |
    OnCloseShippingRateDialogAction | RequestSaveShippingAmountAction |
    ReceiveSaveShippingAmountAction | RequestTransferOrderItemsAction |
    ReceiveTransferOrderItemsAction | ReceiveCopyOrderItemsAction | RequestSplitOrderAction |
    ReceiveSplitOrderAction | openTrackingWindowAction | closeTrackingWindowAction |
    setTrackingDataAction | RequestRemoveItemsAction | ReceiveRemoveItemsAction |
    CloseSearchOrderDialogAction | CloseConfirmEstimateShippingAction |
    OpenSearchDialogAction | CloseConfirmTransferItemsAction | OpenConfirmTransferItemsAction |
    CloseAddItemWindowAction | OpenAddItemWindowAction | OpenConfirmEstimateShippingAction |
    OpenConfirmRemoveItemsWindowAction | CloseConfirmRemoveItemsWindowAction |
    RequestCustomerOrderHeaderAction | DisplaySplitOrderItemsDialogAction |
    HideSplitOrderItemsDialogAction | SetSPlitOrderItemsAction | SetSelectedOrderIdsAction |
    RequestCopyItemsFormStepAction | SetItemsToCopyAction | RecieveCustomerOrderHeaderAction |
    ReceiveCustomerOrderHeaderTotlasAction | RequestShippingAdressesAction | ReceiveShippingAdressesAction |
    OnShippingEstimateAction | onCloseShippingEstimateDialogAction | onCloseConfirmShippingRateDialogAction |
    OnSaveShippingAmountAction | EnterEditShippingAction | RequestSaveShippingAddressAction | ExitEditShippingAction |
    OnShippingAmountChangesAction | OnEditShippingAction | OnSaveShippingAddressAction | OnReceiveShippingMethodsAction |
    OnChangeShippingMethodAction | OnRequestSaveShippingMethodAction | OnReceiveSaveShippingMethodAction

export const actionCreators = {
    onEditShipping: (e: InputChangeEvent, newshippingAddress: OrderAddress | null): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        var state = getState();
        let newshippingAddress: OrderAddress | null = null;

        if (state.customerOrderItems != undefined) {
            newshippingAddress = Object.assign({}, state.customerOrderItems.newShippingAddress);
        }

        if (newshippingAddress != null) {
            switch (e.target.name) {
                case "country":
                    newshippingAddress.country = e.target.value as string;
                    break;
                case "companyName":
                    newshippingAddress.company_name = e.target.value as string;
                    break;
                case "city":
                    newshippingAddress.city = e.target.value as string;
                    break;
                case "zip":
                    newshippingAddress.zip = e.target.value as string;
                    break;
                case "province":
                    newshippingAddress.province = e.target.value as string;
                    break;
                case "street1":
                    newshippingAddress.street1 = e.target.value as string;
                    break;
                case "street2":
                    newshippingAddress.street2 = e.target.value as string;
                    break;
            }
        }

        dispatch({ type: "ON_EDIT_SHIPPING", newShippingAddress: newshippingAddress });
    },
    onShippingAmountChanges: (shippingAmount: number): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        dispatch({ type: "ON_SHIPPING_AMOUNT_CHANGES", shippingAmount: shippingAmount })
    },
    onExitEditShipping: (): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        dispatch({ type: "EXIT_EDIT_SHIPPING" });
    },
    onShippingAddressSave: (): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        var state = getState();
        var shippingAddress: OrderAddress | null = null;
        if (state.customerOrderItems != undefined) {
            shippingAddress = state.customerOrderItems.newShippingAddress;
        }
        if (shippingAddress != undefined) {
            dispatch({ type: "REQUEST_SAVE_SHIPPING_ADDRESS" });

            fetchUrl("/OrdersAddresses/UpdateShippingAddress", "POST", { body: JSON.stringify(shippingAddress) }, { 'Content-Type': 'application/json' })
                .then((response) => {
                    handleLogoutOnSessionExpire(response);
                    return response.json()
                })
                .then((response) => {
                    dispatch({ type: "ON_SAVE_SHIPPING_ADDRESS", shippingAddresses: shippingAddress });
                    CookiesUtil.setNotificationMessages({
                        subject: "",
                        message: i18n.t("shippingAddressSavedSuccess"),
                        type: "success",
                        created: new Date(),
                        expire: 13000,
                        read: false
                    });
                });
        }
    },
    onEnterEditShipping: (): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        var state = getState();
        let newshippingAddress: OrderAddress | null = null;
        let shippingMethod: ShippingMethod | undefined;
        let shippingAmount: number | undefined;

        if (state.customerOrderItems != undefined) {
            newshippingAddress = state.customerOrderItems.shippingAddresses;
            shippingAmount = state.customerOrderItems.orderHeader?.shipping_fee;
            let shippingMethodName = state.customerOrderItems?.orderHeader?.shipping_method_name;
            let shippingMethods = state.customerOrderItems?.shippingMethods;
            shippingMethod = shippingMethods?.find(e => e.shipping_method_name == shippingMethodName);

        }
        dispatch({ type: "ENTER_EDIT_SHIPPING", newShippingAddress: newshippingAddress, shippingAmount: shippingAmount, shippingMethod: shippingMethod as ShippingMethod | null});
    },
    onSaveShippingAmount: (orderId: number, shippingAmount: number | null = null): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        dispatch({ type: "REQUEST_SAVE_SHIPPING_AMOUNT" });
        if (shippingAmount != null) {
            fetchUrl(
                `/orders/UpdateShippingAmount`,
                "POST",
                { body: JSON.stringify({ "orderId": orderId, "shippingAmount": shippingAmount }) },
                { 'Content-Type': 'application/json' }
            )
                .then((response) => {
                    handleLogoutOnSessionExpire(response);
                    return response.json()
                })
                .then((json) => {
                    dispatch({ type: "ON_SAVE_SHIPPING_AMOUNT" });
                    CookiesUtil.setNotificationMessages({
                        subject: "",
                        message: i18n.t("shippingFeeSavedSuccess"),
                        type: "success",
                        created: new Date(),
                        expire: 13000,
                        read: false
                    });
                });
        }
    },
    onCloseConfirmShippingRateDialog: (): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        dispatch({ type: "ON_CLOSE_CONFIRM_SHIPPING_RATE_DIALOG" });
    },
    onCloseShippingEstimateDisalog: (): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        dispatch({ type: "ON_CLOSE_SHIPPING_ESTIMATE_DIALOG" });
    },
    onShippingEstimate: (orderId: number): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        var shippingAddress = undefined;
        var state = getState();
        if (state.customerOrderItems != undefined) {
            shippingAddress = state.customerOrderItems.shippingAddresses
        }
        dispatch({ type: "REQUEST_SHIPPING_ESTIMATE" });
        //TODO delaerId should be dynamic
        fetchUrl("/products/GetStockAvailabilty?dealerId=305&orderId=" + orderId + "&postalcode=" + shippingAddress?.zip, "GET")
            .then((response) => {
                handleLogoutOnSessionExpire(response);
                return response.json()
            })
            .then((response) => {
                var shippingAmount = 0;
                if (response && response.shippingRate) {
                    var res: [] = response.shippingRate.carriers;
                    res.forEach((e: any) => {
                        e.carrierShippingRate.map(((cr: any) => { shippingAmount += cr.fee }))
                    });
                }
                dispatch({ type: "ON_SHIPPING_ESTIMATE", suggestedShippingAmount: shippingAmount });
            });
    },
    fetchOrderShippingAddresses: (orderId: number): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        dispatch({ type: "REQUEST_SHIPPING_ADDRESSES" });
        fetchUrl(`/OrdersAddresses/GetShippingAddresses?orderId=` + orderId + "&type=SHIPPING", "GET")
            .then((response) => {
                handleLogoutOnSessionExpire(response);
                return response.json()
            })
            .then((shippingAddresses) => {
                dispatch({ type: "RECEIVE_SHIPPING_ADDRESSES", shippingAddresses: shippingAddresses });
            });
    },
    requestOrderTotals: (orderId: number): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        fetchUrl(`/orders/GetCustomerOrderTotals?orderId=${orderId}`, "GET")
            .then((response) => {
                handleLogoutOnSessionExpire(response);
                return response.json() as Promise<OrderTotal[]>
            })
            .then(data => {
                dispatch({ type: 'RECEIVE_CUSTOMER_ORDER_HEADER_TOTALS', totals: data });
            });
    },
    fetchOrderHeader: (orderId: number | undefined): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        dispatch({ type: "REQUEST_ORDER_HEADER" });
        fetchUrl(`/orders/GetCustomerOrderHeader?orderId=` + orderId, "GET")
            .then((response) => {
                handleLogoutOnSessionExpire(response);
                return response.json() as Promise<CustomerOrderHeader>
            })
            .then((customerOrderHeader: CustomerOrderHeader) => {
                if (customerOrderHeader.billing_address != null) {
                    customerOrderHeader.billing_address = JSON.parse(customerOrderHeader.billing_address.toString()) as OrderAddress[];
                }
                
                dispatch({ type: "RECEIVE_ORDER_HEADER", orderHeader: customerOrderHeader });
            });
    },
    fetchCustomerOrderItems: (orderId: number | undefined, dataState: State, forceReload: boolean | null = false): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        const appState = getState();
        const req = toDataSourceRequestString(dataState);
        if (appState.supplierOrders !== undefined) {
            const gridState = toDataSourceRequestString(appState.supplierOrders.dataState);
            if (forceReload || (appState && appState.customerOrders && req != gridState && appState.customerOrders.isLoading == false)) {
                fetchUrl(`/orders/GetCustomerOrderItems?${req}&orderId=${orderId}`, "GET")
                    .then(response => {
                        handleLogoutOnSessionExpire(response);
                        return response.json() as Promise<CustomerOrderItemsDataRecievedResults>
                    })
                    .then(data => {
                        let maxQty = 0;
                        data.data.map((e: CustomerOrderItem) => {
                            if (e.qty > maxQty) {
                                maxQty = e.qty;
                            }
                        });
                        dispatch({
                            type: 'RECEIVE_CUSTOMER_ORDER_ITEMS',
                            orderItems: data.data,
                            total: data.total,
                            dataState: dataState,
                            maxTransfertToSubOrderQuantity: maxQty
                        });
                    });

                dispatch({ type: 'REQUEST_CUSTOMER_ORDER_ITEMS' });
            }
        }
    },
    enterEdit: (dataItem: CustomerOrderItem, cellField: string | undefined): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        var state = getState()
        var orderItems: CustomerOrderItem[] = [];
        if (state.customerOrderItems) {
            orderItems = state.customerOrderItems.orderItems;
        }
        let newData = orderItems.map((item: any) =>
            item.order_item_id === dataItem.order_item_id ? { ...item, inEdit: cellField } : item
        );
        dispatch({ type: "ENTER_EDIT_ORDER_ITEM", orderItems: newData });
    },
    displaySplitOrderDialog: (): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        dispatch({ type: "DISPLAY_SPLIT_ORDER_DIALOG" });
    },
    displaySplitOrderItemsDialog: (selectedCustomerOrderItem: CustomerOrderItem[] | undefined): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        var state = getState();
        if (state.customerOrderItems != undefined) {
            var splitOrderItems = Object.assign([], state.customerOrderItems.splitOrderItems);
            if (splitOrderItems == null || splitOrderItems == undefined) {
                splitOrderItems = [];
            }
            selectedCustomerOrderItem?.forEach(element => {
                splitOrderItems.push({
                    orderItemId: element.order_item_id,
                    qty: 1
                })
            });
            dispatch({ type: "DISPLAY_SPLIT_ORDER_ITEMS_DIALOG", splitOrderItems: splitOrderItems });
        }
    },
    hideSplitOrderItemsDialog: (): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        dispatch({ type: "HIDE_SPLIT_ORDER_ITEMS_DIALOG" });
    },
    hideSplitOrderDialog: (): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        dispatch({ type: "HIDE_SPLIT_ORDER_DIALOG" });
    },
    itemChange: (event: any): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        var state = getState()
        var orderItems: CustomerOrderItem[] = [];
        if (state.customerOrderItems) {
            orderItems = state.customerOrderItems.orderItems;
        }
        const field = event.field || "";
        const newData = orderItems.map((item: any) =>
            item.order_item_id === event.dataItem.order_item_id
                ? { ...item, [field]: event.value }
                : item
        );
        dispatch({ type: "ORDER_ITEM_CHANGE", orderItems: newData });
    },
    update: (orderId: number): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        dispatch({ type: "REQUEST_UPDATE_ORDER_ITEM" });
        var state = getState()
        var orderItems: CustomerOrderItem[] = [];
        if (state.customerOrderItems) {
            orderItems = state.customerOrderItems.orderItems;
        }
        fetchUrl(
            "/orders/UpdateCustomerOrderItemSellPriceAndQuantity",
            "POST",
            { body: JSON.stringify({ "customerOrderItemData": orderItems, "orderId": orderId }) },
            { 'Content-Type': 'application/json' }
        )
            .then((response) => {
                handleLogoutOnSessionExpire(response);
                return response.json()
            })
            .then((orderItem) => {
                dispatch({ type: "RECEIVE_UPDATE_ORDER_ITEM" });
            });

        orderItems.forEach((item) => item.inEdit = false);

        dispatch({ type: "ORDER_ITEM_CHANGE", orderItems: orderItems });
    },
    onCopyItemsSetStep: (step: number): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        var state = getState();
        var itemsToCopy: ItemToCopy[] = []
        if (state.customerOrderItems != undefined) {
            itemsToCopy = Object.assign([], state.customerOrderItems.itemsToCopy);
        }
        dispatch({ type: "REQUEST_COPY_ITEMS_FORM_STEP", step: step, itemsToCopy: itemsToCopy });
        dispatch({ type: "SET_SELECTED_ORDERS_IDS", orderId: null });
    },
    onCopyItems: (step: number, selectedCustomerOrderItem: CustomerOrderItem[] | undefined, isTransfer: boolean): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        if (selectedCustomerOrderItem != undefined && selectedCustomerOrderItem?.length > 0) {
            var state = getState();
            var orderId: number | null = null
            let itermsToCopy: ItemToCopy[] = []

            if (state.customerOrderItems != undefined) {
                orderId = state.customerOrderItems.selectedOrderId;
                itermsToCopy = state.customerOrderItems.itemsToCopy;
            }

            if (step == 0 && orderId != null) {
                var state = getState();
                var itemsToCopy: ItemToCopy[] = []
                if (state.customerOrderItems != undefined) {
                    itemsToCopy = Object.assign([], state.customerOrderItems.itemsToCopy);
                    selectedCustomerOrderItem.forEach(oi => {
                        var found = (itemsToCopy.find(it => it.itemId == oi.order_item_id));
                        var foundIndex = (itemsToCopy.findIndex(it => it.itemId == oi.order_item_id));

                        if (found == null) {
                            itemsToCopy.push({
                                itemId: oi.order_item_id,
                                targetOrderId: orderId,
                                qty: oi.qty
                            })
                        } else {
                            found.targetOrderId = orderId;
                            itemsToCopy[foundIndex] = found;
                        }
                    });
                }
                dispatch({ type: "REQUEST_COPY_ITEMS_FORM_STEP", step: 1, itemsToCopy: itemsToCopy });
                return;
            }

            if (step == 1) {
                var url = "/CustomerOrderItems/CopyItems/";
                if (isTransfer) {
                    url = "/CustomerOrderItems/TransferItems/";
                }
                fetchUrl(url, "POST", { body: JSON.stringify(itermsToCopy) }, { 'Content-Type': 'application/json' })
                    .then((response) => {
                        handleLogoutOnSessionExpire(response);
                        return response.json()
                    })
                    .then((copiedItems: CopiedItemsResult[]) => {
                        dispatch({ type: "RECEIVE_COPY_ORDER_ITEMS" });
                        copiedItems.forEach(ci => {
                            let messageType: "none" | "success" | "error" | "warning" | "info" = "success";
                            let message = i18n.t("item") + ci.new_line_id + isTransfer ? i18n.t("itemTransferSuccess") : i18n.t("itemCopySuccess");
                            if (ci.new_line_id == null) {
                                messageType = "error";
                                message = isTransfer ? i18n.t("itemTransferFailure") : i18n.t("itemCopyFailure");
                            }
                            CookiesUtil.setNotificationMessages({
                                subject: "",
                                message: message,
                                type: messageType,
                                created: new Date(),
                                expire: 13000,
                                read: false
                            });
                        });
                    });
            }
        }

    },
    openTrackingWindow: (): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        dispatch({ type: "OPEN_TRACKING_WINDOW" });
    },
    closeTrackingWindow: (): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        dispatch({ type: "CLOSE_TRACKING_WINDOW" });
    },
    setTrackingData: (trackingData: OrderTracking): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        dispatch({ type: "SET_TRACKING_DATA", trackingData: trackingData });
    },
    remove: (selectedCustomerOrderItem: CustomerOrderItem[] | undefined, orderId: number): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        dispatch({ type: "REQUEST_REMOVE_ITEMS" });
        fetchUrl("/orders/DeleteCustomerOrderItem", "DELETE",
            { body: JSON.stringify({ "customerOrderItemData": selectedCustomerOrderItem, "orderId": orderId }) },
            { 'Content-Type': 'application/json' }
        )
            .then((response) => {
                handleLogoutOnSessionExpire(response);
                return response.json()
            })
            .then((response) => {
                CookiesUtil.setNotificationMessages({
                    subject: "",
                    message: i18n.t("itemRemovedSuccess"),
                    type: "success",
                    created: new Date(),
                    expire: 13000,
                    read: false
                });
                dispatch({ type: "RECEIVE_REMOVE_ITEMS" });
            });
    },
    closeSearchOrdersDialog: (): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        dispatch({ type: "CLOSE_SEARCH_ORDER_DIALOG" });
    },
    openSearchOrdersDialog: (displayOrderGrid: boolean, step: number, selectedCustomerOrderItem: CustomerOrderItem[], isTransfer: boolean): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        if (selectedCustomerOrderItem != undefined && selectedCustomerOrderItem?.length > 0) {
            var state = getState();
            var itemsToCopy: ItemToCopy[] = []
            selectedCustomerOrderItem.forEach(oi => {
                var found = (itemsToCopy.find(it => it.itemId == oi.order_item_id));
                if (found == null) {
                    itemsToCopy.push({
                        itemId: oi.order_item_id,
                        targetOrderId: null,
                        qty: oi.qty
                    })
                }
            });
            dispatch({
                type: "OPEN_SEARCH_ORDER_DIALOG",
                displayOrdersGrid: displayOrderGrid,
                stepNumber: step,
                itemsToCopy: itemsToCopy,
                isTransfer: isTransfer
            });
        }
    },
    onCloseConfirmEstimateShipping: (): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        dispatch({ type: "CLOSE_CONFIRM_ESTIMATE_SHIPPING" });
    },
    closeAddItemWindow: (): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        dispatch({ type: "CLOSE_ADD_ITEM_WINDOW" });
    },
    openAddItemWindow: (): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        dispatch({ type: "OPEN_ADD_ITEM_WINDOW" });
    },
    openConfirmShippingEstimateWindow: (): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        dispatch({ type: "OPEN_CONFIRM_ESTIMATE_SHIPPING" });
    },
    openRemoveItemsConfirmWindow: (): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        dispatch({ type: "OPEN_CONFIRM_REMOVE_ITEMS_WINDOW" });
    },
    closeRemoveItemsConfirmWindow: (): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        dispatch({ type: "CLOSE_CONFIRM_REMOVE_ITEMS_WINDOW" });
    },
    setSplitOrderItems: (qty: number, itemId: number | null): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        var state = getState();
        if (state.customerOrderItems != undefined) {
            var splitOrderItems = Object.assign([], state.customerOrderItems.splitOrderItems);
            if (splitOrderItems == null) {
                splitOrderItems = [];
            }
            splitOrderItems.forEach((element: SplitOrderItem) => {
                if (element.orderItemId == itemId) {
                    element.qty = qty
                }
            });

            dispatch({ type: 'SET_SPLIT_ORDER_ITEMS', splitOrderItems: splitOrderItems });

        }
    },
    onSplitOrderItems: (): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        var state = getState();
        if (state.customerOrderItems != undefined) {
            var splitOrderItems = Object.assign([], state.customerOrderItems.splitOrderItems);
            dispatch({ type: "REQUEST_SPLIT_ORDER_ITEMS" });
            fetchUrl("/customerOrderItems/split", "POST",
                { body: JSON.stringify(splitOrderItems) },
                { 'Content-Type': 'application/json' }
            )
                .then((response) => {
                    handleLogoutOnSessionExpire(response);
                    return response.json()
                })
                .then((response: SplitOrderItemsResult[]) => {
                    response.forEach((res: any) => {
                        var messageType: "none" | "success" | "error" | "warning" | "info" = "error";
                        var message = i18n.t("itemSplitFailure");
                        if (res != null) {
                            message = i18n.t("itemSplitSuccess");
                            messageType = "success";
                        }

                        CookiesUtil.setNotificationMessages({
                            subject: "",
                            message: message,
                            type: messageType,
                            created: new Date(),
                            expire: 13000,
                            read: false
                        });
                    });


                    dispatch({ type: "RECEIVE_SPLIT_ORDER_ITEMS" });
                });

        }
    },
    setSelectedOrder: (orderId: number): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        dispatch({ type: "SET_SELECTED_ORDERS_IDS", orderId: orderId });
    },
    onChangeCopyItemsQty: (qty: number, itemId: number | null): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        var state = getState();
        var itemsToCopy: ItemToCopy[] = [];
        var orderId: number | null = null
        if (state.customerOrderItems != undefined) {
            itemsToCopy = Object.assign([], state.customerOrderItems.itemsToCopy);
            orderId = state.customerOrderItems.selectedOrderId;
        }

        let found = false;
        if (itemsToCopy.length == 0) {
            itemsToCopy.push({
                qty: qty,
                itemId: itemId,
                targetOrderId: orderId
            });
        } else {
            itemsToCopy.forEach(it => {
                if (it.itemId == itemId) {
                    found = true;
                    it.qty = qty;
                } else {
                    itemsToCopy.push({
                        qty: qty,
                        itemId: itemId,
                        targetOrderId: orderId
                    })
                }
            });
        }

        dispatch({ type: "SET_ITEMS_TO_COPY", itemsToCopy: itemsToCopy });
    },
    fetchShippingMethods: (): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        var state = getState();
        var shippingMethodName: string | undefined;
        if (state.customerOrderItems != undefined) {
            shippingMethodName = state.customerOrderItems.orderHeader?.shipping_method_name;
        }
        dispatch({ type: "REQUEST_SHIPPING_ADDRESSES" });
        fetchUrl("/shipping/GetMethods", "GET")
            .then((response) => {
                handleLogoutOnSessionExpire(response);
                return response.json() as Promise<ShippingMethod[]>
            })
            .then((shippingMethods: ShippingMethod[] | null) => {
                let selectedShippingMethod = shippingMethods?.find(s => s.shipping_method_name == shippingMethodName) as ShippingMethod | null;
                dispatch({ type: "ON_RECEIVE_SHIPPING_METHODS", shippingMethods: shippingMethods, selectedShippingMethod: selectedShippingMethod });
            });
    },
    onChangeShippingMethod: (e: DropDownListChangeEvent): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        dispatch({ type: "ON_CHANGE_SHIPPING_METHOD", shippingMethod: e.value });
    },
    onSaveShippingMethod: (
        orderId: number,
        shippingMethod: ShippingMethod | null,
        shippingAmount: number | undefined,
        saveShippingAmountCallback : any
    ): AppThunkAction<CustomerOrderItensKnownAction> => (dispatch, getState) => {
        dispatch({ type: "ON_REQUEST_SAVE_SHIPPING_METHOD" });
        var state = getState();
        if (shippingMethod != null) {
            fetchUrl(
                `/orders/UpdateShippingMethod`,
                "POST",
                { body: JSON.stringify({ "orderId": orderId, "shippingMethodId": shippingMethod.shipping_method_id }) },
                { 'Content-Type': 'application/json' }
            )
                .then((response) => {
                    handleLogoutOnSessionExpire(response);
                    return response.json()
                })
                .then((json) => {
                    dispatch({ type: "ON_RECEIVE_SAVE_SHIPPING_METHOD" });
                    saveShippingAmountCallback(orderId, shippingAmount);
                    CookiesUtil.setNotificationMessages({
                        subject: "",
                        message: i18n.t("shippingMethodSavedSuccess"),
                        type: "success",
                        created: new Date(),
                        expire: 13000,
                        read: false
                    });
                });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloadedState: CustomerOrderItemsState = {
    init: true,
    isLoading: false,
    orderItems: [],
    dataState: null,
    total: 0,
    splitOrderDialogIsVisible: false,
    confirmRestimateShipping: false,
    confirmShippingRateDialogIsVisible: false,
    suggestedShippingAmount: null,
    enterEditShipping: false,
    refreshCutomerOrderItmeTab: false,
    message: null,
    estimateShipping: false,
    displaySearchOrderDialog: false,
    trackingWindowIsVisible: false,
    trackingData: null,
    displayConfirmDialog: false,
    displayAddItemWindow: false,
    displayConfirmRemoveItemsWindow: false,
    orderEditableStates: [],
    refreshHeaders: false,
    splitOrderItemsDialogIsVisible: false,
    splitOrderItems: null,
    selectedOrderId: null,
    copyItemsformStepNumber: 0,
    itemsToCopy: [],
    displayOrdersGrid: false,
    shippingAddresses: null,
    newShippingAddress: null,
    canEdit: false,
    confirmResitimateShipping: false,
    refreshData: false,
    shippingAmount: undefined,
    enterEditOrderStatus: undefined,
    orderStatuses: [],
    orderStatus: null,
    displayUploadInvoiceDialog: false,
    uploadInvoiceComponentCallNumber: 0,
    refreshParentGrid: false,
    orderTotals: [],
    orderHeader: null,
    isTransfer: false,
    shippingMethods: [],
    selectedShippingMethod: null
};

export const reducer: Reducer<CustomerOrderItemsState> = (state: CustomerOrderItemsState | undefined, incomingAction: CustomerOrderItensKnownAction): CustomerOrderItemsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as CustomerOrderItensKnownAction;
    switch (action.type) {
        case 'REQUEST_CUSTOMER_ORDER_ITEMS':
            return {
                init: false,
                isLoading: true,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: state.refreshCutomerOrderItmeTab,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'RECEIVE_CUSTOMER_ORDER_ITEMS':
            return {
                init: false,
                isLoading: false,
                orderItems: action.orderItems,
                dataState: action.dataState,
                total: action.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: state.refreshCutomerOrderItmeTab,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'ENTER_EDIT_ORDER_ITEM':
            return {
                init: false,
                isLoading: false,
                orderItems: action.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: false,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'DISPLAY_SPLIT_ORDER_DIALOG':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: true,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: state.refreshCutomerOrderItmeTab,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'HIDE_SPLIT_ORDER_DIALOG':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: false,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: state.refreshCutomerOrderItmeTab,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'ORDER_ITEM_CHANGE':
            return {
                init: false,
                isLoading: false,
                orderItems: action.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: false,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: false,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'REQUEST_UPDATE_ORDER_ITEM':
            return {
                init: false,
                isLoading: true,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: false,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: false,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: false,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'RECEIVE_UPDATE_ORDER_ITEM':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: false,
                confirmRestimateShipping: true,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: true,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: true,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'REQUEST_SHIPPING_ESTIMATE':
            return {
                init: false,
                isLoading: true,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.splitOrderDialogIsVisible,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: state.refreshCutomerOrderItmeTab,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'RECEIVE_SHIPPING_ESTIMATE':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: true,
                suggestedShippingAmount: action.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: false,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'CLOSE_SHIPPING_RATE_DIALOG':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.splitOrderDialogIsVisible,
                confirmShippingRateDialogIsVisible: false,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: state.refreshCutomerOrderItmeTab,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'REQUEST_SAVE_SHIPPING_AMOUNT':
            return {
                init: false,
                isLoading: true,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.splitOrderDialogIsVisible,
                confirmShippingRateDialogIsVisible: false,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: state.refreshCutomerOrderItmeTab,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: false,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'RECEIVE_SAVE_SHIPPING_AMOUNT':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: false,
                confirmShippingRateDialogIsVisible: false,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: true,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: true,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'REQUEST_TRANSFER_ORDER_ITEMS':
            return {
                init: false,
                isLoading: true,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.splitOrderDialogIsVisible,
                confirmShippingRateDialogIsVisible: false,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: false,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: false,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'RECEIVE_TRANSFER_ORDER_ITEMS':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.splitOrderDialogIsVisible,
                confirmShippingRateDialogIsVisible: false,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: true,
                message: action.message,
                estimateShipping: true,
                displaySearchOrderDialog: false,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'RECEIVE_COPY_ORDER_ITEMS':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.splitOrderDialogIsVisible,
                confirmShippingRateDialogIsVisible: false,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: true,
                message: state.message,
                estimateShipping: true,
                displaySearchOrderDialog: false,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'REQUEST_SPLIT_ORDER':
            return {
                init: false,
                isLoading: true,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.splitOrderDialogIsVisible,
                confirmShippingRateDialogIsVisible: false,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: false,
                message: state.message,
                estimateShipping: true,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'RECEIVE_SPLIT_ORDER':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: false,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: false,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: true,
                message: state.message,
                estimateShipping: true,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'OPEN_TRACKING_WINDOW':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: state.refreshCutomerOrderItmeTab,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: true,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };

        case 'CLOSE_TRACKING_WINDOW':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: state.refreshCutomerOrderItmeTab,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: false,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'SET_TRACKING_DATA':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: state.refreshCutomerOrderItmeTab,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: false,
                trackingData: action.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'REQUEST_REMOVE_ITEMS':
            return {
                init: false,
                isLoading: true,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.splitOrderDialogIsVisible,
                confirmShippingRateDialogIsVisible: false,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: false,
                message: state.message,
                estimateShipping: true,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: false,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'RECEIVE_REMOVE_ITEMS':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: true,
                confirmShippingRateDialogIsVisible: false,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: true,
                message: state.message,
                estimateShipping: true,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: false,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: false,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'CLOSE_SEARCH_ORDER_DIALOG':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: state.refreshCutomerOrderItmeTab,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: false,
                trackingWindowIsVisible: false,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: 0,
                itemsToCopy: [],
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'CLOSE_CONFIRM_ESTIMATE_SHIPPING':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: false,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: state.refreshCutomerOrderItmeTab,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'OPEN_CONFIRM_ESTIMATE_SHIPPING':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: true,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: state.refreshCutomerOrderItmeTab,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'OPEN_SEARCH_ORDER_DIALOG':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: state.refreshCutomerOrderItmeTab,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: true,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: action.stepNumber,
                itemsToCopy: action.itemsToCopy,
                displayOrdersGrid: action.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: action.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'CLOSE_CONFIRM_TRANSFER_ITEMS':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: state.refreshCutomerOrderItmeTab,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'OPEN_CONFIRM_TRANSFER_ITEMS':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: state.refreshCutomerOrderItmeTab,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'OPEN_ADD_ITEM_WINDOW':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: state.refreshCutomerOrderItmeTab,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: true,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'CLOSE_ADD_ITEM_WINDOW':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: state.refreshCutomerOrderItmeTab,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: false,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };

        case 'OPEN_CONFIRM_REMOVE_ITEMS_WINDOW':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: state.refreshCutomerOrderItmeTab,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: true,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'CLOSE_CONFIRM_REMOVE_ITEMS_WINDOW':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: state.refreshCutomerOrderItmeTab,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: false,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'REQUEST_ORDER_HEADER':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: false,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: false,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'DISPLAY_SPLIT_ORDER_ITEMS_DIALOG':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: state.refreshCutomerOrderItmeTab,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: true,
                splitOrderItems: action.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'HIDE_SPLIT_ORDER_ITEMS_DIALOG':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: state.refreshCutomerOrderItmeTab,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: false,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'SET_SPLIT_ORDER_ITEMS':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: state.refreshCutomerOrderItmeTab,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: action.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'REQUEST_SPLIT_ORDER_ITEMS':
            return {
                init: false,
                isLoading: true,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: state.refreshCutomerOrderItmeTab,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'RECEIVE_SPLIT_ORDER_ITEMS':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: true,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: true,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'SET_SELECTED_ORDERS_IDS':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: false,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: false,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: action.orderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'REQUEST_COPY_ITEMS_FORM_STEP':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: false,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: false,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: action.step,
                itemsToCopy: action.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'SET_ITEMS_TO_COPY':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: false,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: false,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: action.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'RECEIVE_ORDER_HEADER':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: false,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: false,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: action.orderHeader?.shipping_fee,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: action.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'RECEIVE_SHIPPING_ADDRESSES':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: state.refreshCutomerOrderItmeTab,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: false,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: action.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'REQUEST_SHIPPING_ADDRESSES':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: state.refreshCutomerOrderItmeTab,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: false,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'RECEIVE_CUSTOMER_ORDER_HEADER_TOTALS':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: false,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: false,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: action.totals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'ENTER_EDIT_SHIPPING':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: true,
                refreshCutomerOrderItmeTab: false,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: false,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: action.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: action.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: action.shippingMethod
            };
        case 'EXIT_EDIT_SHIPPING':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: false,
                refreshCutomerOrderItmeTab: false,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: false,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'ON_EDIT_SHIPPING':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: true,
                refreshCutomerOrderItmeTab: false,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: false,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: action.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'ON_SHIPPING_ESTIMATE':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: true,
                suggestedShippingAmount: action.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: false,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: false,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: false,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };

        case 'ON_SAVE_SHIPPING_AMOUNT':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: false,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: false,
                refreshCutomerOrderItmeTab: true,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: false,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.newShippingAddress,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'ON_SAVE_SHIPPING_ADDRESS':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: true,
                confirmShippingRateDialogIsVisible: false,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: false,
                refreshCutomerOrderItmeTab: true,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: false,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: action.shippingAddresses,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: true,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'REQUEST_SAVE_SHIPPING_ADDRESS':
            return {
                init: false,
                isLoading: true,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: false,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: true,
                refreshCutomerOrderItmeTab: false,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: false,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.shippingAddresses,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'ON_CLOSE_CONFIRM_SHIPPING_RATE_DIALOG':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: false,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: false,
                refreshCutomerOrderItmeTab: false,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: false,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.shippingAddresses,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'ON_SHIPPING_AMOUNT_CHANGES':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: state.refreshCutomerOrderItmeTab,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.shippingAddresses,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: action.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: state.selectedShippingMethod
            };
        case 'ON_RECEIVE_SHIPPING_METHODS':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: state.refreshCutomerOrderItmeTab,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.shippingAddresses,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: action.shippingMethods,
                selectedShippingMethod: action.selectedShippingMethod
            };
        case 'ON_CHANGE_SHIPPING_METHOD':
            return {
                init: false,
                isLoading: false,
                orderItems: state.orderItems,
                dataState: state.dataState,
                total: state.total,
                splitOrderDialogIsVisible: state.splitOrderDialogIsVisible,
                confirmRestimateShipping: state.confirmRestimateShipping,
                confirmShippingRateDialogIsVisible: state.confirmShippingRateDialogIsVisible,
                suggestedShippingAmount: state.suggestedShippingAmount,
                enterEditShipping: state.enterEditShipping,
                refreshCutomerOrderItmeTab: state.refreshCutomerOrderItmeTab,
                message: state.message,
                estimateShipping: state.estimateShipping,
                displaySearchOrderDialog: state.displaySearchOrderDialog,
                trackingWindowIsVisible: state.trackingWindowIsVisible,
                trackingData: state.trackingData,
                displayConfirmDialog: state.displayConfirmDialog,
                displayAddItemWindow: state.displayAddItemWindow,
                displayConfirmRemoveItemsWindow: state.displayConfirmRemoveItemsWindow,
                orderEditableStates: state.orderEditableStates,
                refreshHeaders: state.refreshHeaders,
                splitOrderItemsDialogIsVisible: state.splitOrderItemsDialogIsVisible,
                splitOrderItems: state.splitOrderItems,
                selectedOrderId: state.selectedOrderId,
                copyItemsformStepNumber: state.copyItemsformStepNumber,
                itemsToCopy: state.itemsToCopy,
                displayOrdersGrid: state.displayOrdersGrid,
                newShippingAddress: state.shippingAddresses,
                shippingAddresses: state.shippingAddresses,
                canEdit: state.canEdit,
                confirmResitimateShipping: state.confirmResitimateShipping,
                refreshData: state.refreshData,
                shippingAmount: state.shippingAmount,
                enterEditOrderStatus: state.enterEditOrderStatus,
                orderStatuses: state.orderStatuses,
                orderStatus: state.orderStatus,
                displayUploadInvoiceDialog: state.displayUploadInvoiceDialog,
                uploadInvoiceComponentCallNumber: state.uploadInvoiceComponentCallNumber,
                refreshParentGrid: state.refreshParentGrid,
                orderTotals: state.orderTotals,
                orderHeader: state.orderHeader,
                isTransfer: state.isTransfer,
                shippingMethods: state.shippingMethods,
                selectedShippingMethod: action.shippingMethod
            };
        default:
            return state;
    }
};
