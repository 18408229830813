import moment from "moment-timezone";

export class DateTimeUtil {
    static toUserLocalDateTIme = (dateString: string, displayTime: boolean = true) : string => {
        var format = 'YYYY-MM-DD';
        if (displayTime) {
            format = 'YYYY-MM-DD HH:mm:ss'
        }
        var date = "";
        const localtz = moment.tz.guess();
        if (dateString) {
            var rawDate = new Date(dateString);
            var utcDate = new Date(Date.UTC(rawDate.getFullYear(), rawDate.getMonth(), rawDate.getDate(), rawDate.getHours(), rawDate.getMinutes(), rawDate.getSeconds()));
            date = moment(utcDate).tz(localtz).format(format);
        }

        return date
    }
    
}