import * as React from 'react';
import * as NotificationsStore from '../../store/Notifications/Notifications';
import { RouteComponentProps } from 'react-router';
import { CookiesUtil } from '../../Module/CookiesUtil';
import { ApplicationState } from '../../store';
import { connect, useDispatch } from 'react-redux';
import './notifications.css'

import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";
import { NotificationWrapper } from './NotificationWrapper';

interface AccessTokenProps {
    onTokenReceived: (token: string) => void
}
let interval: any = null;
interface State {
    store: NotificationsStore.NotificationContainer[];
    success: boolean;
    error: boolean;
    warning: boolean;
    info: boolean;
    none: boolean;
}

type NotificationsProps =
    NotificationsStore.NotificationState // ... state we've requested from the Redux store
    & typeof NotificationsStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps

const Notifications = (props: NotificationsProps) => {
    const [state, setState] = React.useState<State>({
        store: [],
        success: false,
        error: false,
        warning: false,
        info: true,
        none: false,
    });
    const { info } = state;

    if (interval) {
        clearInterval(interval);
    }

    interval = setInterval(() => {
        if (process.env.REACT_APP_ENABLE_NOTIFICATIONS == "true") {
            //props.requestNotificationMessages(CookiesUtil.getCookieValue(CookiesUtil.COOKIE_KEY_ACCESS_TOKEN));
            if (props.sessionExpired) {
                clearInterval(interval);
                window.location.reload();
            }
            if (props.notificationMessagesPopUps.length > 0) {
                props.notificationMessagesPopUps.map(function (message: NotificationsStore.NotificationContainer) {
                    state.store.push(message);

                });
            }
        }
    }, 1000)

    return <>
        {info && (
            <NotificationGroup style={{ bottom: 0, right: 0, alignItems: "flex-end", zIndex: 99999 }}>
                {
                    state.store.map(function (message: NotificationsStore.NotificationContainer) {
                        return <NotificationWrapper hideAfter={5000} message={message.message} />
                    })
                }
            </NotificationGroup>
        )}

    </>;
}

export default connect(
    (state: ApplicationState) => state.notifications, // Selects which state properties are merged into the component's props
    NotificationsStore.actionCreators // Selects which action creators are merged into the component's props
)(Notifications as any); // eslint-disable-line @typescript-eslint/no-explicit-any