import * as React from 'react';
import { Grid, GridCellProps, GridColumn as Column, GridDataStateChangeEvent, GridDetailRowProps, GridExpandChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { State } from '@progress/kendo-data-query';
import { DataRecievedResults, Order, Product } from '../../store/interfaces';
import { AccessToken } from '../AccessToken';
import i18n from "i18next";

import {
    IntlProvider,
    load,
    LocalizationProvider,
} from "@progress/kendo-react-intl";

import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";

import numbers from "cldr-numbers-full/main/fr/numbers.json";
import currencies from "cldr-numbers-full/main/fr/currencies.json";
import caGregorian from "cldr-dates-full/main/fr/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/fr/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/fr/timeZoneNames.json";
import * as SupplierOrdersStore from '../../store/Orders/SupplierOrdersStore';
import { Menu, PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { CookiesUtil } from '../../Module/CookiesUtil';
import { DateTimeUtil } from '../../Module/DateTimeUtil';
import { useTranslation } from 'react-i18next';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { loadingPanel } from '../loadingPanel';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { MoneyCell } from '../../Module/renderers';
import { updateGridFooterPager } from '../../Module/Utils';
import { ConfirmDialog } from '../../Module/Dialog/ConfirmDialog';
import * as TabsStore from '../../store/Tabs/Tabs';
import * as ConnectWiseStore from '../../store/ConnectWise/ConnectWise';
import SupplierOrderDetail from './SupplierOrderDetail';
import { SUPPLIER_ORDER_DETAILS_TAB_ID_PREFIX } from '../../store/constants';

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    currencies,
    caGregorian,
    dateFields,
    timeZoneNames
);


type SupplierOrdersProps =
    SupplierOrdersStore.SupplierOrdersState // ... state we've requested from the Redux store
    & typeof SupplierOrdersStore.actionCreators // ... plus action creators we've requested
    & TabsStore.TabsState // ... state we've requested from the Redux store
    & typeof TabsStore.actionCreators // ... plus action creators we've requested
    & ConnectWiseStore.ConnectWiseState // ... state we've requested from the Redux store
    & typeof ConnectWiseStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps


const SupplierOrdersGrid = (props: SupplierOrdersProps) => {
    const { t, i18n } = useTranslation();
    const [orders, setOrders] = React.useState<Order[]>([]);
    const [dataState, setDataState] = React.useState<State>({ take: 10, skip: 0 });
    const [accessToken, setAccessToken] = React.useState<string>(CookiesUtil.getCookieValue("accessToken"));
    const [exportOrders, setExportOrders] = React.useState<boolean>();
    const _export = React.useRef<ExcelExport | null>(null);

    const dataStateChange = (e: GridDataStateChangeEvent) => {
        setDataState(e.dataState);
    }

    const OrderCreatedOnCell = (props: GridCellProps) => {
        const field = props.field || "";
        return (
            <td colSpan={props.colSpan} style={props.style} className={props.className}>
                {DateTimeUtil.toUserLocalDateTIme(props.dataItem[field])}
            </td>
        );
    };

    const excelExport = () => {
        if (_export.current !== null) {
            props.requestExportOrders(dataState);
            setExportOrders(true);
        }
    };

    React.useEffect(() => {
        props.requestOrders(dataState);
        if (props.exportedSupplierOrders.length > 0 && exportOrders == true) {
            setExportOrders(false);
            _export.current?.save(props.exportedSupplierOrders);
        }
        document.title = process.env.REACT_APP_SITE_TITLE + " " + i18n.t("supplierOrders")
        updateGridFooterPager(props.total, i18n.t("orders"), "supplier-orders")
    });

    React.useEffect(() => {
        if (props.refreshSupplierOrders == true) {
            props.requestOrders(dataState);
        }
    }, [props.refreshSupplierOrders])

    React.useEffect(() => {
        props.fetchModuleParams();
    },[]);

    const actionCell = (cellProps: GridCellProps) => {
        return (
            <td colSpan={cellProps.colSpan} style={cellProps.style} className={cellProps.className}>
                <a href='#'
                    onClick={() => { 
                        props.onAddNewTabs(
                            [
                                {
                                    title: i18n.t('supplierOrderShort') + " # " + cellProps.dataItem["estaff_order_number"]  + " " + cellProps.dataItem["supplier_name"] ,
                                    id: SUPPLIER_ORDER_DETAILS_TAB_ID_PREFIX + cellProps.dataItem["estaff_order_number"],
                                    content: {
                                        name: SupplierOrderDetail,
                                        props: { orderId: cellProps.dataItem["estaff_order_number"] }
                                    },
                                    visible: true,
                                    closable: true
                                }
                            ]
                        ) 
                    }}
                    className="fa fa-external-link-square-alt fa-fw action-link"
                ></a>
                <>&nbsp;&nbsp;&nbsp;</>
                {props.connectwiseParams != null && props.connectwiseParams.param_value == "1" &&
                    <>
                    {
                        cellProps.dataItem["external_status"] == 0 &&
                        <a
                            href="#"
                            onClick={(e) => { props.onPromptCreateOrderIntoCW(cellProps.dataItem["estaff_order_number"]) }}
                            className="fa fa-plus-circle action-link"
                        >
                        </a> 
                    }
                    {cellProps.dataItem["external_status"] == 1 && <><i className="fa fa-check" style={{color: "green"}}></i></>}
                    {cellProps.dataItem["external_status"] == -1 && <>
                        <a
                            href="#"
                            onClick={(e) => { props.onPromptCreateOrderIntoCW(cellProps.dataItem["estaff_order_number"]) }}
                            className="fa fa-plus-circle action-link red"
                        >
                        </a> 
                    
                    </>}
                    </>
                }
            </td>
        );
    };

    return (

        <div>
            {props.confirmCreateOrderIntoCWIsVisible &&
                <ConfirmDialog
                    message={i18n.t("confirmCreateOrderIntoiCW")}
                    onPrompt={props.CloseConfirmCreateOrderIntoCW}
                    onConfirm={() => { props.onCreateIntoCW(props.orderToCreateIntoCW) }}
                 />
            }
            <LocalizationProvider language={i18n.language}>
                <IntlProvider locale={i18n.language}>
                    <ExcelExport ref={_export}>
                        <div className='supplier-orders'>
                            <Grid
                                sortable={true}
                                pageable={{
                                    pageSizes: [10, 30, 50]
                                }}
                                resizable={true}
                                {...dataState}
                                data={props.supplierOrders}
                                onDataStateChange={dataStateChange}
                                total={props.total}
                            >
                                <GridToolbar>
                                    <div className='action-menu'>
                                        <div className='action-menu-dropdown'>
                                            <Menu
                                                className='drop-down-menu'
                                                onSelect={excelExport}
                                                items={[{
                                                    "text": i18n.t("action"),
                                                    "items": [
                                                        { "text": i18n.t("exportToExcel") },
                                                    ],
                                                }]} />
                                        </div></div>
                                    <div className='grid-titles'>{i18n.t("supplierOrders")}</div>
                                </GridToolbar>
                                <Column field="supplier_order_date" filter="date" title={i18n.t('date')} cell={OrderCreatedOnCell} width={211} />
                                <Column field="order_source_name" filter='text' title={i18n.t('source')} width={225} />
                                <Column field="order_number_source" filter='text' title={i18n.t('external')} width={140} />
                                <Column field="estaff_order_number" filter='numeric' title={i18n.t('#estaff')} width={117} />
                                <Column field="supplier_name" filter="text" title={i18n.t('supplierName')} width={160} />
                                <Column field="account_number" filter="text" title={i18n.t('accountNumber')} width={163} />
                                <Column field="order_total" filter="numeric" title={i18n.t('total')} cell={MoneyCell} width={120} />
                                <Column field="status_name" filter="text" title={i18n.t('status')} width={190} />
                                <Column field="order_number_save" filter="text" title={i18n.t('order_number_save')} width={190} />
                                <Column field="shipping" filter="text" title={i18n.t('shipping')} width={190} />
                                <Column field="buyer_name" filter="text" title={i18n.t('buyer_name')} width={190} />
                                <Column field="is_licence" filter="text" title={i18n.t('is_licence')} width={190} />
                                <Column field="is_eui" filter="text" title={i18n.t('is_eui')} width={190} />
                                <Column field="vend_auth_number" filter="text" title={i18n.t('vend_auth_number')} width={190} />
                                <Column field="licence_reorder" filter="text" title={i18n.t('licence_reorder')} width={190} />

                                <Column field="invoice_present" filter="boolean" title={i18n.t('action')} locked={true} cell={actionCell} width={100} />
                            </Grid>
                        </div>


                    </ExcelExport>
                </IntlProvider>
            </LocalizationProvider>
            {props.isLoading && loadingPanel}
        </div>
    );
}

export default connect(
    (state: ApplicationState) => ({
        ...state.supplierOrders,
        ...state.Tabs,
        ...state.connectwise
    }), // Selects which state properties are merged into the component's props
    {...SupplierOrdersStore.actionCreators, ...TabsStore.actionCreators, ...ConnectWiseStore.actionCreators} // Selects which action creators are merged into the component's props
)(SupplierOrdersGrid as any); // eslint-disable-line @typescript-eslint/no-explicit-any