import { CompositeFilterDescriptor, GroupDescriptor, SortDescriptor, State, toDataSourceRequestString } from '@progress/kendo-data-query';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { LOCATION_CHANGE } from 'react-router-redux'
import React from 'react';
import { stat } from 'fs';
import { AddressFromParent, SupplierOrder, SupplierOrderHeader, SupplierOrderShippingAddress } from '../interfaces';
import { fetchUrl, handleLogoutOnSessionExpire } from '../../Module/Fetch';
import { CookiesUtil } from '../../Module/CookiesUtil';


// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface SupplierOrderDetailState {
    isLoading: boolean;
    supplierOrder: SupplierOrderHeader | null;
    shippingAddresses: SupplierOrderShippingAddress[] | null
    updatedShippingAddresses: SupplierOrderShippingAddress[] | null,
    updatedEUI: SupplierOrderHeader | null,
    refresh: boolean
    editShipping: boolean
    editEUI: boolean,
    dealerShippingAddress: AddressFromParent | null
    disableEditShippingAddress: boolean,
    enterEditShippingFees: boolean,
    shippingFees: any
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestOrdersAction {
    type: 'REQUEST_SUPPLIER_ORDER';
}

interface ReceiveOrdersAction {
    type: 'RECEIVE_SUPPLIER_ORDER';
    supplierOrder: SupplierOrderHeader;
    shippingAddresses: SupplierOrderShippingAddress[],
}

interface OnChangeShippingAddressAction {
    type: 'ON_CHANGE_SHIPPING_ADDRESS';
    updatedShippingAddresses: SupplierOrderShippingAddress[] | null
}

interface OnChangeEUIAction {
    type: 'ON_CHANGE_EUI';
    orderHeader: SupplierOrderHeader | null,
}

interface OnChangeShippingLocationAction {
    type: 'ON_CHANGE_SHIPPING_LOCATION';
    upShippingAddresses: SupplierOrderShippingAddress[] | null
    disableEditShippingAddress: boolean
}

interface OnReceiveSupplierOrderShippingAddressUpdateAction {
    type: 'RECEIVE_SUPPLIER_ORDER_SHIPPING_ADDRESS_UPDATE_RESULT';
    updatedShippingAddresses: SupplierOrderShippingAddress[] | null
}


interface OnRequestSupplierOrderShippingAddressUpdateAction {
    type: 'REQUEST_SUPPLIER_ORDER_SHIPPING_ADDRESS_UPDATE_RESULT';
}

interface OnReceiveEUIUpdateAction {
    type: 'RECEIVE_EUI_UPDATE_RESULT';
    eui: SupplierOrderHeader | null
}

interface OnRequestEUIUpdateAction {
    type: 'REQUEST_EUI_UPDATE_RESULT';
}

interface onCancelEditShipping {
    type: "ON_CANCEL_EDIT_SHIPPING";
}

interface onCancelEditEUI {
    type: "ON_CANCEL_EDIT_EUI";
}

interface onEnterEditShippingAction {
    type: "ON_ENTER_EDIT_SHIPPING";
    disableEditShippingAddress : boolean
}

interface onExitEditShippingAction {
    type: "ON_EXIT_EDIT_SHIPPING";
}

interface onEnterEditEUIAction {
    type: "ON_ENTER_EDIT_EUI";
}

interface onExitEditEUIAction {
    type: "ON_EXIT_EDIT_EUI";
}

interface OnReceiveDealerShippingAddressAction {
    type: 'RECEIVE_DEALER_SHIPPING_ADDRESS';
    dealerShippingAddress: AddressFromParent | null
}

interface OnReuestDealerShippingAddressAction {
    type: 'REQUEST_DEALER_SHIPPING_ADDRESS';
}

interface OnEnterEditShippingFees {
    type: "SUPPLIERORDER/ON_ENTER_EDIT_SHIPPING_FEES";
    enterEditShippingFees: boolean,
    shippingFees: number | undefined
}

interface onChangeShippingFees {
    type: "SUPPLIERORDER/ON_CHANGE_SHIPPING_FEES";
    shippingFees: number
}

interface OnUpdateShippingFees {
    type: "SUPPLIERORDER/ON_CHANGE_SHIPPING_FEES";
    shippingFees: number
}

interface onCancelEditShippingFees {
    type: "SUPPLIERORDER/ON_CANCEL_EDIT_SHIPPING_FEES";
    enterEditShippingFees: boolean
}

interface OnRequestShippingFeesUpdate {
    type: "SUPPLIERORDER/ON_REQUEST_SHIPPING_FEES_UPDATE";
}

interface OnRecieveShippingFeesUpdate {
    type: "SUPPLIERORDER/ON_RECEIVE_SHIPPING_FEES_UPDATE";
}

//
// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestOrdersAction | ReceiveOrdersAction |
    OnChangeShippingAddressAction | OnReceiveSupplierOrderShippingAddressUpdateAction |
    OnRequestSupplierOrderShippingAddressUpdateAction | OnChangeEUIAction | OnReceiveEUIUpdateAction |
    OnRequestEUIUpdateAction | onCancelEditShipping | onCancelEditEUI | onEnterEditShippingAction |
    onExitEditShippingAction | onEnterEditEUIAction | onExitEditEUIAction | OnReceiveDealerShippingAddressAction |
    OnReuestDealerShippingAddressAction | OnChangeShippingLocationAction | OnEnterEditShippingFees | 
    onChangeShippingFees | OnUpdateShippingFees | onCancelEditShippingFees | OnRequestShippingFeesUpdate | OnRecieveShippingFeesUpdate;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).


export const actionCreators = {
    fetchDealerShippingAddress: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetchUrl(`/dealers/GetShippingAddress`, "GET")
            .then(response => {
                handleLogoutOnSessionExpire(response);
                return response.json() as Promise<AddressFromParent>
            })
            .then((data: AddressFromParent) => {
                dispatch({ type: 'RECEIVE_DEALER_SHIPPING_ADDRESS', dealerShippingAddress: data });
            });

        dispatch({ type: 'REQUEST_DEALER_SHIPPING_ADDRESS' });
    },
    fetchOrderHeader: (orderId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetchUrl(`/orders/GetSupplierOrderHeader?orderId=${orderId}`, "GET")
            .then(response => {
                handleLogoutOnSessionExpire(response);
                return response.json() as Promise<SupplierOrderHeader>
            })
            .then((data: SupplierOrderHeader) => {
                data.external_links_parsed = JSON.parse(data.external_links);
                dispatch({ type: 'RECEIVE_SUPPLIER_ORDER', supplierOrder: data, shippingAddresses: JSON.parse(data.shipping_address) });
            });

        dispatch({ type: 'REQUEST_SUPPLIER_ORDER' });
    },
    onChangeShippingData: (event: any, orderId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        var newShippingAddresses: SupplierOrderShippingAddress[] | null = null;

        if (state.SupplierOrderDetail != undefined) {
            newShippingAddresses = Object.assign([], state.SupplierOrderDetail.updatedShippingAddresses);
        }

        if (newShippingAddresses != null) {
            var shippingAddress = { ...newShippingAddresses[0] };
            switch (event.target.name) {
                case "name":
                    shippingAddress.first_name = event.target.value
                    break
                case "street1":
                    shippingAddress.street1 = event.target.value
                    break
                case "street2":
                    shippingAddress.street2 = event.target.value
                    break
                case "zip":
                    shippingAddress.zip = event.target.value
                    break
                case "province":
                    shippingAddress.province = event.target.value
                    break
                case "city":
                    shippingAddress.city = event.target.value
                    break
                case "country":
                    shippingAddress.country = event.target.value
                    break
            }
            dispatch({ type: 'ON_CHANGE_SHIPPING_ADDRESS', updatedShippingAddresses: [shippingAddress] });
        }
    },
    onChangeEUI: (event: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        var newOrderHeader: any | null = null;

        if (state.SupplierOrderDetail != undefined) {
            newOrderHeader = JSON.parse(JSON.stringify(state.SupplierOrderDetail.updatedEUI));
        }

        if (newOrderHeader != null) {
            var value: any = event.target.value;
            if (event.target.name == "isdropship") {

                value = (event.target.value == "true");
            }
            (newOrderHeader as any)[event.target.name] = value
        }

        dispatch({ type: 'ON_CHANGE_EUI', orderHeader: newOrderHeader });
    },
    onChangeShippingLocation: (event: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        var disableEditShippingAddress = false;
        var value: any = event.target.value;
        var upShippingAddress: SupplierOrderShippingAddress[] | null | undefined | any = [];
        var dealerShippingAddress = state.SupplierOrderDetail?.dealerShippingAddress;
        var shippingAddresses = state.SupplierOrderDetail?.shippingAddresses;
        value = (event.target.value == "true");
        if (!value) {
            if (dealerShippingAddress) {
                upShippingAddress = JSON.parse(JSON.stringify(state.SupplierOrderDetail?.shippingAddresses));
                disableEditShippingAddress = true;
                if (!upShippingAddress) {
                    upShippingAddress = [{}];
                }
                upShippingAddress[0].city = dealerShippingAddress?.city
                upShippingAddress[0].country = dealerShippingAddress?.country
                upShippingAddress[0].zip = dealerShippingAddress?.zip
                upShippingAddress[0].street1 = dealerShippingAddress?.street1
                upShippingAddress[0].street2 = dealerShippingAddress?.street2
            } else {
                upShippingAddress = shippingAddresses
            }
        } else {
            disableEditShippingAddress = false;

            upShippingAddress = shippingAddresses
            value = false;
        }

        dispatch({ type: 'ON_CHANGE_SHIPPING_LOCATION', upShippingAddresses: upShippingAddress, disableEditShippingAddress: disableEditShippingAddress });
    },
    onSaveShipping: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        var state = getState();
        var shppingAddress = null;
        if (state.SupplierOrderDetail?.shippingAddresses != null) {
            shppingAddress = state.SupplierOrderDetail?.shippingAddresses[0];
        }

        fetchUrl(`/SupplierOrders/UpdateShippingAddress`, "PUT", { body: JSON.stringify(shppingAddress) }, { "Content-Type": "application/json" })
            .then(response => {
                handleLogoutOnSessionExpire(response);
                return response.json() as Promise<SupplierOrderShippingAddress>
            })
            .then((data: SupplierOrderShippingAddress) => {
                CookiesUtil.setNotificationMessages({
                    subject: "",
                    message: "L'adresse a etait enregistre avec succès",
                    type: "success",
                    created: new Date(),
                    expire: 13000,
                    read: false
                });
                dispatch({ type: 'RECEIVE_SUPPLIER_ORDER_SHIPPING_ADDRESS_UPDATE_RESULT', updatedShippingAddresses: [data] });
            });
        dispatch({ type: 'REQUEST_SUPPLIER_ORDER_SHIPPING_ADDRESS_UPDATE_RESULT' });

    },
    onCancelEditShipping: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'ON_CANCEL_EDIT_SHIPPING' });
    },
    onSaveEUI: (displayNotification: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        var state = getState();
        var EUI = null;
        if (state.SupplierOrderDetail?.updatedEUI != null) {
            EUI = state.SupplierOrderDetail?.updatedEUI;
        }

        fetchUrl(`/SupplierOrders/UpdateEUI`, "PUT", { body: JSON.stringify(EUI) }, { "Content-Type": "application/json" })
            .then(response => {
                handleLogoutOnSessionExpire(response);
                return response.json() as Promise<SupplierOrderHeader>
            })
            .then((data: SupplierOrderHeader) => {
                if (displayNotification) {
                    CookiesUtil.setNotificationMessages({
                        subject: "",
                        message: "Les information de l'utilisateurs ont etait enregistre avec succès",
                        type: "success",
                        created: new Date(),
                        expire: 13000,
                        read: false
                    });
                }

                dispatch({ type: 'RECEIVE_EUI_UPDATE_RESULT', eui: data });
            });
        dispatch({ type: 'REQUEST_EUI_UPDATE_RESULT' });

    },
    onCancelEditEui: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'ON_CANCEL_EDIT_EUI' });
    },
    enterEditShipping: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        var state = getState();
        var disableEditShippingAddress = false;
        if (state.SupplierOrderDetail) {
            disableEditShippingAddress = !state.SupplierOrderDetail?.supplierOrder?.isdropship;
        }
        dispatch({ type: 'ON_ENTER_EDIT_SHIPPING', disableEditShippingAddress: disableEditShippingAddress  });
    },
    exitEditShipping: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'ON_EXIT_EDIT_SHIPPING' });
    },
    enterEditEUI: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'ON_ENTER_EDIT_EUI'});
    },
    exitEditEUI: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'ON_EXIT_EDIT_EUI' });
    },
    onEnterEditShippingFees: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        var shippingFees = getState().SupplierOrderDetail?.supplierOrder?.shipping_fee;
        dispatch({ type: "SUPPLIERORDER/ON_ENTER_EDIT_SHIPPING_FEES", enterEditShippingFees: true, shippingFees: shippingFees })
    },
    onChangeShippingFees: (event: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "SUPPLIERORDER/ON_CHANGE_SHIPPING_FEES", shippingFees: event.target.value })
    },
    onUpdateShippingFees: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
       var state = getState();
        var shippingFees = state.SupplierOrderDetail?.shippingFees;
        var orderId = state.SupplierOrderDetail?.supplierOrder?.estaff_order_id;

        fetchUrl(`/SupplierOrders/UpdateShippingFees`, "PUT", { body: JSON.stringify({ orderId: orderId, shippingFees }) }, { "Content-Type": "application/json" })
            .then(response => {
                handleLogoutOnSessionExpire(response);
                return response.json() as Promise<number>
            })
            .then((data: number) => {
                CookiesUtil.setNotificationMessages({
                    subject: "",
                    message: "Les frais de livraison ont etait enregistre avec succès",
                    type: "success",
                    created: new Date(),
                    expire: 13000,
                    read: false
                });
                dispatch({ type: 'SUPPLIERORDER/ON_RECEIVE_SHIPPING_FEES_UPDATE'});
            });
        dispatch({ type: 'SUPPLIERORDER/ON_REQUEST_SHIPPING_FEES_UPDATE' });
    },
    OnCancelEditShippingFees: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SUPPLIERORDER/ON_CANCEL_EDIT_SHIPPING_FEES', enterEditShippingFees: false })
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: SupplierOrderDetailState = {
    supplierOrder: null,
    isLoading: false,
    shippingAddresses: null,
    updatedShippingAddresses: null,
    refresh: false,
    updatedEUI: null,
    editShipping: false,
    editEUI: false,
    dealerShippingAddress: null,
    disableEditShippingAddress: false,
    enterEditShippingFees: false,
    shippingFees: null
};

export const reducer: Reducer<SupplierOrderDetailState> = (state: SupplierOrderDetailState | undefined, incomingAction: Action): SupplierOrderDetailState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_SUPPLIER_ORDER':
            return {
                supplierOrder: state.supplierOrder,
                isLoading: true,
                shippingAddresses: state.shippingAddresses,
                updatedShippingAddresses: state.updatedShippingAddresses,
                refresh: false,
                updatedEUI: state.updatedEUI,
                editShipping: state.editShipping,
                editEUI: state.editEUI,
                dealerShippingAddress: state.dealerShippingAddress,
                disableEditShippingAddress: state.disableEditShippingAddress,
                enterEditShippingFees: state.enterEditShippingFees,
                shippingFees: state.shippingFees
            };
        case 'RECEIVE_SUPPLIER_ORDER':
            return {
                supplierOrder: action.supplierOrder,
                isLoading: false,
                shippingAddresses: action.shippingAddresses,
                updatedShippingAddresses: action.shippingAddresses,
                refresh: false,
                updatedEUI: action.supplierOrder,
                editShipping: state.editShipping,
                editEUI: state.editEUI,
                dealerShippingAddress: state.dealerShippingAddress,
                disableEditShippingAddress: state.disableEditShippingAddress,
                enterEditShippingFees: state.enterEditShippingFees,
                shippingFees: state.shippingFees
            };
        case 'ON_CHANGE_SHIPPING_ADDRESS':
            return {
                supplierOrder: state.supplierOrder,
                isLoading: false,
                shippingAddresses: state.shippingAddresses,
                updatedShippingAddresses: action.updatedShippingAddresses,
                refresh: false,
                updatedEUI: state.updatedEUI,
                editShipping: state.editShipping,
                editEUI: state.editEUI,
                dealerShippingAddress: state.dealerShippingAddress,
                disableEditShippingAddress: state.disableEditShippingAddress,
                enterEditShippingFees: state.enterEditShippingFees,
                shippingFees: state.shippingFees
            };
        case 'RECEIVE_SUPPLIER_ORDER_SHIPPING_ADDRESS_UPDATE_RESULT':
            return {
                supplierOrder: state.supplierOrder,
                isLoading: false,
                shippingAddresses: state.shippingAddresses,
                updatedShippingAddresses: action.updatedShippingAddresses,
                refresh: true,
                updatedEUI: state.updatedEUI,
                editShipping: state.editShipping,
                editEUI: state.editEUI,
                dealerShippingAddress: state.dealerShippingAddress,
                disableEditShippingAddress: state.disableEditShippingAddress,
                enterEditShippingFees: state.enterEditShippingFees,
                shippingFees: state.shippingFees
            };
        case 'REQUEST_SUPPLIER_ORDER_SHIPPING_ADDRESS_UPDATE_RESULT':
            return {
                supplierOrder: state.supplierOrder,
                isLoading: true,
                shippingAddresses: state.shippingAddresses,
                updatedShippingAddresses: state.updatedShippingAddresses,
                refresh: false,
                updatedEUI: state.updatedEUI,
                editShipping: state.editShipping,
                editEUI: state.editEUI,
                dealerShippingAddress: state.dealerShippingAddress,
                disableEditShippingAddress: state.disableEditShippingAddress,
                enterEditShippingFees: state.enterEditShippingFees,
                shippingFees: state.shippingFees
            };
        case 'ON_CHANGE_EUI':
            return {
                supplierOrder: state.supplierOrder,
                isLoading: false,
                shippingAddresses: state.shippingAddresses,
                updatedShippingAddresses: state.updatedShippingAddresses,
                refresh: false,
                updatedEUI: action.orderHeader,
                editShipping: state.editShipping,
                editEUI: state.editEUI,
                dealerShippingAddress: state.dealerShippingAddress,
                disableEditShippingAddress: state.disableEditShippingAddress,
                enterEditShippingFees: state.enterEditShippingFees,
                shippingFees: state.shippingFees
            };
        case 'REQUEST_EUI_UPDATE_RESULT':
            return {
                supplierOrder: state.supplierOrder,
                isLoading: true,
                shippingAddresses: state.shippingAddresses,
                updatedShippingAddresses: state.updatedShippingAddresses,
                refresh: false,
                updatedEUI: state.updatedEUI,
                editShipping: state.editShipping,
                editEUI: state.editEUI,
                dealerShippingAddress: state.dealerShippingAddress,
                disableEditShippingAddress: state.disableEditShippingAddress,
                enterEditShippingFees: state.enterEditShippingFees,
                shippingFees: state.shippingFees
            };
        case 'RECEIVE_EUI_UPDATE_RESULT':
            return {
                supplierOrder: state.supplierOrder,
                isLoading: false,
                shippingAddresses: state.shippingAddresses,
                updatedShippingAddresses: state.updatedShippingAddresses,
                refresh: true,
                updatedEUI: action.eui,
                editShipping: state.editShipping,
                editEUI: state.editEUI,
                dealerShippingAddress: state.dealerShippingAddress,
                disableEditShippingAddress: state.disableEditShippingAddress,
                enterEditShippingFees: state.enterEditShippingFees,
                shippingFees: state.shippingFees
            };
        case 'ON_CANCEL_EDIT_SHIPPING':
            return {
                supplierOrder: state.supplierOrder,
                isLoading: false,
                shippingAddresses: state.shippingAddresses,
                updatedShippingAddresses: state.shippingAddresses,
                refresh: false,
                updatedEUI: state.updatedEUI,
                editShipping: state.editShipping,
                editEUI: state.editEUI,
                dealerShippingAddress: state.dealerShippingAddress,
                disableEditShippingAddress: state.disableEditShippingAddress,
                enterEditShippingFees: state.enterEditShippingFees,
                shippingFees: state.shippingFees
            };
        case 'ON_CANCEL_EDIT_EUI':
            return {
                supplierOrder: state.supplierOrder,
                isLoading: false,
                shippingAddresses: state.shippingAddresses,
                updatedShippingAddresses: state.shippingAddresses,
                refresh: false,
                updatedEUI: state.supplierOrder,
                editShipping: state.editShipping,
                editEUI: state.editEUI,
                dealerShippingAddress: state.dealerShippingAddress,
                disableEditShippingAddress: state.disableEditShippingAddress,
                enterEditShippingFees: state.enterEditShippingFees,
                shippingFees: state.shippingFees
            };
        case 'ON_ENTER_EDIT_SHIPPING':
            return {
                supplierOrder: state.supplierOrder,
                isLoading: false,
                shippingAddresses: state.shippingAddresses,
                updatedShippingAddresses: state.shippingAddresses,
                refresh: false,
                updatedEUI: state.supplierOrder,
                editShipping: true,
                editEUI: state.editEUI,
                dealerShippingAddress: state.dealerShippingAddress,
                disableEditShippingAddress: action.disableEditShippingAddress,
                enterEditShippingFees: state.enterEditShippingFees,
                shippingFees: state.shippingFees
            };
        case 'ON_EXIT_EDIT_SHIPPING':
            return {
                supplierOrder: state.supplierOrder,
                isLoading: false,
                shippingAddresses: state.shippingAddresses,
                updatedShippingAddresses: state.shippingAddresses,
                refresh: false,
                updatedEUI: state.supplierOrder,
                editShipping: false,
                editEUI: state.editEUI,
                dealerShippingAddress: state.dealerShippingAddress,
                disableEditShippingAddress: state.disableEditShippingAddress,
                enterEditShippingFees: state.enterEditShippingFees,
                shippingFees: state.shippingFees
            };
        case 'ON_ENTER_EDIT_EUI':
            return {
                supplierOrder: state.supplierOrder,
                isLoading: false,
                shippingAddresses: state.shippingAddresses,
                updatedShippingAddresses: state.updatedShippingAddresses,
                refresh: false,
                updatedEUI: state.supplierOrder,
                editShipping: state.editShipping,
                editEUI: true,
                dealerShippingAddress: state.dealerShippingAddress,
                disableEditShippingAddress: state.disableEditShippingAddress,
                enterEditShippingFees: state.enterEditShippingFees,
                shippingFees: state.shippingFees
            };
        case 'ON_EXIT_EDIT_EUI':
            return {
                supplierOrder: state.supplierOrder,
                isLoading: false,
                shippingAddresses: state.shippingAddresses,
                updatedShippingAddresses: state.updatedShippingAddresses,
                refresh: false,
                updatedEUI: state.supplierOrder,
                editShipping: state.editShipping,
                editEUI: false,
                dealerShippingAddress: state.dealerShippingAddress,
                disableEditShippingAddress: state.disableEditShippingAddress,
                enterEditShippingFees: state.enterEditShippingFees,
                shippingFees: state.shippingFees
            };

        case 'RECEIVE_DEALER_SHIPPING_ADDRESS':
            return {
                supplierOrder: state.supplierOrder,
                isLoading: false,
                shippingAddresses: state.shippingAddresses,
                updatedShippingAddresses: state.updatedShippingAddresses,
                refresh: false,
                updatedEUI: state.updatedEUI,
                editShipping: state.editShipping,
                editEUI: state.editEUI,
                dealerShippingAddress: action.dealerShippingAddress,
                disableEditShippingAddress: state.disableEditShippingAddress,
                enterEditShippingFees: state.enterEditShippingFees,
                shippingFees: state.shippingFees
            };

        case 'ON_CHANGE_SHIPPING_LOCATION':
            return {
                supplierOrder: state.supplierOrder,
                isLoading: false,
                shippingAddresses: state.shippingAddresses,
                updatedShippingAddresses: action.upShippingAddresses,
                refresh: false,
                updatedEUI: state.updatedEUI,
                editShipping: state.editShipping,
                editEUI: state.editEUI,
                dealerShippingAddress: state.dealerShippingAddress,
                disableEditShippingAddress: action.disableEditShippingAddress,
                enterEditShippingFees: state.enterEditShippingFees,
                shippingFees: state.shippingFees
            };
        case "SUPPLIERORDER/ON_ENTER_EDIT_SHIPPING_FEES":
            return {
                supplierOrder: state.supplierOrder,
                isLoading: false,
                shippingAddresses: state.shippingAddresses,
                updatedShippingAddresses: state.updatedShippingAddresses,
                refresh: false,
                updatedEUI: state.updatedEUI,
                editShipping: state.editShipping,
                editEUI: state.editEUI,
                dealerShippingAddress: state.dealerShippingAddress,
                disableEditShippingAddress: state.disableEditShippingAddress,
                enterEditShippingFees: action.enterEditShippingFees,
                shippingFees: action.shippingFees
            }
        case "SUPPLIERORDER/ON_CHANGE_SHIPPING_FEES":
            return {
                supplierOrder: state.supplierOrder,
                isLoading: false,
                shippingAddresses: state.shippingAddresses,
                updatedShippingAddresses: state.updatedShippingAddresses,
                refresh: false,
                updatedEUI: state.updatedEUI,
                editShipping: state.editShipping,
                editEUI: state.editEUI,
                dealerShippingAddress: state.dealerShippingAddress,
                disableEditShippingAddress: state.disableEditShippingAddress,
                enterEditShippingFees: state.enterEditShippingFees,
                shippingFees: action.shippingFees
            }
        case "SUPPLIERORDER/ON_CANCEL_EDIT_SHIPPING_FEES":
            return {
                supplierOrder: state.supplierOrder,
                isLoading: false,
                shippingAddresses: state.shippingAddresses,
                updatedShippingAddresses: state.updatedShippingAddresses,
                refresh: false,
                updatedEUI: state.updatedEUI,
                editShipping: state.editShipping,
                editEUI: state.editEUI,
                dealerShippingAddress: state.dealerShippingAddress,
                disableEditShippingAddress: state.disableEditShippingAddress,
                enterEditShippingFees: action.enterEditShippingFees,
                shippingFees: state.shippingFees
            }
        case "SUPPLIERORDER/ON_REQUEST_SHIPPING_FEES_UPDATE":
            return {
                supplierOrder: state.supplierOrder,
                isLoading: true,
                shippingAddresses: state.shippingAddresses,
                updatedShippingAddresses: state.updatedShippingAddresses,
                refresh: false,
                updatedEUI: state.updatedEUI,
                editShipping: state.editShipping,
                editEUI: state.editEUI,
                dealerShippingAddress: state.dealerShippingAddress,
                disableEditShippingAddress: state.disableEditShippingAddress,
                enterEditShippingFees: state.enterEditShippingFees,
                shippingFees: state.shippingFees
            }
        case "SUPPLIERORDER/ON_RECEIVE_SHIPPING_FEES_UPDATE":
            return {
                supplierOrder: state.supplierOrder,
                isLoading: false,
                shippingAddresses: state.shippingAddresses,
                updatedShippingAddresses: state.updatedShippingAddresses,
                refresh: true,
                updatedEUI: state.updatedEUI,
                editShipping: state.editShipping,
                editEUI: state.editEUI,
                dealerShippingAddress: state.dealerShippingAddress,
                disableEditShippingAddress: state.disableEditShippingAddress,
                enterEditShippingFees: false,
                shippingFees: state.shippingFees
            }
        default:
            return state;
    }
};
