import React from "react";
import { fetchUrl } from "../Module/Fetch";

export class ErrorHandler extends React.Component {
    state: Readonly<{hasError: boolean, error: any}>;
    
    constructor(props : any) {
      super(props);
      this.state = { hasError: false, error: null};
    }
    
    private promiseRejectionHandler = (event: PromiseRejectionEvent) => {
      console.log(event);
      this.setState({
          hasError: false,
          error: event.reason
      });
      //@todo log error
    }

    componentDidMount() {
      // Add an event listener to the window to catch unhandled promise rejections & stash the error in the state
      window.addEventListener('unhandledrejection', this.promiseRejectionHandler)
    }

    componentWillUnmount() {
        window.removeEventListener('unhandledrejection', this.promiseRejectionHandler);
    }

    static getDerivedStateFromError(error  : any) {
      // Update state to trigger fallback UI
      return { hasError: true };
    }
  
    componentDidCatch(error : any, errorInfo : any) {
        console.log(error);
        console.log(errorInfo);
        fetchUrl("/UILogger/log", "POST", { body: JSON.stringify(error)}, { "Content-Type": "application/json" })
        .then((response) => {
          return response.json()
        })
        .then((results) => {
          console.log(results);
        });    
        // Log error or send logging data to log management tool
      //logErrorToMyService(error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        // Render fallback UI
        return <h1>Something went wrong.</h1>;
      }
  
      return this.props.children; 
    }
  }