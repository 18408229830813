
import * as React from "react";
import { useTranslation } from "react-i18next";

export const CustomerOrderItemCommandCell = (props: any) => {
  const { t, i18n } = useTranslation();
  const { dataItem } = props;
  return (
    <td colSpan={props.colSpan} style={props.style} className={props.className}>
      <a
        href="#"
        onClick={(e) => { props.remove(dataItem) }}
        className="fa fa-trash action-link"
      ></a>
    </td>
  );
};