import { PublicClientApplication } from "@azure/msal-browser";
import { authConfig } from "../authConfig";
import { CookiesUtil } from "./CookiesUtil";

export const fetchUrl = (
    url : string, method : string,
    extraOptions: {} | null | FormData = null,
    contentType : HeadersInit | undefined = undefined, isThirdPartyAPI : boolean = false
) : Promise<Response> => {

    const headers = new Headers(contentType);
    const bearer = `Bearer ${CookiesUtil.getCookieValue("accessToken")}`;
    if (!isThirdPartyAPI) {
        headers.append("Authorization", bearer);
    }
    const options = {
        method: method,
        credentials: "include" as RequestCredentials,
        headers: headers
    };

    Object.assign(options, extraOptions);

    if (url.startsWith("http")) {
        return  fetch(url, options)
    } else {
        return  fetch(process.env.REACT_APP_API_URL + url, options)
    }
}

export function handleLogoutOnSessionExpire(response: any) {
    const pca = new PublicClientApplication(authConfig);
    if (response.status === 401) {
        pca.logoutRedirect().catch((e : any) => {
            console.error(e);
        });
    }
}