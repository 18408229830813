import { CompositeFilterDescriptor, GroupDescriptor, SortDescriptor, State, toDataSourceRequestString } from '@progress/kendo-data-query';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { LOCATION_CHANGE } from 'react-router-redux'
import React from 'react';
import { stat } from 'fs';
import './notifications.css';
import { AccessToken } from '../../components/AccessToken';

export interface NotificationContainer {
    title: string;
    message: string;
    type: string;
    insert: string;
    container: string,
    animationIn: String[],
    animationOut: String[],
    dismiss: {}
}

export interface NotificationMessage {
    notification_message_id: number;
    subject: string;
    message_body: string;
}

export interface NotificationState {
    isLoading: boolean;
    notificationMessagesPopUps: NotificationContainer[];
    sessionExpired: Boolean,
    notificationMessages: NotificationMessage[]
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestNotificationMessagesAction {
    type: 'REQUEST_NOTIFICATION_MESSAGES';
}

interface RecievetNotificationMessagesAction {
    type: 'RECEIVE_NOTIFICATION_MESSAGES';
    notificationMessages: NotificationMessage[];
    notificationMessagesPopUps: NotificationContainer[];
}

interface LogoutAction {
    type: 'LOGOUT';
    sessionExpired: Boolean
}

interface NotificationMessageIsSetToRead {
    type: 'NOTIFICATION_MESSAGES_SET_TO_READ';
}

interface RequestNotificationMessageIsSetToRead {
    type: 'REQUEST_NOTIFICATION_MESSAGES_SET_TO_READ';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestNotificationMessagesAction | RecievetNotificationMessagesAction | LogoutAction | NotificationMessageIsSetToRead | RequestNotificationMessageIsSetToRead;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).


export const actionCreators = {
    requestNotificationMessages: (acsessToken: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.notifications && appState.notifications.isLoading == false && appState.notifications.sessionExpired == false) {
            const headers = new Headers();
            const bearer = `Bearer ${acsessToken}`;
            headers.append("Authorization", bearer);
            const options = {
                method: "GET",
                headers: headers
            };

            fetch(`${process.env.REACT_APP_API_URL}/notifications/GetNewMessages`, options)
                .then(response => {
                    if (response.status == 401) {
                        dispatch({ type: 'LOGOUT', sessionExpired: true });
                        window.location.reload();
                    }
                    return response
                })
                .then(response => response.json() as Promise<NotificationMessage[]>)
                .then(messages => {
                    const messagesContainer: NotificationContainer[] = [];
                    messages.map(function (message: NotificationMessage) {
                        messagesContainer.push({
                            title: message.subject,
                            message: message.message_body,
                            type: "default",
                            insert: "top",
                            container: "bottom-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 10000,
                                onScreen: true,
                                showIcon: true,
                            }
                        });
                    });

                    if (messages.length > 0) {
                        dispatch({ type: 'REQUEST_NOTIFICATION_MESSAGES_SET_TO_READ' });
                        const headers = new Headers();
                        const bearer = `Bearer ${acsessToken}`;
                        headers.append("Authorization", bearer);
                        headers.append("Content-Type", 'application/json');
                        const messagIds: any[] = [];
                        messages.map((message) => {
                            messagIds.push(message.notification_message_id)
                        });
                        const options = {
                            method: "POST",
                            headers: headers,
                            body: JSON.stringify(messagIds)
                        };
                
                        fetch(`${process.env.REACT_APP_API_URL}/notifications/UpdateMessagIsSent`, options)
                            .then(response => {
                                if (response.status == 401) {
                                    dispatch({ type: 'LOGOUT', sessionExpired: true });
                                }
                                return response
                            })
                            .then(response => response.json() as Promise<NotificationMessage[]>)
                            .then(messages => {
                                dispatch({ type: 'NOTIFICATION_MESSAGES_SET_TO_READ' });
                
                            }).catch(e => {
                                console.log(e);
                            });
                    }
                    dispatch({ type: 'RECEIVE_NOTIFICATION_MESSAGES', notificationMessagesPopUps: messagesContainer, notificationMessages: messages });


                }).catch(e => {
                    console.log(e);
                });

            dispatch({ type: 'REQUEST_NOTIFICATION_MESSAGES' });

        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: NotificationState = { notificationMessagesPopUps: [], notificationMessages: [], isLoading: false, sessionExpired: false };

export const reducer: Reducer<NotificationState> = (state: NotificationState | undefined, incomingAction: Action): NotificationState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_NOTIFICATION_MESSAGES':
            return {
                notificationMessagesPopUps: state.notificationMessagesPopUps,
                notificationMessages: state.notificationMessages,
                isLoading: true,
                sessionExpired: false
            };
        case 'RECEIVE_NOTIFICATION_MESSAGES':
            return {
                notificationMessagesPopUps: action.notificationMessagesPopUps,
                notificationMessages: action.notificationMessages,
                isLoading: false,
                sessionExpired: false
            };
        case 'NOTIFICATION_MESSAGES_SET_TO_READ':
            return {
                notificationMessagesPopUps: state.notificationMessagesPopUps,
                notificationMessages: state.notificationMessages,
                isLoading: false,
                sessionExpired: false
            };
        case 'REQUEST_NOTIFICATION_MESSAGES_SET_TO_READ':
            return {
                notificationMessagesPopUps: state.notificationMessagesPopUps,
                notificationMessages: state.notificationMessages,
                isLoading: true,
                sessionExpired: false
            };
        case 'LOGOUT':
            return {
                notificationMessagesPopUps: state.notificationMessagesPopUps,
                notificationMessages: state.notificationMessages,
                isLoading: true,
                sessionExpired: action.sessionExpired
            };
        default:
            return state;
    }
};
