import { HeaderCellProps } from '@progress/kendo-react-data-tools';
import { GridCellProps, GridHeaderCellProps, GridRowProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Popover, PopoverActionsBar, Tooltip } from '@progress/kendo-react-tooltip';
import * as React from 'react';
import { Button } from 'reactstrap';

import { CustomerOrder } from '../store/interfaces';
import { MoneyFormat } from './CurrencyUtil';
import { DateTimeUtil } from './DateTimeUtil';

interface CellRenderProps {
  originalProps: GridCellProps;
  td: React.ReactElement<HTMLTableCellElement>;
  enterEdit: (dataItem: any, field: string | undefined) => void;
  save: () => void;
  exitEdit: () => void;
  editField: string | undefined;
}

interface RowRenderProps {
  originalProps: GridRowProps;
  tr: React.ReactElement<HTMLTableRowElement>;
  exitEdit: () => void;
  editField: string | undefined;
}

export const CellRender = (props: CellRenderProps) => {
  const dataItem = props.originalProps.dataItem;
  const cellField = props.originalProps.field;
  const inEditField = dataItem[props.editField || ''];

  const additionalProps =
    cellField && cellField === inEditField
      ? {
        ref: (td: any) => {
          const input = td && td.querySelector('input');
          const activeElement = document.activeElement;

          if (
            !input ||
            !activeElement ||
            input === activeElement ||
            !activeElement.contains(input)
          ) {
            return;
          }

          input.focus();
          input.addEventListener("keyup", (e: any) => {
            if (e.key === "Enter") {
              props.save()
            }
          });
        },
      }
      : {
        onClick: () => {
          props.enterEdit(dataItem, cellField);
        },
      };

  const clonedProps: any = { ...props.td.props, ...additionalProps };
  return React.cloneElement(props.td, clonedProps, props.td.props.children);
};

export const RowRender = (props: RowRenderProps) => {
  const trProps = {
    ...props.tr.props,
    onBlur: () => {
      props.exitEdit();
    },
  };
  return React.cloneElement(props.tr, { ...trProps }, props.tr.props.children);
};

export const MoneyCell = (props: GridCellProps) => {
  const field = props.field || "";
  return (
    <td colSpan={props.colSpan} style={props.style}>
      <div style={{ textAlign: "right" }}>
        {(props.dataItem[field] != null) ? MoneyFormat(props.dataItem[field], props.dataItem["currency_code"]) : ""}
      </div>
    </td>
  );
};

export const HtmlCell = (props: GridCellProps) => {
  const field = props.field || "";
  const s = props.dataItem[field];
  return (
    <td colSpan={props.colSpan} style={props.style}>
      <div dangerouslySetInnerHTML={{ __html: s }}></div>
    </td>
  );
};

export const HeaderTooltipCell = (props: GridHeaderCellProps, tooltipTitle: string, isOpen: boolean = false) => {
  return (
    <Tooltip showCallout={true} anchorElement="target" position="bottom">
      <a className="k-link" onClick={props.onClick} >
        <span title={tooltipTitle}>{props.title}</span>
      </a>
      {props.children}

    </Tooltip>
  );
}

export const c = (props: any, tooltipTitle: string, isOpen: boolean = false) => {
  const anchor: any = React.useRef(null);
  const [show, setShow] = React.useState(false);
  const [callout, setCallout] = React.useState(true);
  React.useEffect(() => {
    setShow(true);
  }, []);
  const onClick = () => {
    setShow(!show);
  };

  return (
    <div>
      <Button
        style={{
          left: 250,
          top: 100,
        }}
        onClick={onClick}
        disabled={show}
        ref={anchor}
      >
        Show
      </Button>
      <Popover
        show={true}
        anchor={anchor.current && anchor.current.element}
        callout={callout}
        margin={
          callout
            ? undefined
            : {
              vertical: 0,
              horizontal: 0,
            }
        }
        title={"Please confirm"}
        onPosition={(e) => console.log(e)}
      >
        Are you sure you want to continue?
        <PopoverActionsBar>
          <Button onClick={onClick} themeColor={"primary"}>
            Yes
          </Button>
          <Button onClick={onClick}>No</Button>
        </PopoverActionsBar>
      </Popover>
    </div>
  );

};


export const DateTimeCell = (cellProps: GridCellProps) => {
  const field = cellProps.field || "";
  return (
      <td colSpan={cellProps.colSpan} style={cellProps.style} className={cellProps.className}>
          {DateTimeUtil.toUserLocalDateTIme(cellProps.dataItem[field])}
      </td>
  );
};

export const DateCell = (cellProps: GridCellProps) => {
  const field = cellProps.field || "";
  return (
      <td colSpan={cellProps.colSpan} style={cellProps.style} className={cellProps.className}>
          {DateTimeUtil.toUserLocalDateTIme(cellProps.dataItem[field], false)}
      </td>
  );
};

export const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
