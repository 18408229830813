import React, { useEffect, useRef } from "react";
import i18n from "../../i18n";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import CustomerOrderItems from "./CustomerOrderItems";
import CustomerOrdersGrid from "./CustomerOrdersGrid";
import configureStore from "../../store/configureStore";
import { createBrowserHistory } from 'history';
import { connect, Provider } from "react-redux";
import { ApplicationState } from "../../store";
import { RouteComponentProps } from "react-router";
import SupplierOrderItems from "./SupplierOrderItems";


declare global {
  interface Window { reduxStores: any; }
}

export interface CustomerOrderDetailProps {
  orderId: number,
}

const SupplierOrderDetail = (props: CustomerOrderDetailProps) => {
  window.reduxStores = window.reduxStores || [];

  if (window.reduxStores[props.orderId] == null) {
    const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
    const history = createBrowserHistory({ basename: baseUrl });
    const store = configureStore(history);
    
  
    console.log("props==>", props.orderId);
    console.log("init stores==>", window.reduxStores);
  
    if (window.reduxStores[props.orderId] == null) {
      console.log("creating store for orderHeaderId==>", props.orderId);
      window.reduxStores[props.orderId] = store;
    }
  }


  return (
    <>
      <Provider store={window.reduxStores[props.orderId]} key={props.orderId} >
        <SupplierOrderItems orderId={props.orderId} key={props.orderId}/>
      </Provider>
    </>
  );
};


export default SupplierOrderDetail;