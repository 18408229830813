import * as React from 'react';
import { AutoComplete, AutoCompleteChangeEvent } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { filterBy } from '@progress/kendo-react-data-tools';
export const AutoCompleteFilterCell = props => {
  const [state, setState] = React.useState({
    data: props.data,
    value: props.value,
  });

  let hasValue = value => Boolean(value && value !== props.defaultItem);

  const filterData = (value) => {
    const data = props.data;
    const filter = [{
      field: "name",
      value: value,
      operator: "contains",
      ignoreCase: true,
    }];
    return value ? filterBy(data, filter) : data;
  };

  const onChange = (event) => {
    const value = event.value;
    setState({
      data: filterData(value),
      value: value,
    });

    if (event.nativeEvent.type == "click") {
      hasValue = hasValue(value);
      props.onChange({
        value: hasValue ? value : '',
        operator: hasValue ? 'eq' : '',
        syntheticEvent: event.syntheticEvent
      });

      setState({
        data: filterData(value),
        value: props.value,
      });
    }
  };

  return <div className="k-filtercell">
    <AutoComplete
      className='custom-filter'
      style={{
        width: "300px",
      }}
      textField="name"
      dataItemKey="order_status_id"
      data={state.data}
      value={state.value}
      onChange={onChange}
    />
  </div>;
};