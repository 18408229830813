import { DatePicker } from '@progress/kendo-react-dateinputs';
import * as React from 'react';
export const DateFilterCell = props => {
  let hasValue = value => Boolean(value && value !== props.defaultItem);

  const onChange = event => {
    hasValue = hasValue(event.target.value);
    var date = event.target.value;
    props.onChange({
      value: hasValue ? date : '',
      operator: hasValue ? 'eq' : '',
      syntheticEvent: event.syntheticEvent
    });
  };

  return <div className="k-filtercell">
        <DatePicker
              className='custom-filter'
              onChange={onChange}
              value={props.value}
              format="dd/MMM/yyyy"
            />
      </div>;
};