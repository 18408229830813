import { CompositeFilterDescriptor, GroupDescriptor, SortDescriptor, State, toDataSourceRequestString } from '@progress/kendo-data-query';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { LOCATION_CHANGE } from 'react-router-redux'
import React from 'react';
import { stat } from 'fs';
import { CwOrderResult, DealerModuleParams, SupplierOrder, User } from '../interfaces';
import { fetchUrl, handleLogoutOnSessionExpire } from '../../Module/Fetch';
import { CookiesUtil } from '../../Module/CookiesUtil';


// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface SupplierOrdersState {
    isLoading: boolean;
    total: number;
    supplierOrders: SupplierOrder[];
    dataState: State,
    exportedSupplierOrders: SupplierOrder[]
    confirmCreateOrderIntoCWIsVisible: boolean,
    orderToCreateIntoCW: SupplierOrder | null,
    refreshSupplierOrders: boolean,
    enterEditShippingFees: boolean,
    exitEditShippingFees: boolean,
}

const nameSpace = "SUPPLIERORDERS";
// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestOrdersAction {
    type: 'REQUEST_SUPPLIER_ORDERS';
    dataState: State
}

interface RequestExportOrdersAction {
    type: 'REQUEST_EXPORT_SUPPLIER_ORDERS';
}

interface ReceiveExportOrdersAction {
    type: 'RECEIVE_EXPORT_SUPPLIER_ORDERS';
    exportedSupplierOrders: SupplierOrder[];
}

interface ReceiveOrdersAction {
    type: 'RECEIVE_SUPPLIER_ORDERS';
    supplierOrders: SupplierOrder[];
    total: number,
    dataState: State
}

interface OnPropmtCreateOrderIntoCWAction {
    type: 'SUPPLIERORDER/ON_PROMPT_CREATE_ORDER_INTO_CW';
    orderToCreateIntoCw: SupplierOrder | null
}

export interface DataRecievedResults {
    data: SupplierOrder[],
    total: number
}

interface OnCloseCreateOrderIntoCWindowAction {
    type: 'ON_CLOSE_CREATE_ORDER_INTO_CW_WINDOW';
}

interface OnReceiveCreateIntoCWAction {
    type: 'SUPPLIERORDER/ON_RECEIVE_CREATE_INTO_CW';
}

interface OnRequestCreateIntoCWAction {
    type: 'SUPPLIERORDER/ON_REQUEST_CREATE_INTO_CW';
}

interface ReceiveDealerModuleParamsAction {
    type: 'RECEIVE_DEALER_MODULE_PARAMS';
    connectwiseParams: any
}

interface RequestDealerModuleParamsAction {
    type: 'REQUEST_DEALER_MODULE_PARAMS';
}

interface OnEnterEditShippingFees {
    type: 'SUPPLIERORDER/ON_ENTER_EDIT_SHIPPING_FEES';
    enterEditShippingFees: boolean;
}

interface OnExitEditShippingFees {
    type: 'SUPPLIERORDER/ON_EXIT_EDIT_SHIPPING_FEES';
}

interface OnRequestEditShippingFees {
    type: 'SUPPLIERORDER/ON_REQUEST_EDIT_SHIPPING_FEES';
}

interface OnReceiveEditShippingFees {
    type: 'SUPPLIERORDER/ON_RECEIVE_EDIT_SHIPPING_FEES';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestOrdersAction | ReceiveOrdersAction | RequestExportOrdersAction |
                    ReceiveExportOrdersAction | OnPropmtCreateOrderIntoCWAction |
                    OnCloseCreateOrderIntoCWindowAction | OnReceiveCreateIntoCWAction |
                    OnRequestCreateIntoCWAction | ReceiveDealerModuleParamsAction | RequestDealerModuleParamsAction |
                    OnEnterEditShippingFees | OnExitEditShippingFees | OnRequestEditShippingFees | OnReceiveEditShippingFees;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).


export const actionCreators = {
    requestOrders: (dataState: State): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        const req = toDataSourceRequestString(dataState);
        if (appState.supplierOrders !== undefined) {
            const gridState = toDataSourceRequestString(appState.supplierOrders.dataState);
            if (
                (appState && appState.supplierOrders && req != gridState &&
                appState.supplierOrders.isLoading == false) ||
                appState.supplierOrders.refreshSupplierOrders == true
                ) {
                fetchUrl(`/orders/GetSupplierOrders?${req}`, "GET")
                    .then(response => {
                        handleLogoutOnSessionExpire(response);
                        return response.json() as Promise<DataRecievedResults>
                    })
                    .then(data => {
                        dispatch({ type: 'RECEIVE_SUPPLIER_ORDERS', supplierOrders: data.data, total: data.total, dataState: dataState });
                    });

                dispatch({ type: 'REQUEST_SUPPLIER_ORDERS', dataState: dataState });
            }
        }
    },
    requestExportOrders: (dataState: State): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        const newState = {...dataState}
        newState.take = 100000000;
        const req = toDataSourceRequestString(newState);
        if (appState.customerOrders !== undefined) {
            if (appState && appState.customerOrders) {
                fetchUrl(`/orders/GetSupplierOrders?${req}`, "GET")
                    .then(response => {
                        handleLogoutOnSessionExpire(response);
                        return response.json() as Promise<DataRecievedResults>
                    })
                    .then(data => {
                        dispatch({ type: 'RECEIVE_EXPORT_SUPPLIER_ORDERS', exportedSupplierOrders: data.data});
                    });

                dispatch({ type: 'REQUEST_EXPORT_SUPPLIER_ORDERS'});
            }
        }
    },
    onPromptCreateOrderIntoCW: (orderId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let appState = getState();
        let supplierOrders = appState.supplierOrders?.supplierOrders;

        let supplierOrder : SupplierOrder | null = null;
        if (supplierOrders) {
            supplierOrder = supplierOrders?.find(e => e.estaff_order_number == orderId) as SupplierOrder | null;
        }

        dispatch({ type: "SUPPLIERORDER/ON_PROMPT_CREATE_ORDER_INTO_CW", orderToCreateIntoCw : supplierOrder})
    },
    CloseConfirmCreateOrderIntoCW: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "ON_CLOSE_CREATE_ORDER_INTO_CW_WINDOW" })
    },
    onCreateIntoCW: (supplierOrder: SupplierOrder | null): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const user : User = JSON.parse(CookiesUtil.getCookieValue("user")) as User;

        dispatch({ type: "SUPPLIERORDER/ON_REQUEST_CREATE_INTO_CW" })
        fetchUrl(
            process.env.REACT_APP_API_URL + "/Connectwise/CreatePO?orderId=" + supplierOrder?.estaff_order_number, "POST")
            .then((response) => {
                handleLogoutOnSessionExpire(response);
                return response.json() as Promise<CwOrderResult>
            })
            .then((result) => {
                var type: any = "error";
                if (result.code == "2") {

                    type = "success"
                }
                CookiesUtil.setNotificationMessages({
                    subject: "",
                    message: result.message,
                    type: type,
                    created: new Date(),
                    expire: 13000,
                    read: false
                });

                dispatch({ type: "SUPPLIERORDER/ON_RECEIVE_CREATE_INTO_CW" })
            });
    },
    fetchModuleParams: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState.customerOrders !== undefined) {
            fetchUrl(`/dealers/GetModulesParams?moduleName=TONY-CONNECTWISE`, "GET")
                .then((response) => {
                    handleLogoutOnSessionExpire(response);
                    return response.json() as Promise<DealerModuleParams>
                })
                .then((params) => {
                    dispatch({ type: 'RECEIVE_DEALER_MODULE_PARAMS', connectwiseParams: params });
                });
            dispatch({ type: 'REQUEST_DEALER_MODULE_PARAMS' });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: SupplierOrdersState = {
    supplierOrders: [],
    isLoading: false,
    total: 0,
    dataState: {},
    exportedSupplierOrders: [],
    confirmCreateOrderIntoCWIsVisible: false,
    orderToCreateIntoCW: null,
    refreshSupplierOrders: false,
    enterEditShippingFees: false,
    exitEditShippingFees: false
};

export const reducer: Reducer<SupplierOrdersState> = (state: SupplierOrdersState | undefined, incomingAction: Action): SupplierOrdersState => {
    if (state === undefined) {
        return unloadedState;
    }


    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_SUPPLIER_ORDERS':
            return {
                supplierOrders: state.supplierOrders,
                total: state.total,
                isLoading: true,
                dataState: state.dataState,
                exportedSupplierOrders: state.exportedSupplierOrders,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                orderToCreateIntoCW: state.orderToCreateIntoCW,
                refreshSupplierOrders: false,
                enterEditShippingFees: state.enterEditShippingFees,
                exitEditShippingFees: state.exitEditShippingFees
            };
        case 'RECEIVE_SUPPLIER_ORDERS':
            return {
                supplierOrders: action.supplierOrders,
                total: action.total,
                isLoading: false,
                dataState: action.dataState,
                exportedSupplierOrders: state.exportedSupplierOrders,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                orderToCreateIntoCW: state.orderToCreateIntoCW,
                refreshSupplierOrders: false,
                enterEditShippingFees: state.enterEditShippingFees,
                exitEditShippingFees: state.exitEditShippingFees

            };
        case 'REQUEST_EXPORT_SUPPLIER_ORDERS':
            return {
                supplierOrders: state.supplierOrders,
                total: state.total,
                isLoading: true,
                dataState: state.dataState,
                exportedSupplierOrders: state.exportedSupplierOrders,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                orderToCreateIntoCW: state.orderToCreateIntoCW,
                refreshSupplierOrders: false,
                enterEditShippingFees: state.enterEditShippingFees,
                exitEditShippingFees: state.exitEditShippingFees


            };
        case 'RECEIVE_EXPORT_SUPPLIER_ORDERS':
            return {
                supplierOrders: state.supplierOrders,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                exportedSupplierOrders: action.exportedSupplierOrders,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                orderToCreateIntoCW: state.orderToCreateIntoCW,
                refreshSupplierOrders: false,
                enterEditShippingFees: state.enterEditShippingFees,
                exitEditShippingFees: state.exitEditShippingFees
            };
        case 'SUPPLIERORDER/ON_PROMPT_CREATE_ORDER_INTO_CW':
            return {
                supplierOrders: state.supplierOrders,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                exportedSupplierOrders: state.exportedSupplierOrders,
                confirmCreateOrderIntoCWIsVisible: true,
                orderToCreateIntoCW: action.orderToCreateIntoCw,
                refreshSupplierOrders: false,
                enterEditShippingFees: state.enterEditShippingFees,
                exitEditShippingFees: state.exitEditShippingFees
            };
        case 'ON_CLOSE_CREATE_ORDER_INTO_CW_WINDOW':
            return {
                supplierOrders: state.supplierOrders,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                exportedSupplierOrders: state.exportedSupplierOrders,
                confirmCreateOrderIntoCWIsVisible: false,
                orderToCreateIntoCW: null,
                refreshSupplierOrders: false,
                enterEditShippingFees: state.enterEditShippingFees,
                exitEditShippingFees: state.exitEditShippingFees
            };
        case 'SUPPLIERORDER/ON_RECEIVE_CREATE_INTO_CW':
            return {
                supplierOrders: state.supplierOrders,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                exportedSupplierOrders: state.exportedSupplierOrders,
                confirmCreateOrderIntoCWIsVisible: false,
                orderToCreateIntoCW: null,
                refreshSupplierOrders: true,
                enterEditShippingFees: state.enterEditShippingFees,
                exitEditShippingFees: state.exitEditShippingFees
            };
        case 'SUPPLIERORDER/ON_REQUEST_CREATE_INTO_CW':
            return {
                supplierOrders: state.supplierOrders,
                total: state.total,
                isLoading: true,
                dataState: state.dataState,
                exportedSupplierOrders: state.exportedSupplierOrders,
                confirmCreateOrderIntoCWIsVisible: false,
                orderToCreateIntoCW: state.orderToCreateIntoCW,
                refreshSupplierOrders: false,
                enterEditShippingFees: state.enterEditShippingFees,
                exitEditShippingFees: state.exitEditShippingFees
            };
        case 'RECEIVE_DEALER_MODULE_PARAMS':
            return {
                supplierOrders: state.supplierOrders,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                exportedSupplierOrders: state.exportedSupplierOrders,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                orderToCreateIntoCW: state.orderToCreateIntoCW,
                refreshSupplierOrders: false,
                enterEditShippingFees: state.enterEditShippingFees,
                exitEditShippingFees: state.exitEditShippingFees
            };
        case 'REQUEST_DEALER_MODULE_PARAMS':
            return {
                supplierOrders: state.supplierOrders,
                total: state.total,
                isLoading: true,
                dataState: state.dataState,
                exportedSupplierOrders: state.exportedSupplierOrders,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                orderToCreateIntoCW: state.orderToCreateIntoCW,
                refreshSupplierOrders: false,
                enterEditShippingFees: state.enterEditShippingFees,
                exitEditShippingFees: state.exitEditShippingFees
            };
            
        case 'SUPPLIERORDER/ON_ENTER_EDIT_SHIPPING_FEES':
            return {
                supplierOrders: state.supplierOrders,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                exportedSupplierOrders: state.exportedSupplierOrders,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                orderToCreateIntoCW: state.orderToCreateIntoCW,
                refreshSupplierOrders: false,
                enterEditShippingFees: action.enterEditShippingFees,
                exitEditShippingFees: state.exitEditShippingFees
            };
        default:
            return state;
    }
};
