import { getter, State, toDataSourceRequestString } from "@progress/kendo-data-query";
import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import { Grid, GridCellProps, GridDetailRowProps, GridColumn as Column, GridDataStateChangeEvent, GridToolbar, GridRowProps, GridItemChangeEvent, getSelectedState, GridSelectionChangeEvent, setSelectedState, GridHeaderSelectionChangeEvent } from "@progress/kendo-react-grid";

import { GridLayout, Menu, MenuSelectEvent, Splitter, SplitterOnChangeEvent } from "@progress/kendo-react-layout";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmDialog } from "../../Module/Dialog/ConfirmDialog";
import { fetchUrl, handleLogoutOnSessionExpire } from "../../Module/Fetch";
import { CellRender, DateCell, DateTimeCell, HtmlCell, MoneyCell, RowRender } from "../../Module/renderers";
import { CarrierShippingRate, CustomerOrder, CustomerOrderHeader, CustomerOrderItem, GetStockAvailabilty, OrderAdress, OrderTracking, SelectionState } from "../../store/interfaces";
import { CustomerOrderItemCommandCell } from "../CommandCell/CustomerOrderItemCommandCell";
import { ORDER_STATUS_ID_COMPLETED, ORDER_STATUS_ID_PROCESSING, SUPPLIER_ORDER_DETAILS_TAB_ID_PREFIX } from "../../store/constants";
import { Checkbox, CheckboxChangeEvent, Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { Form, Spinner } from "reactstrap";
import { DialogActionsBar, Dialog, Window } from "@progress/kendo-react-dialogs";
import SearchProduct from "../Product/SearchProduct";
import { Fade } from "@progress/kendo-react-animation";
import {
  Notification,
} from "@progress/kendo-react-notification";

import * as CustomerOrderItemsStore from '../../store/Orders/CustomerOrderItems';

import SearchCustomerOrders from "./SearchCustomerOrders";
import { DateTimeUtil } from "../../Module/DateTimeUtil";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { Loader } from "@progress/kendo-react-indicators";
import { getSelectedIds, orderIsEditable, updateGridFooterPager } from "../../Module/Utils";
import { MoneyFormat } from "../../Module/CurrencyUtil";
import { Tooltip } from "@progress/kendo-react-tooltip/dist/npm/tooltip/Tooltip";
import { CookiesUtil } from "../../Module/CookiesUtil";
import CustomerOrdersGrid from "./CustomerOrdersGrid";
import InvoiceUpload from "../Invoice/InvoiceUpload";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { CustomerOrderDetailProps } from "./CustomerOrderDetail";
import { DateInput, DatePicker } from "@progress/kendo-react-dateinputs";
import { SvgIcon } from "@progress/kendo-react-common";
import * as TabsStore from "../../store/Tabs/Tabs";
import * as CustomerOrderStore from "../../store/Orders/CustomerOrders";

import {
  saveIcon,
  editToolsIcon,
  cancelIcon,
} from "@progress/kendo-svg-icons";
import SupplierOrderDetail from "./SupplierOrderDetail";

interface CustomerOrderItemsCustomProps {
  orderHeaderId: number
}

type CustomerOrdersItemProps =
  CustomerOrderItemsStore.CustomerOrderItemsState // ... state we've requested from the Redux store
  & typeof CustomerOrderItemsStore.actionCreators // ... plus action creators we've requested
  & CustomerOrderStore.CustomerOrdersState // ... state we've requested from the Redux store
  & typeof CustomerOrderStore.actionCreators // ... plus action creators we've requested
  & TabsStore.TabsState // ... state we've requested from the Redux store
  & typeof TabsStore.actionCreators // ... plus action creators we've requested
  & RouteComponentProps
  & CustomerOrderDetailProps

const CustomerOrderItems = (props: CustomerOrdersItemProps) => {
  const { t, i18n } = useTranslation();
  const [dataState, setDataState] = React.useState<State>({ take: 10, skip: 0 });
  const [selectedCustomerOrderItem, setSelectedCustomerOrderItem] = React.useState<CustomerOrderItem[]>();
  const [zip, setZip] = React.useState<string>();
  const [message, setMessage] = React.useState<string>("");
  const [transferToSubOrderQuantity, setTransferToSubOrderQuantity] = React.useState<number>(1);

  const onAddItems = () => {
    CookiesUtil.setNotificationMessages({
      subject: "",
      message: i18n.t("itemAddedSuccess"),
      type: "success",
      created: new Date(),
      expire: 13000,
      read: false
    });
    refresh();
    props.closeAddItemWindow();
    props.openConfirmShippingEstimateWindow();
  }

  const onChange = (event: SplitterOnChangeEvent) => {
    setPanes(event.newState);
  };

  const [selectedState, setSelectedState] = React.useState<SelectionState>({});

  const DATA_ITEM_KEY: string = "order_item_id";
  const SELECTED_FIELD: string = "selected";
  const idGetter = getter(DATA_ITEM_KEY);

  const [panes, setPanes] = React.useState<Array<any>>([
    { size: "309px", resizable: false },
    { size: "428px", resizable: false },
    { resizable: false },
    { resizable: false },
    { size: "666px", resizable: false }
  ]);

  const CommandCell = (gprops: GridCellProps) => (
    <CustomerOrderItemCommandCell
      {...gprops}
      remove={promptRemoveItem}
    />
  );

  const refresh = () => {
    props.fetchOrderHeader(props.orderHeaderId);
    props.requestOrderTotals(props.orderHeaderId);
    props.fetchCustomerOrderItems(props.orderHeaderId, dataState, true);
    props.requestCustomerOrdersEditableStates([{estaff_order_number : props.orderHeaderId} as CustomerOrder]);
    props.fetchOrderShippingAddresses(props.orderHeaderId);
    props.fetchShippingMethods();
  }

  const promptRemoveItem = (dataItem: CustomerOrderItem) => {
    props.openRemoveItemsConfirmWindow();
    setSelectedCustomerOrderItem([dataItem]);
  };

  const setSelectedItems = () => {
    var selectedItems: CustomerOrderItem[] = []
    for (const [key, value] of Object.entries(selectedState)) {
      if (value) {
        var oi = props.orderItems.find((e: any) => e.order_item_id == key as unknown);
        if (oi != undefined) {
          selectedItems.push(oi);
        }
      }
    }
    setSelectedCustomerOrderItem(selectedItems);
  }

  const promptRemoveItems = () => {
    if (selectedCustomerOrderItem != undefined && selectedCustomerOrderItem?.length > 0) {
      props.openRemoveItemsConfirmWindow();
    }
  };

  const remove = (estimateShipping: boolean = true) => {
    props.remove(selectedCustomerOrderItem, props.orderHeaderId);
  };

  const update = () => {
    props.update(props.orderHeaderId);
  };

  const discard = () => {
    refresh();
  };

  const cancel = () => {
    refresh();
  };

  const enterEdit = (dataItem: CustomerOrderItem, cellField: string | undefined) => {
    props.enterEdit(dataItem, cellField);
  };

  const [editField, setEditField] = React.useState<string | undefined>(
    "inEdit"
  );

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  }

  useEffect(() => {
    refresh();
  }, [props.orderHeaderId]);

  useEffect(() => {
    if (props.refreshCutomerOrderItmeTab) {
      refresh();
    }
  }, [props.refreshCutomerOrderItmeTab]);

  useEffect(() => {
    setSelectedItems();
  }, [selectedState]);

  useEffect(() => {
    updateGridFooterPager(props.total, i18n.t("products"), "customer-order-items-" + props.orderHeaderId)
  });

  const onShippingEstimate = () => {
    props.onShippingEstimate(props.orderHeaderId);
  }

  const onSelectionChange = React.useCallback(
    (event: GridSelectionChangeEvent) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
    },
    []
  );

  const onHeaderSelectionChange = React.useCallback(
    (event: GridHeaderSelectionChangeEvent) => {
      const checkboxElement: any = event.syntheticEvent.target;
      const checked = checkboxElement.checked;
      const newSelectedState: any = {};

      event.dataItems.forEach((item) => {
        newSelectedState[idGetter(item)] = checked;
      });
      setSelectedState(newSelectedState);
    },
    []
  );

  const promptCopyItems = (displayOrders: boolean, step: number, isTransfert: boolean) => {
    if (selectedCustomerOrderItem != undefined && selectedCustomerOrderItem?.length > 0) {
      props.openSearchOrdersDialog(displayOrders, step, selectedCustomerOrderItem, isTransfert);
    }
  }

  const handleActions = (e: MenuSelectEvent) => {
    switch (e.item.text) {
      case i18n.t("add"):
        props.openAddItemWindow();
        break;
      case i18n.t("delete"):
        promptRemoveItems();
        break;
      case i18n.t("copyToExistingOrder"):
        promptCopyItems(true, 0, false);
        break;
      case i18n.t("copyToNewOrder"):
        promptCopyItems(false, 1, false);
        break;
      case i18n.t("transferToExistingOrder"):
        promptCopyItems(true, 0, true);
        break;
      case i18n.t("transferToNewOrder"):
        promptCopyItems(false, 1, true);
        break;
      case i18n.t("split"):
        promptSplitItems();
        break;
      default:
        return;
    }
  }

  const promptSplitItems = () => {
    if (selectedCustomerOrderItem != undefined && selectedCustomerOrderItem?.length > 0) {
      props.displaySplitOrderItemsDialog(selectedCustomerOrderItem);
    }
  }

  const onTransferItemsToNewOrder = () => {
    //props.onCopyItemsToNewOrder(props.orderHeaderId, true, false, selectedCustomerOrderItem);
  }

  const onToggleSelectAllRows = (e: CheckboxChangeEvent) => {
    let selectedState: any = {}
    if (!e.target.value) {
      setSelectedState({});
    } else {
      props.orderItems.map((e : any) => {
        if (e.order_item_id != null) {
          selectedState[e.order_item_id] = true
        }
      })
    }

    setSelectedState(selectedState);
  }

  const TrackingCell = (gprops: GridCellProps) => {
    const field = gprops.field || "";
    const s = gprops.dataItem[field];
    return (
      <td colSpan={gprops.colSpan} style={gprops.style}>
        {gprops.dataItem[field] &&
          <Button
            onClick={
              (e) => {
                props.setTrackingData({
                  carrierName: gprops.dataItem['carrier_name'],
                  id: 0,
                  trackingNumber: gprops.dataItem['tracking_number'],
                  eta: gprops.dataItem['tracking_eta'],
                  trackingUrl: gprops.dataItem['tracking_url_template']
                });
                props.openTrackingWindow();
              }
            } >
            {gprops.dataItem[field]}
          </Button>}
      </td>
    );
  };

  const productDescriptionCell = (gprops: GridCellProps) => {
    const field = gprops.field || "";
    const s = gprops.dataItem[field];
    return (
      <td colSpan={gprops.colSpan} style={gprops.style}>
        <a className="product-descripton" href={"https://charlie.estaffsoftware.com/products/details/" + gprops.dataItem['product_id'] + ""} target="_blank"><div dangerouslySetInnerHTML={{ __html: s }}></div></a>
      </td>
    );
  };

  const ViewProfitCell = (props: GridCellProps) => {
    const field = props.field || "";
    return (
      <td colSpan={props.colSpan} style={props.style} className={props.className}>
        <div key={props.dataItem["estaff_order_number"]} style={{ textAlign: "right" }}>
          {props.dataItem[field] != null &&
            <Tooltip anchorElement="target" position="left">

              {(props.dataItem["evaluated_profit"] === true) &&
                <a href="#" title={i18n.t("estimate")} id="canton" className="fas fa-exclamation-circle orange-color" >&nbsp;</a>
              }
              <span style={{ opacity: props.dataItem["evaluated_profit"] === true ? 0.5 : 1 }}>
                {(props.dataItem[field] != null) ? MoneyFormat(props.dataItem[field], props.dataItem["currency_code"]) : ""}
              </span>
            </Tooltip>
          }

        </div>
      </td>
    );
  };

  const customCellRender = (td: any, prop: GridCellProps) => {
    return (<CellRender
      originalProps={prop}
      td={td}
      enterEdit={enterEdit}
      editField={editField}
      save={update}
      exitEdit={discard}
    />)
  };

  const customRowRender = (tr: any, props: any) => (
    <RowRender
      originalProps={props}
      tr={tr}
      exitEdit={discard}
      editField={editField}
    />
  );

  const SupplierOrderNumCell = (owprops: GridCellProps) => {
    const field = owprops.field || "";
    return (
      <td colSpan={owprops.colSpan} style={owprops.style} className={owprops.className}>
        <span style={{ maxHeight: 23, overflow: "hidden" }}>
          <Button
            className='supplier-button'
            onClick={(el) => { 
              props.onAddNewTabs(
                [
                    {
                        title: i18n.t('supplierOrderDetail'),
                        id: SUPPLIER_ORDER_DETAILS_TAB_ID_PREFIX + owprops.dataItem[field],
                        content: {
                            name: SupplierOrderDetail,
                            props: {orderId: owprops.dataItem[field]}
                        },
                        visible: true,
                        closable: true
                    }
                ]
            ) 
              
            }}
            value={owprops.dataItem[field]} > {owprops.dataItem[field]}
          </Button>&nbsp;
        </span>
      </td>
    );
};

  return (
    <>
      <section>

        {props.isLoading &&
          <Dialog>
            <Loader size="large" />
          </Dialog>
        }

        {props.confirmRestimateShipping &&
          <Dialog title={i18n.t("confim")} onClose={props.onCloseConfirmEstimateShipping} >
            <p style={{ margin: "25px", textAlign: "center" }}>
              {i18n.t("promptShippingEstimate")}
            </p>
            <DialogActionsBar>
              <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={props.onCloseConfirmEstimateShipping}>{i18n.t("no")}</button>
              <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={onShippingEstimate}>{i18n.t("yes")}</button>
            </DialogActionsBar>
          </Dialog>
        }

        {props.orderHeader && <table className="cutomer-order-summary">
          <tr>
            <td className="cutomer-order-header-td">          
              <div className="order-detail-header">
              <h3>{i18n.t('orderDetails')}</h3>

              <div className="order-detail-header-body">
                <div id={"test" + props.orderHeaderId}><strong>{i18n.t("date")}</strong>: {DateTimeUtil.toUserLocalDateTIme(props.orderHeader?.order_date)} </div>
                <div><strong>{i18n.t("paymentMethod")}</strong>: {props.orderHeader?.payment_method_name}</div>

                {!props.enterEditOrderStatus && <>
                  <strong>{i18n.t("status")}</strong>: {(props.orderHeader?.order_status_name != null) ? props.orderHeader?.order_status_name : ""} 
                  {props.orderHeader.order_status_url_svg != null && <img src={props.orderHeader.order_status_url_svg} width={"32px"} height={"32px"} />}
                  </>
                }
                <br />
                <br />


                <h3>{i18n.t('vendorName')}:</h3>
                <div className="order-detail-header-body">
                  {props.orderHeader?.sales_person_name}
                </div>
              </div>
            </div>


            </td>

            <td className="cutomer-order-header-td">
              <div className="order-detail-header">
                <h3>{i18n.t('referencesOrderNumbers')}</h3>
                <div className="order-detail-header-body">
                  <div><strong>{i18n.t("#estaff")}</strong>: {props.orderHeader?.estaff_order_number}</div>
                  {props.orderHeader?.order_number_save && <div><strong>{i18n.t("transaction#")}</strong>: {props.orderHeader?.order_number_save}</div>}
                  {props.orderHeader?.order_source_name && <div><strong>{props.orderHeader?.order_source_name}</strong>: {props.orderHeader?.order_number_source}</div>}
                  {props.orderHeader?.customer_po_number && <div><strong>{i18n.t('salesOrder#')}</strong>: {props.orderHeader?.customer_po_number}</div>}
                </div>


              </div>
            </td>

  
            <td className="cutomer-order-header-td">
            <div className="order-detail-header">
              <h3>{i18n.t('shippingInformation')}</h3>
              <div className="order-detail-header-body">

                <>                    
                
                {!props.enterEditShipping && 
                <table>
                      <tr><td className="shipping-information-section" >
                            <div className="shipping-information-headers">{i18n.t("shippingMethod")}</div>

                            {props.orderHeader?.shipping_method_name}
                          </td></tr>

                          <tr>
                            <td className="shipping-information-section" >
                            <div className="shipping-information-headers">{i18n.t("shippingCost")}</div>

                            {MoneyFormat(props.orderHeader?.shipping_fee?.toString(), props.orderHeader?.currency_code)}
                          </td>

                          </tr>

                          <tr><td className="shipping-information-section" >

                          </td>
                            <td>
                              {!props.enterEditShipping && orderIsEditable(props.orderHeaderId, props.orderEditableStates) && <div className="edit-shipping-button"> <Button themeColor={"primary"} onClick={(e) => { props.onEnterEditShipping() }}> {i18n.t("editShipping")}</Button></div>}
                            </td>
                          </tr>

                          <tr >
                            <td align="center" colSpan={3}>
                              {orderIsEditable(props.orderHeaderId, props.orderEditableStates) && <>
                                {props.enterEditShipping && <>
                                  <div style={{ marginBottom: "10px" }}>
                                    <Button className="buttons-container-button" onClick={(e) => { props.onShippingAddressSave() }} > {i18n.t('save')} </Button>
                                    <Button className="buttons-container-button" onClick={(e) => { props.onExitEditShipping() }} > {i18n.t('cancel')} </Button>
                                  </div>
                                </>
                                }
                              </>
                              }
                            </td>

                          </tr>
                    </table>
                    }
                    {props.enterEditShipping &&
                    <>
                      <table>
                        <tr>
                          <td>
                            <table>
                              <tr className="shipping-fields-rows">
                                <td className="shipping-information-headers">
                                  {i18n.t("name")}
                                </td>

                                <td>
                                  <Input
                                    className="shipping-fields"
                                    type={"text"}
                                    value={props.newShippingAddress?.company_name as string}
                                    onChange={(e) => { props.onEditShipping(e, props.newShippingAddress) }}
                                    name="companyName"

                                  />
                                </td>
                                <td>
                                </td>
                              </tr>

                              <tr className="shipping-fields-rows">
                                <td className="shipping-information-headers">
                                  {i18n.t("address1")}
                                </td>

                                <td>
                                  <Input
                                    className="shipping-fields"
                                    type={"text"}
                                    value={props.newShippingAddress?.street1 as string}
                                    onChange={(e) => { props.onEditShipping(e, props.newShippingAddress) }}
                                    name="street1"

                                  />
                                </td>
                                <td>
                                </td>
                              </tr>
                              {<tr className="shipping-fields-rows">
                                <td className="shipping-information-headers">
                                  {i18n.t("address2")}
                                </td>
                                <td>
                                  <Input
                                    className="shipping-fields"
                                    type={"text"}
                                    value={props.newShippingAddress?.street2 as string}
                                    onChange={(e) => { props.onEditShipping(e, props.newShippingAddress) }}
                                    name="street2"

                                  />
                                </td>
                                <td>
                                </td>
                              </tr>}
                              <tr className="shipping-fields-rows">
                                <td className="shipping-information-headers">
                                  {i18n.t("zip")}
                                </td>
                                <td>
                                  <Input
                                    className="shipping-fields"
                                    type={"text"}
                                    value={props.newShippingAddress?.zip as string}
                                    onChange={(e) => { props.onEditShipping(e, props.newShippingAddress) }}
                                    name="zip"
                                  />
                                </td>
                                <td>
                                </td>
                              </tr>

                              <tr className="shipping-fields-rows">
                                <td className="shipping-information-headers">
                                  {i18n.t("city")}
                                </td>
                                <td>
                                  <Input
                                    className="shipping-fields"
                                    type={"text"}
                                    value={props.newShippingAddress?.city as string}
                                    onChange={(e) => { props.onEditShipping(e, props.newShippingAddress) }}
                                    name="city"

                                  />
                                </td>
                                <td>
                                </td>
                              </tr>
                              <tr className="shipping-fields-rows">
                                <td className="shipping-information-headers">
                                  {i18n.t("province")}
                                </td>
                                <td >
                                  <Input
                                    style={{ width: "65px" }}
                                    className="shipping-fields province"
                                    type={"text"}
                                    value={props.newShippingAddress?.province as string}
                                    onChange={(e) => { props.onEditShipping(e, props.newShippingAddress) }}
                                    name="province"
                                  />
                                  <span className="shipping-information-headers country-label">{i18n.t("county")}</span>
                                  <Input
                                    className="shipping-fields country"
                                    type={"text"}
                                    value={props.newShippingAddress?.country as string}
                                    onChange={(e) => { props.onEditShipping(e, props.newShippingAddress) }}
                                    name="country"
                                  />
                                </td>
                                <td>
                                </td>
                              </tr>
                            </table>
                          </td>

                          <td valign="top">
                            <div className="shipping-estimate">
                              <table>
                                <tr>
                                  <td className="shipping-information-headers">
                                    {i18n.t("shippingMethod")}
                                  </td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td >
                                    <DropDownList
                                      className="shipping-method"
                                      dataItemKey="shipping_method_id"
                                      textField="shipping_method_name"
                                      value={props.selectedShippingMethod}
                                      data={props.shippingMethods as any}
                                      onChange={props.onChangeShippingMethod}
                                    />

                                  </td>
                                  <td>
                                  </td>
                                </tr>
                                <tr style={{ height: "22px" }}>
                                  <td>
                                  </td>
                                  <td>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="shipping-information-headers">
                                    {i18n.t("estimateCost")}
                                  </td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td style={{ width: "226px" }}>
                                    <NumericTextBox
                                      className="shipping-fields-2-columns shipping-amount"
                                      value={props.shippingAmount}
                                      onChange={(e) => {
                                        props.onShippingAmountChanges(Number(e.target.value))
                                      }}
                                    />
                                    <Button style={{ height: "24px", marginLeft: "7px", width: "77px;", border: "1px grey solid" }} className="buttons-container-button" onClick={(e) => { props.onShippingEstimate(props.orderHeaderId) }} > {i18n.t('calculate')} </Button>
                                  </td>
                                  <td>
                                  </td>
                                </tr>
                              </table>
                            </div>

                          </td>
                        </tr>
                        <tr style={{ height: "12px" }}>
                          <td >
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td className="save-shipping-buttons-td">
                            <Button className="cancel-button" onClick={(e) => { props.onExitEditShipping() }} > {i18n.t('cancel')} </Button>
                            <Button
                              className="k-button-solid-primary shipping-save-button"
                              onClick={
                                (e) => {
                                  props.onShippingAddressSave();
                                  props.onSaveShippingMethod(props.orderHeaderId, props.selectedShippingMethod, props.shippingAmount, props.onSaveShippingAmount);
                                }
                              } > {i18n.t('save')} </Button>
                          </td>
                          <td></td>
                        </tr>
                      </table>


                    </>
                  }
                </>

              </div>
            </div>


            </td>
          </tr>

          <tr>
            <td className="cutomer-order-header-td">

              <div className="order-detail-header">
                <h3>{i18n.t('costSummary')}</h3>
                <div className="order-detail-header-body">

                  {props.orderTotals.map(function (t) {
                    return <div>
                      {t.amount_name == "Total:" && props.orderHeader?.order_profit &&
                        <div>
                          <span ><strong>{i18n.t('profit')}</strong></span>
                          <span className="cost-summar-amount">{MoneyFormat(props.orderHeader.order_profit?.toString(), props.orderHeader.currency_code)}</span>
                        </div>
                      }
                      <span ><strong>{t.amount_name}</strong></span>
                      <span className="cost-summar-amount">{t.amount != null ? MoneyFormat(t.amount?.toString(), props.orderHeader?.currency_code) : ""} </span></div>
                  })}

                </div>
              </div>

            </td>
            <td className="cutomer-order-header-td">
              <div className="order-detail-header">
                <h3 >{props.enterEditShipping ? i18n.t('editShippingInformation') : i18n.t('shipTo')}
                </h3>
                <div className="order-detail-header-body">

                    <div >
                      <table className="shipping-information">
                        <tbody>
                          <tr>
                            <td className="shipping-information-section" rowSpan={3}>
                              <div><b>{props.shippingAddresses?.company_name}</b></div>
                              <div>{props.shippingAddresses?.street1} {props.shippingAddresses?.street2 ? "," : ""} {props.shippingAddresses?.street2}</div>
                              <div>{props.shippingAddresses?.city} {props.shippingAddresses?.province} {props.shippingAddresses?.zip} {props.shippingAddresses?.country}</div>
                              <br/>
                              <div><b>{i18n.t("Contact")}</b>: {props.shippingAddresses?.first_name}  {props.shippingAddresses?.last_name}</div>
                              <div><b>{i18n.t("phone")}</b>:  {props.shippingAddresses?.phone}</div>
                              <div><b>{i18n.t("email")}</b>:  {props.shippingAddresses?.email}</div>
                            </td>
                          </tr>



                        </tbody>

                      </table>
                    </div>
                

                </div>
              </div>
            </td>
            <td className="cutomer-order-header-td">
              <div className="order-detail-header">
                <h3>{i18n.t('invoiceTo')}</h3> 
                <div className="order-detail-header-body">
                 {
                    props.orderHeader?.billing_address && 
                    <>
                      <div>
                       <b>{props.orderHeader?.billing_address[0]?.company_name}</b>
                      </div> 
                      <div>
                        {props.orderHeader?.billing_address[0]?.street1} {props.orderHeader?.billing_address[0]?.street2 ? "," : ""} {props.orderHeader?.billing_address[0]?.street2}
                      </div>
                      <div>
                        { props.orderHeader?.billing_address[0]?.city} { props.orderHeader?.billing_address[0]?.province} {props.orderHeader?.billing_address[0]?.zip} {props.orderHeader?.billing_address[0]?.country}
                      </div>

                      <br/>
                      <div>
                        <strong>{i18n.t("contact")}</strong>: {props.orderHeader?.billing_address[0]?.first_name } {props.orderHeader?.billing_address[0]?.last_name}
                      </div>
                      <div>
                        <strong>{i18n.t("phone")}</strong>: {props.orderHeader?.billing_address[0]?.phone}
                      </div>
                      <div>
                        <strong>{i18n.t("email")}</strong>: {props.orderHeader?.billing_address[0]?.email}
                      </div>  
                    </>
                  }
                  </div>
              </div>
            </td>
          </tr>
        </table>}

      </section>

      <>
        {props.trackingWindowIsVisible && (
          <Dialog title={i18n.t("tracking information")} onClose={props.closeTrackingWindow} >
            <div style={{ margin: "25px", textAlign: "left" }}>
              <ul>
                <li>
                  <b>{i18n.t("carrier")} </b>: {props.trackingData?.carrierName}
                </li>
                <li>
                  <b>{i18n.t("trackingNumber")}</b>: {props.trackingData?.trackingNumber}
                </li>
                <li>
                  <b>{i18n.t("eta")}</b>: {props.trackingData?.eta && DateTimeUtil.toUserLocalDateTIme(props.trackingData?.eta?.toString())}
                </li>
                <li>
                  {props.trackingData?.trackingUrl && <a href={props.trackingData?.trackingUrl.replace("{trackingNumber}", props.trackingData?.trackingNumber)} target="_blank">{i18n.t("trackOrderLinkLabel")}</a>}
                </li>
              </ul>
            </div>
            <DialogActionsBar>
              <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={props.closeTrackingWindow}>{i18n.t("close")}</button>
            </DialogActionsBar>
          </Dialog>
        )}

        {props.displaySearchOrderDialog && (
          <Window
            title={props.isTransfer ? i18n.t("transfer") : i18n.t("copy") + " " + i18n.t("items")}
            onClose={props.closeSearchOrdersDialog}
            initialHeight={550}
            initialWidth={1250}
            modal={true}
            maximizeButton={() => null}
            restoreButton={() => null}
            minimizeButton={() => null}
          >
            {props.copyItemsformStepNumber == 1 && props.displayOrdersGrid && <Button onClick={(e) => { props.onCopyItemsSetStep(0) }}> {i18n.t("back")}</Button>}
            <Button
              disabled={(props.selectedOrderId == null && props.displayOrdersGrid)}
              onClick={(e) => { props.onCopyItems(props.copyItemsformStepNumber, selectedCustomerOrderItem, props.isTransfer) }}
            >{props.copyItemsformStepNumber == 1 ? (props.isTransfer ? i18n.t("transfer") : i18n.t("copy")) : i18n.t("next")}</Button>

            {props.copyItemsformStepNumber == 0 &&
              <>
                <h1>{i18n.t("step")} 1: {i18n.t("chooseTargetOrder")}</h1>
                <CustomerOrdersGrid
                  orderHeaderId={0}
                  onSelectOrdersCallback={props.setSelectedOrder}
                  selectionMode="single"
                  displayAction={false}
                  fetchEditableOrdersOnly={true}
                />
              </>
            }
            {props.copyItemsformStepNumber == 1 &&
              <><h1>
                {props.displayOrdersGrid && <> {i18n.t("step")} 2: </>}
                {props.isTransfer ? i18n.t("chooseQtyToTransfer") : i18n.t("chooseQtyToCopy")}</h1>
                {selectedCustomerOrderItem?.map(oi => {
                  return <><span>{oi.mfr_part_number}</span><span><NumericTextBox
                    min={1}
                    max={oi.qty}
                    key={oi.order_item_id as number}
                    value={props.itemsToCopy.find(i => i.itemId == oi.order_item_id)?.qty as number}
                    onChange={e => props.onChangeCopyItemsQty(Number(e.target.value), oi.order_item_id)}
                  /></span></>
                })}</>
            }

          </Window>
        )}
      </>
      <>
        {props.splitOrderItemsDialogIsVisible && (
          <Window
            title={i18n.t("splitItems")}
            onClose={props.hideSplitOrderItemsDialog}
            modal={true}
            resizable={false}
            width={500}
            height={200}
            maximizeButton={() => null}
            restoreButton={() => null}
            minimizeButton={() => null}
          >
            {
              selectedCustomerOrderItem?.map(item =>
                <table style={{ width: "100%" }}><tr>
                  <td style={{ width: "50%" }}>{item.mfr_part_number}</td>
                  <td>
                    <NumericTextBox
                      min={1}
                      key={item.order_item_id as number}
                      value={props.splitOrderItems?.find(e => e.orderItemId == item.order_item_id)?.qty as number}
                      onChange={e => props.setSplitOrderItems(Number(e.target.value), item.order_item_id)}
                    />
                  </td>
                </tr>
                </table>
              )
            }

            <div className="k-form-buttons">
              <button
                type={"submit"}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={props.onSplitOrderItems}
              >
                {i18n.t('submit')}
              </button>
            </div>

          </Window>
        )}
      </>
      <section>

        {props.displayAddItemWindow && (
          <Window
            title={i18n.t("addProduct")}
            onClose={props.closeAddItemWindow}
            initialHeight={550}
            initialWidth={1250}
            modal={true}
            maximizeButton={() => null}
            restoreButton={() => null}
            minimizeButton={() => null}
          >
            <SearchProduct orderHeaderId={props.orderHeaderId} onAddItem={onAddItems} onClose={props.closeAddItemWindow} init={true} />
          </Window>
        )}

        {props.displayConfirmRemoveItemsWindow &&
          <ConfirmDialog
            message={i18n.t("deleteComfirmMessage")}
            onPrompt={props.closeRemoveItemsConfirmWindow}
            onConfirm={remove} />
        }

        {props.confirmShippingRateDialogIsVisible &&
          <Dialog title={i18n.t("confim")} onClose={props.onCloseConfirmShippingRateDialog} >
            <p style={{ margin: "25px", textAlign: "center" }}>{i18n.t("updateShippingRateConfirmMessage")}
              {props.suggestedShippingAmount} CAD
            </p>
            <DialogActionsBar>
              <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={props.onCloseConfirmShippingRateDialog}>{i18n.t("no")}</button>
              <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={(event: any) => {
                  props.onSaveShippingAmount(props.orderHeaderId, props.suggestedShippingAmount);
                }}
              >{i18n.t("yes")}</button>
            </DialogActionsBar>
          </Dialog>
        }
        <LocalizationProvider language={i18n.language}>
          <IntlProvider locale={i18n.language}>
            <div className={"customer-order-items-" + props.orderHeaderId + ""}>
              <Grid
                onItemChange={props.itemChange}
                editField={editField}
                resizable={true}
                style={{ height: "400px" }}
                data={props.orderItems?.map(item => ({ ...item, [SELECTED_FIELD]: selectedState[idGetter(item)] }))}
                {...props.dataState}
                total={props.total}
                sortable={true}
                onDataStateChange={dataStateChange}
                pageable={{
                  pageSizes: true
                }}
                pageSize={10}
                onHeaderSelectionChange={onHeaderSelectionChange}
                dataItemKey={DATA_ITEM_KEY}
                onSelectionChange={onSelectionChange}
                selectedField={SELECTED_FIELD}
                selectable={{
                  enabled: true,
                  drag: false,
                  cell: false,
                  mode: "multiple",
                }}
                cellRender={customCellRender}
                rowRender={customRowRender}
              >
                <GridToolbar>
                  <div className='action-menu'>
                    <div className='check-all-rows'> <Checkbox onChange={onToggleSelectAllRows}></Checkbox></div>
                    <div className='action-menu-dropdown'>
                      <Menu
                        className="drop-down-menu"
                        onSelect={handleActions}
                        items={[{
                          "text": i18n.t("action") + " (" + getSelectedIds(selectedState).length + ")",
                          "items": [
                            { "text": i18n.t("add"), disabled: !orderIsEditable(props.orderHeaderId, props.orderEditableStates) },
                            //{ "text": i18n.t("split") },
                            {
                              "text": i18n.t("copy"), "items": [{ "text": i18n.t("copyToExistingOrder") }, { "text": i18n.t("copyToNewOrder") }]
                            },
                            {
                              "text": i18n.t("transfer"),
                              disabled: !orderIsEditable(props.orderHeaderId, props.orderEditableStates),
                              "items": [
                                { "text": i18n.t("transferToNewOrder") },
                                { "text": i18n.t("transferToExistingOrder") },
                              ]
                            },
                            { "text": i18n.t("delete"), disabled: !orderIsEditable(props.orderHeaderId, props.orderEditableStates) },
                          ],
                        }]} />
                    </div>
                  </div>
                  <div className='grid-titles'>{i18n.t("customerOrderDetail")}</div>
                </GridToolbar>
                <Column
                  headerCell={() => <td></td>}
                  field={SELECTED_FIELD}
                  width="50px"
                  headerSelectionValue={
                    props.orderItems.findIndex((item: any) => !selectedState[idGetter(item)]) === -1
                  }
                />
                <Column field="line_id" title={"# " + i18n.t("lineId")} width={100} editable={false} />
                <Column field="mfr_part_number" title={i18n.t("mfrPartNumber")} width={161} editable={false} />
                <Column field="manufacturer_name" title={i18n.t("manufacturerName")} width={161} editable={false} />
                <Column field="product_description" title={i18n.t("Description")} editable={false} width={562} cell={productDescriptionCell} />
                <Column field="cost" title={i18n.t("cost")} cell={MoneyCell} width={150} />
                <Column field="unit_sell_price" title={i18n.t("sellPrice")} editor="numeric" width={150} cell={MoneyCell} />
                <Column field="qty" title={i18n.t("quantity")} width={100} editor="numeric" editable={orderIsEditable(props.orderHeaderId, props.orderEditableStates)} />
                <Column field="inventory" title={i18n.t("inventory")} width={100} editable={false} />
                <Column field="sub_total" title={i18n.t("subtotal")} width={150} editable={false} cell={MoneyCell} />
                <Column field="item_profit" title={i18n.t("profit")} cell={ViewProfitCell} width={130} editable={false} />
                <Column field="item_status_name" title={i18n.t("status")} width={100} editable={false} />
                <Column field="unit_weight" title={i18n.t("weight")} width={100} editable={false} />
                <Column field="group_name" title={"# " + i18n.t("shipment")} width={100} editable={false} />
                <Column field="eta" title={i18n.t("eta")} width={130} cell={DateCell} editable={false} />
                <Column field="tracking_number" title={i18n.t("trackingNumber")} width={120} editable={false} />
                <Column field="supplier_order_id" title={i18n.t("purchaseOrder#")} width={140} cell={SupplierOrderNumCell} editable={false} />
                <Column field="invoice_number" title={i18n.t("#invoice")} width={100} editable={false} />
                {orderIsEditable(props.orderHeaderId, props.orderEditableStates) && <Column cell={CommandCell} title={i18n.t('action')} width={100} locked={true} />}
              </Grid></div>
          </IntlProvider>
        </LocalizationProvider>
        <div className="order-comment"><span className="order-comment-notes-labels">{i18n.t('comment')}:</span> {props.orderHeader?.order_comment}</div>
        <div className="order-note"><span className="order-comment-notes-labels">{i18n.t('internalNote')}:</span> {props.orderHeader?.internal_note}</div>
        {<div className="cost-summary">
          <h3>{i18n.t("costSummary")}</h3>
          {props.orderTotals.map(function (t) {
            return <div>
              {t.amount_name == "Total:" && props.orderHeader?.order_profit &&
                <div>
                  <span className="cost-summar-labels">{i18n.t('profit')}</span>
                  <span className="cost-summar-amount">{MoneyFormat(props.orderHeader.order_profit?.toString(), props.orderHeader.currency_code)}</span>
                </div>
              }
              <span className="cost-summar-labels">{t.amount_name}</span>
              <span className="cost-summar-amount">{t.amount != null ? MoneyFormat(t.amount?.toString(), props.orderHeader?.currency_code) : ""} </span></div>
          })}

        </div>}

      </section>
    </>
  );
};

export default connect(
  (state: ApplicationState, ownProps: CustomerOrderItemsCustomProps) => (
    {
      ...state.customerOrderItems,
      ...state.customerOrders,
      ...state.Tabs,
      orderHeaderId: ownProps.orderHeaderId,
    }),
  { ...CustomerOrderItemsStore.actionCreators, ...CustomerOrderStore.actionCreators, ...TabsStore.actionCreators }
)(CustomerOrderItems as any);