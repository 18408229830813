import { State, toDataSourceRequestString } from '@progress/kendo-data-query';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { CustomerOrder, CustomerOrderStatusUpdateResult, CwOrderResult, DealerModuleParams, OrderHeader, OrderStatus } from '../interfaces';
import { GridCellProps, GridExpandChangeEvent } from '@progress/kendo-react-grid';
import { fetchUrl, handleLogoutOnSessionExpire } from '../../Module/Fetch';
import i18n from '../../i18n';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { CookiesUtil } from '../../Module/CookiesUtil';
import { Offset } from '@progress/kendo-react-popup';
import { MenuSelectEvent } from '@progress/kendo-react-layout';


// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface CustomerOrdersState {
    init: boolean,
    isLoading: boolean;
    total: number;
    customerOrders: CustomerOrder[];
    dataState: State,
    exportedCustomerOrders: CustomerOrder[],
    orderHeaders: OrderHeader[],
    confirmDialogIsVisible: boolean,
    errorMessage: string | null,
    reloadCustomerOrders: boolean,
    updateOrderStatus: boolean,
    orderStatusId: number | null,
    orderStatuses: OrderStatus[],
    allOrderStatuses: OrderStatus[],
    orderEditableStates: [],
    orderStatus: OrderStatus | null,
    updateOrderStatusWindowIsVisible: boolean,
    selectedCustomerOrders: CustomerOrder[] | undefined
    selectedCustomerOrderIds: number[],
    contextualMenuIsOpen: boolean
    contextualMenuOffset: Offset | undefined,
    confirmCancelOrdersWindowIsVisible: boolean
    confirmCreateOrderIntoCWIsVisible: boolean
    orderToCreateIntoCW: CustomerOrder | null
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestCustomerOrdersAction {
    type: 'REQUEST_CUSTOMER_ORDERS';
    dataState: State
}

interface RecieveCustomerOrderHeader {
    type: 'RECEIVE_CUSTOMER_ORDER_HEADER';
    customerOrders: CustomerOrder[]
}

interface EnterEditCustomerOrderAction {
    type: 'ENTER_EDIT_CUSTOMER_ORDERS';
    customerOrders: CustomerOrder[]
}

interface EditCustomerOrderEditAction {
    type: 'EDIT_CUSTOMER_ORDERS';
    customerOrders: CustomerOrder[]
}

interface RequestCustomerOrderHeader {
    type: 'REQUEST_CUSTOMER_ORDER_HEADER';
}

interface RecieveCustomerOrderHeader {
    type: 'RECEIVE_CUSTOMER_ORDER_HEADER';
    customerOrders: CustomerOrder[]
}

interface RequestExportCustomerOrdersAction {
    type: 'REQUEST_EXPORT_CUSTOMER_ORDERS';
}

interface ReceiveExportCustomerOrdersAction {
    type: 'RECEIVE_EXPORT_CUSTOMER_ORDERS';
    exportedCustomerOrders: CustomerOrder[];
}

interface ReceiveCustomerOrdersAction {
    type: 'RECEIVE_CUSTOMER_ORDERS';
    customerOrders: CustomerOrder[];
    total: number,
    dataState: State
}

interface RequestSearchCustomerOrdersAction {
    type: 'REQUEST_SEARCH_CUSTOMER_ORDERS';
}

interface ReceiveSearchCustomerOrdersAction {
    type: 'RECEIVE_SEARCH_CUSTOMER_ORDERS';
    orderHeaders: OrderHeader[];
    total: number,
    dataState: State
}

interface RequestGetCusotmerOrderChildren {
    type: 'REQUEST_GET_CUSTOMER_ORDER_CHILDREN';
}

interface RecieveGetCusotmerOrderChildren {
    type: 'RECEIVE_GET_CUSTOMER_ORDER_CHILDREN';
    children: CustomerOrder[];
    total: number,
    childrenDataState: State
}

export interface DataRecievedResults {
    data: CustomerOrder[],
    total: number,
}

export interface OrderDataRecievedResults {
    data: OrderHeader[],
    total: number
}

interface RequestUpdateOrderStatusAction {
    type: 'REQUEST_UPDATE_ORDER_STATUS';
}

interface ReceiveUpdateOrderStatusAction {
    type: 'RECEIVE_UPDATE_ORDER_STATUS';
    confirmDialogIsVisible: boolean,
    alertDialogIsVisible: boolean
}

interface ReceiveFetchOrderStatusAction {
    type: 'RECEIVE_FETCH_ORDER_STATUS';
    orderStatuses: []
}

interface ReceiveFetchAllOrderStatusAction {
    type: 'RECEIVE_FETCH_ALL_ORDER_STATUS';
    orderStatuses: []
}

interface OnForceRefreshCustomerOrdersAction {
    type: 'ON_FORCE_REFRESH_CUSTOMER_ORDERS'
}

interface RequestCustomerOrdersEditableStatesAction {
    type: 'REQUEST_CUSTOMER_ORDER_EDITABLE_STATES';
}

interface ReceiveCustomerOrdersEditableStatesAction {
    type: 'RECEIVE_CUSTOMER_ORDER_EDITABLE_STATES';
    orderEditableStates: []
}

interface SetOrderStatusAction {
    type: 'SET_ORDER_STATUS';
    orderStatus: OrderStatus
}

interface OpenUpdateOrderStatusWindowAction {
    type: 'OPEN_UPDATE_ORDER_STATUS_WINDOW';
}

interface CloseUpdateOrderStatusWindowAction {
    type: 'CLOSE_UPDATE_ORDER_STATUS_WINDOW';
}

interface OnSelectionChangeAction {
    type: 'ON_SELECTION_CHANGE';
    selectedCustomerOrders: CustomerOrder[] | undefined
    selectedCustomerOrderIds: number[]
}

interface OnOpenContextualMenuAction {
    type: 'ON_OPEN_CONTEXTUAL_MENU';
    offset: Offset,
    selectedCustomerOrderIds: number[]
}

interface OnCloseContextualMenuAction {
    type: 'ON_CLOSE_CONTEXTUAL_MENU';
}

interface OnSelectContextualMenuOrderStatusAction {
    type: 'ON_SELECT_CONTEXTUAL_MENU_ORDER_STATUS';
    orderStatus: OrderStatus
}

interface OpenConfirmUpdateOrderStatusWindowAction {
    type: 'OPEN_CONFIRM_UPDATE_ORDER_STATUS_WINDOW';
}

interface CloseConfirmUpdateOrderStatusWindowAction {
    type: 'CLOSE_CONFIRM_UPDATE_ORDER_STATUS_WINDOW';
}

interface OnReceiveCancelOrdersAction {
    type: 'ON_RECEIVE_CANCEL_ORDERS';
}

interface OnRequestCancelOrdersAction {
    type: 'ON_REQUEST_CANCEL_ORDERS';
}

interface OnCloseConfirmCancelOrdersWindowAction {
    type: 'ON_CLOSE_CONFIRM_CANCEL_ORDERS_WINDOW';
}

interface OnOpenConfirmCancelOrdersWindowAction {
    type: 'ON_OPEN_CONFIRM_CANCEL_ORDERS_WINDOW';
    selectedCustomerOrderIds: number[]
}

interface OnRequestCreateIntoCWAction {
    type: 'CUSTOMERORDER/ON_REQUEST_CREATE_INTO_CW';
}

interface OnReceiveCreateIntoCWAction {
    type: 'CUSTOMERORDER/ON_RECEIVE_CREATE_INTO_CW';
}

interface OnPropmtCreateOrderIntoCWAction {
    type: 'CUSTOMERORDER/ON_PROMPT_CREATE_ORDER_INTO_CW';
    orderToCreateIntoCw: CustomerOrder | null
}

interface OnCloseCreateOrderIntoCWindowAction {
    type: 'ON_CLOSE_CREATE_ORDER_INTO_CW_WINDOW';
}

interface ReceiveDealerModuleParamsAction {
    type: 'RECEIVE_DEALER_MODULE_PARAMS';
    connectwiseParams: any
}

interface RequestDealerModuleParamsAction {
    type: 'REQUEST_DEALER_MODULE_PARAMS';
}

interface loadingAction {
    type: 'LOADING';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RecieveCustomerOrderHeader | RequestCustomerOrderHeader |
    EnterEditCustomerOrderAction | RequestCustomerOrdersAction |
    ReceiveCustomerOrdersAction | RequestExportCustomerOrdersAction |
    ReceiveExportCustomerOrdersAction | EditCustomerOrderEditAction |
    RequestSearchCustomerOrdersAction | ReceiveSearchCustomerOrdersAction |
    RequestGetCusotmerOrderChildren | RecieveGetCusotmerOrderChildren |
    RequestUpdateOrderStatusAction | ReceiveUpdateOrderStatusAction |
    ReceiveFetchOrderStatusAction | OnForceRefreshCustomerOrdersAction |
    ReceiveCustomerOrdersEditableStatesAction | RequestCustomerOrdersEditableStatesAction |
    SetOrderStatusAction | OpenUpdateOrderStatusWindowAction | CloseUpdateOrderStatusWindowAction |
    OnSelectionChangeAction | OnOpenContextualMenuAction | OnCloseContextualMenuAction |
    OnSelectContextualMenuOrderStatusAction | OpenConfirmUpdateOrderStatusWindowAction |
    CloseConfirmUpdateOrderStatusWindowAction | OnReceiveCancelOrdersAction | OnCloseConfirmCancelOrdersWindowAction |
    OnOpenConfirmCancelOrdersWindowAction | OnRequestCancelOrdersAction | ReceiveFetchAllOrderStatusAction |
    OnRequestCreateIntoCWAction | OnReceiveCreateIntoCWAction | OnPropmtCreateOrderIntoCWAction |
    OnCloseCreateOrderIntoCWindowAction | ReceiveDealerModuleParamsAction | RequestDealerModuleParamsAction | loadingAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).


export const actionCreators = {
    fetchModuleParams: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState.customerOrders !== undefined) {
            fetchUrl(`/dealers/GetModulesParams?moduleName=TONY-CONNECTWISE`, "GET")
                .then((response) => {
                    handleLogoutOnSessionExpire(response);
                    return response.json() as Promise<DealerModuleParams>
                })
                .then((params) => {
                    dispatch({ type: 'RECEIVE_DEALER_MODULE_PARAMS', connectwiseParams: params });
                });
            dispatch({ type: 'REQUEST_DEALER_MODULE_PARAMS' });
        }
    },
    requestCustomerOrderHeaders: (event: GridExpandChangeEvent, callbackFn: () => {}): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState.customerOrders !== undefined) {
            const customerOrders = appState.customerOrders.customerOrders;

            event.dataItem.expanded = event.value;
            let estaffOrderNumber = event.dataItem.estaff_order_number;

            fetchUrl(`/orders/GetCustomerOrderHeader?orderId=` + event.dataItem.estaff_order_number, "GET")
                .then((response) => {
                    handleLogoutOnSessionExpire(response);
                    return response.json()
                })
                .then((json) => {
                    let data: any = customerOrders.slice();
                    let index = data.findIndex((d: any) => d.estaff_order_number === estaffOrderNumber);
                    data[index].details = json;
                    data[index].expanded = event.value;
                    data[index].reloadCustomerOrdersCallbackFn = callbackFn;
                    dispatch({ type: 'RECEIVE_CUSTOMER_ORDER_HEADER', customerOrders: data });
                });
            dispatch({ type: 'REQUEST_CUSTOMER_ORDER_HEADER' });
        }
    },
    editCustomerOrder: (field: string, orderId: number, value: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState.customerOrders !== undefined) {
            const customerOrders = appState.customerOrders.customerOrders;
            let newData = customerOrders.map((item: any) => {
                if (item.estaff_order_number === orderId) {
                    item[field] = value;
                }
                return item;
            });

            dispatch({ type: 'EDIT_CUSTOMER_ORDERS', customerOrders: newData });

        }
    },
    enterEditCustomerOrder: (dataItem: CustomerOrder, field: string | undefined): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState.customerOrders !== undefined) {
            const customerOrders = appState.customerOrders.customerOrders;
            const newData = customerOrders.map((item: CustomerOrder) => ({
                ...item,
                inEdit: item.estaff_order_number === dataItem.estaff_order_number ? field : undefined,
            }));
            dispatch({ type: 'ENTER_EDIT_CUSTOMER_ORDERS', customerOrders: newData });

        }
    },
    requestCustomerOrders: (dataState: State, forceReload: boolean, fetchEditableOrdersOnly: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let req = toDataSourceRequestString(dataState);
        if (appState.customerOrders !== undefined) {
            const gridState = toDataSourceRequestString(appState.customerOrders.dataState);

            if (forceReload || (appState && appState.customerOrders && req != gridState && appState.customerOrders.isLoading == false)) {
                if (fetchEditableOrdersOnly) {
                    req += "&fetchEditableOnly=true";
                }
                fetchUrl(`/orders/GetCustomerOrders?${req}`, "GET")
                    .then((response) => {
                        handleLogoutOnSessionExpire(response);
                        return response.json() as Promise<DataRecievedResults>
                    })
                    .then(data => {
                        dispatch({ type: 'RECEIVE_CUSTOMER_ORDERS', customerOrders: data.data, total: data.total, dataState: dataState });
                    });

                dispatch({ type: 'REQUEST_CUSTOMER_ORDERS', dataState: dataState });
            }
        }
    },
    requestCustomerOrdersEditableStates: (orders: CustomerOrder[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        var qs: any = [];
        orders.map(o => { qs.push("orderIds=" + o.estaff_order_number) })
        fetchUrl(`/orders/OrderIsEditable?${qs.join("&")}`, "GET")
            .then((response) => {
                handleLogoutOnSessionExpire(response);
                return response.json() as Promise<[]>
            })
            .then(data => {
                dispatch({ type: 'RECEIVE_CUSTOMER_ORDER_EDITABLE_STATES', orderEditableStates: data });
            });

        dispatch({ type: 'REQUEST_CUSTOMER_ORDER_EDITABLE_STATES' });

    },
    requestExportCustomerOrders: (acsessToken: string, dataState: State): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        const newState = { ...dataState }
        newState.take = 100000000;
        const req = toDataSourceRequestString(newState);
        if (appState.customerOrders !== undefined) {
            if (appState && appState.customerOrders) {
                fetchUrl(`/orders/GetCustomerOrders?${req}`, "GET")
                    .then(response => {
                        handleLogoutOnSessionExpire(response);
                        return response.json() as Promise<DataRecievedResults>
                    })
                    .then((data) => {
                        dispatch({ type: 'RECEIVE_EXPORT_CUSTOMER_ORDERS', exportedCustomerOrders: data.data });
                    });

                dispatch({ type: 'REQUEST_EXPORT_CUSTOMER_ORDERS' });
            }
        }
    },
    searchOrderByEstaffOrderId: (estaffOrderId: number, dataState: State, orderHeaderId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetchUrl('/orders/SearchCustomerOrdersByEstaffOrderId?estaffOrderId=' + estaffOrderId + "&excludeOrderId=" + orderHeaderId, "GET").
            then(response => {
                handleLogoutOnSessionExpire(response);
                return response.json() as Promise<OrderDataRecievedResults>
            })
            .then(data => {
                dispatch({ type: 'RECEIVE_SEARCH_CUSTOMER_ORDERS', orderHeaders: data.data, total: data.total, dataState: dataState });
            });

        dispatch({ type: 'REQUEST_SEARCH_CUSTOMER_ORDERS' });
    },
    onUpdateOrderStatus: (orderIds: number[] | undefined, statusId: number | null | undefined): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (statusId != null) {
            dispatch({ type: "REQUEST_UPDATE_ORDER_STATUS" });
            var appState = getState();

            fetchUrl("/orders/UpdateCustomerOrdersStatus", "POST", { body: JSON.stringify({ orderIds: orderIds, statusId: statusId }) }, { "Content-Type": "application/json" })
                .then((response) => {
                    handleLogoutOnSessionExpire(response);
                    return response.json()
                })
                .then((json) => {
                    json.forEach((orderResult: CustomerOrderStatusUpdateResult) => {
                        if (orderResult.isUpdated) {
                            CookiesUtil.setNotificationMessages({
                                subject: "",
                                message: orderResult.message,
                                type: "success",
                                created: new Date(),
                                expire: 13000,
                                read: false
                            });
                        } else {
                            CookiesUtil.setNotificationMessages({
                                subject: "",
                                message: orderResult.message,
                                type: "error",
                                created: new Date(),
                                expire: 13000,
                                read: false
                            });
                        }
                    });

                    var ds = {}

                    if (appState.customerOrders != undefined) {
                        ds = appState.customerOrders.dataState;
                    }

                    dispatch({ type: "RECEIVE_UPDATE_ORDER_STATUS", alertDialogIsVisible: true, confirmDialogIsVisible: false });
                });
        }

    },
    CloseConfirmUpdateOrderStatusWindow: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "CLOSE_CONFIRM_UPDATE_ORDER_STATUS_WINDOW" });
    },
    OpenConfirmUpdateOrderStatusWindow: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "OPEN_CONFIRM_UPDATE_ORDER_STATUS_WINDOW" });
    },
    onfetchOrderStatuses: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetchUrl("/orders/GetOrderStatuses?type=BATCH", "GET")
            .then((response) => {
                handleLogoutOnSessionExpire(response);
                return response.json()
            })
            .then((orderStatuses) => {
                dispatch({ type: "RECEIVE_FETCH_ORDER_STATUS", orderStatuses: orderStatuses })
            });
    },
    onfetchAllOrderStatuses: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetchUrl("/orders/GetOrderStatuses?type=ALL", "GET")
            .then((response) => {
                handleLogoutOnSessionExpire(response);
                return response.json()
            })
            .then((orderStatuses) => {
                dispatch({ type: "RECEIVE_FETCH_ALL_ORDER_STATUS", orderStatuses: orderStatuses })
            });
    },
    onForceRefreshCustomerOrders: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "ON_FORCE_REFRESH_CUSTOMER_ORDERS" })
    },
    onOrderStatusChanges: (e: DropDownListChangeEvent): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "SET_ORDER_STATUS", orderStatus: e.value })
    },
    onCloseUpdateOrderStatusWindow: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "CLOSE_UPDATE_ORDER_STATUS_WINDOW" });
    },
    onOpenUpdateOrderStatusWindow: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "OPEN_UPDATE_ORDER_STATUS_WINDOW" });
    },
    onSelectionChange: (selectedState: { [id: string]: boolean | number[] }): AppThunkAction<KnownAction> => (dispatch, getState) => {
        var appState = getState();
        let customerOrders = appState.customerOrders?.customerOrders;
        let newSelectedCustomerOrders = appState.customerOrders?.selectedCustomerOrders;
        let oIds: number[] = []
        for (const k in selectedState) {
            var selectedOrder = newSelectedCustomerOrders?.find(i => i.estaff_order_number == k as unknown as number);
            var selectedIndex = newSelectedCustomerOrders?.findIndex(i => i.estaff_order_number == k as unknown as number);
            var order = customerOrders?.find(i => i.estaff_order_number == k as unknown as number)
            let oId = k as unknown as number
            let foundIndex = oIds.findIndex(id => id == oId);
            if (selectedState[k]) {
                oIds.push(oId);
                if (!selectedOrder && order) {
                    newSelectedCustomerOrders?.push(order)
                }
            } else {
                if (foundIndex !== undefined && foundIndex > -1) {
                    oIds.splice(foundIndex, 1);
                }
                if (selectedIndex !== undefined && selectedIndex > -1) {
                    newSelectedCustomerOrders?.splice(selectedIndex, 1);
                }
            }
        }

        dispatch({ type: "ON_SELECTION_CHANGE", selectedCustomerOrders: newSelectedCustomerOrders, selectedCustomerOrderIds: oIds });
    },
    OnContextMenuOpen: (e: any, props: GridCellProps): AppThunkAction<KnownAction> => (dispatch, getState) => {
        var orderId = props.dataItem["estaff_order_number"];
        e.stopPropagation();
        dispatch({ type: "ON_OPEN_CONTEXTUAL_MENU", offset: { left: e.clientX, top: e.clientY }, selectedCustomerOrderIds: [orderId] })
    },
    OnContextMenuClose: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "ON_CLOSE_CONTEXTUAL_MENU" })
    },
    onSelectContextualMenuOrderStatus: (e: MenuSelectEvent): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let orderStatus = e.item.data;
        dispatch({ type: "ON_SELECT_CONTEXTUAL_MENU_ORDER_STATUS", orderStatus: orderStatus })
    },
    onCacnelOrders: (orderIds: number[] | undefined): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (orderIds == undefined || orderIds?.length == 0) {
            //dispatch no order is selected error
            return
        }

        let appState = getState()

        dispatch({ type: "ON_REQUEST_CANCEL_ORDERS" })

        fetchUrl("/orders/CancelCustomerOrders", "POST", { body: JSON.stringify({ orderIds: orderIds }) }, { "Content-Type": "application/json" })
            .then((response) => {
                handleLogoutOnSessionExpire(response);
                return response.json()
            })
            .then((json) => {
                json.forEach((orderResult: CustomerOrderStatusUpdateResult) => {
                    if (orderResult.isUpdated) {
                        CookiesUtil.setNotificationMessages({
                            subject: "",
                            message: orderResult.message,
                            type: "success",
                            created: new Date(),
                            expire: 13000,
                            read: false
                        });
                    } else {
                        CookiesUtil.setNotificationMessages({
                            subject: "",
                            message: orderResult.message,
                            type: "error",
                            created: new Date(),
                            expire: 13000,
                            read: false
                        });
                    }
                });

                var ds = {}

                if (appState.customerOrders != undefined) {
                    ds = appState.customerOrders.dataState;
                }

                dispatch({ type: "ON_RECEIVE_CANCEL_ORDERS" });
            });
    },
    CloseConfirmCancelOrderWindow: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "ON_CLOSE_CONFIRM_CANCEL_ORDERS_WINDOW" })
    },
    OpenConfirmCancelOrderWindow: (orderIds: number[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "ON_OPEN_CONFIRM_CANCEL_ORDERS_WINDOW", selectedCustomerOrderIds: orderIds })
    },
    onCreateIntoCW: (customerOrder: CustomerOrder | null): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "CUSTOMERORDER/ON_REQUEST_CREATE_INTO_CW" })
        fetchUrl(
            process.env.REACT_APP_API_URL + "/Connectwise/CreateSO?orderId=" + customerOrder?.estaff_order_number, "POST")
            .then((response) => {
                handleLogoutOnSessionExpire(response);
                return response.json() as Promise<CwOrderResult>
            })
            .then((result) => {
                var type: any = "error";
                if (result.code == "2") {

                    type = "success"
                }
                CookiesUtil.setNotificationMessages({
                    subject: "",
                    message: result.message,
                    type: type,
                    created: new Date(),
                    expire: 13000,
                    read: false
                });

                dispatch({ type: "CUSTOMERORDER/ON_RECEIVE_CREATE_INTO_CW" })
            });
    },
    onPromptCreateOrderIntoCW: (orderId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let appState = getState();
        let customerOrders = appState.customerOrders?.customerOrders;

        let customerOrder : CustomerOrder | null = null;
        if (customerOrders) {
            customerOrder = customerOrders?.find(e => e.estaff_order_number == orderId) as CustomerOrder | null;
        }

        dispatch({ type: "CUSTOMERORDER/ON_PROMPT_CREATE_ORDER_INTO_CW", orderToCreateIntoCw : customerOrder})
    },
    CloseConfirmCreateOrderIntoCW: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "ON_CLOSE_CREATE_ORDER_INTO_CW_WINDOW" })
    },
    onloading: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "LOADING" })
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: CustomerOrdersState = {
    init: true,
    customerOrders: [],
    isLoading: false,
    total: 0,
    dataState: {},
    exportedCustomerOrders: [],
    orderHeaders: [],
    confirmDialogIsVisible: false,
    errorMessage: null,
    reloadCustomerOrders: false,
    updateOrderStatus: false,
    orderStatusId: null,
    orderStatuses: [],
    orderEditableStates: [],
    orderStatus: null,
    updateOrderStatusWindowIsVisible: false,
    selectedCustomerOrders: [],
    contextualMenuIsOpen: false,
    contextualMenuOffset: undefined,
    confirmCancelOrdersWindowIsVisible: false,
    selectedCustomerOrderIds: [],
    allOrderStatuses: [],
    confirmCreateOrderIntoCWIsVisible: false,
    orderToCreateIntoCW: null
};

export const reducer: Reducer<CustomerOrdersState> = (state: CustomerOrdersState | undefined, incomingAction: Action): CustomerOrdersState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_CUSTOMER_ORDERS':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: true,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: state.confirmDialogIsVisible,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: false,
                updateOrderStatus: false,
                orderStatusId: state.orderStatusId,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: state.contextualMenuIsOpen,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: state.confirmCancelOrdersWindowIsVisible,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.allOrderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };
        case 'ENTER_EDIT_CUSTOMER_ORDERS':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: action.customerOrders,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: state.confirmDialogIsVisible,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: false,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: state.contextualMenuIsOpen,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: state.confirmCancelOrdersWindowIsVisible,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.allOrderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };
        case 'EDIT_CUSTOMER_ORDERS':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: action.customerOrders,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: state.confirmDialogIsVisible,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: false,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: state.contextualMenuIsOpen,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: state.confirmCancelOrdersWindowIsVisible,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.allOrderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };

        case 'REQUEST_CUSTOMER_ORDER_HEADER':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: true,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: state.confirmDialogIsVisible,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: false,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: state.contextualMenuIsOpen,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: state.confirmCancelOrdersWindowIsVisible,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.allOrderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };
        case 'RECEIVE_CUSTOMER_ORDER_HEADER':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: action.customerOrders,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: state.confirmDialogIsVisible,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: false,
                updateOrderStatus: false,
                orderStatusId: state.orderStatusId,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: state.contextualMenuIsOpen,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: state.confirmCancelOrdersWindowIsVisible,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.allOrderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };
        case 'RECEIVE_CUSTOMER_ORDERS':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: action.customerOrders,
                total: action.total,
                isLoading: false,
                dataState: action.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: state.confirmDialogIsVisible,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: false,
                updateOrderStatus: false,
                orderStatusId: state.orderStatusId,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: state.contextualMenuIsOpen,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: state.confirmCancelOrdersWindowIsVisible,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.allOrderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };
        case 'REQUEST_EXPORT_CUSTOMER_ORDERS':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: true,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: state.confirmDialogIsVisible,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: false,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: state.contextualMenuIsOpen,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: state.confirmCancelOrdersWindowIsVisible,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.allOrderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };
        case 'RECEIVE_EXPORT_CUSTOMER_ORDERS':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                exportedCustomerOrders: action.exportedCustomerOrders,
                confirmDialogIsVisible: state.confirmDialogIsVisible,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: false,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: state.contextualMenuIsOpen,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: state.confirmCancelOrdersWindowIsVisible,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.allOrderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };
        case 'REQUEST_SEARCH_CUSTOMER_ORDERS':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: true,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: state.confirmDialogIsVisible,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: false,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: state.contextualMenuIsOpen,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: state.confirmCancelOrdersWindowIsVisible,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.allOrderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };
        case 'RECEIVE_SEARCH_CUSTOMER_ORDERS':
            return {
                init: false,
                orderHeaders: action.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: state.confirmDialogIsVisible,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: false,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: state.contextualMenuIsOpen,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: state.confirmCancelOrdersWindowIsVisible,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.allOrderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };
        case 'REQUEST_GET_CUSTOMER_ORDER_CHILDREN':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: true,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: state.confirmDialogIsVisible,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: false,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: state.contextualMenuIsOpen,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: state.confirmCancelOrdersWindowIsVisible,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.allOrderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };
        case 'RECEIVE_GET_CUSTOMER_ORDER_CHILDREN':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: state.confirmDialogIsVisible,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: false,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: state.contextualMenuIsOpen,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: state.confirmCancelOrdersWindowIsVisible,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.allOrderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };
        case 'REQUEST_UPDATE_ORDER_STATUS':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: true,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: state.confirmDialogIsVisible,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: false,
                updateOrderStatus: false,
                orderStatusId: state.orderStatusId,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: state.contextualMenuIsOpen,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: state.confirmCancelOrdersWindowIsVisible,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.allOrderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };
        case 'RECEIVE_UPDATE_ORDER_STATUS':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: action.confirmDialogIsVisible,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: true,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: false,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: state.contextualMenuIsOpen,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: state.confirmCancelOrdersWindowIsVisible,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.allOrderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };
        case 'RECEIVE_FETCH_ORDER_STATUS':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: state.confirmDialogIsVisible,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: false,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: action.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: state.contextualMenuIsOpen,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: state.confirmCancelOrdersWindowIsVisible,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.allOrderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };
        case 'ON_FORCE_REFRESH_CUSTOMER_ORDERS':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: state.confirmDialogIsVisible,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: true,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: state.contextualMenuIsOpen,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: state.confirmCancelOrdersWindowIsVisible,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.allOrderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };
        case 'RECEIVE_CUSTOMER_ORDER_EDITABLE_STATES':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: state.confirmDialogIsVisible,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: false,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: state.orderStatuses,
                orderEditableStates: action.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: state.contextualMenuIsOpen,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: state.confirmCancelOrdersWindowIsVisible,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.allOrderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };
        case 'SET_ORDER_STATUS':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: false,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: false,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: action.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: state.contextualMenuIsOpen,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: state.confirmCancelOrdersWindowIsVisible,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.allOrderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };
        case 'CLOSE_UPDATE_ORDER_STATUS_WINDOW':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: state.confirmDialogIsVisible,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: false,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: false,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: state.contextualMenuIsOpen,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: state.confirmCancelOrdersWindowIsVisible,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.allOrderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };
        case 'OPEN_UPDATE_ORDER_STATUS_WINDOW':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: false,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: false,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: null,
                updateOrderStatusWindowIsVisible: true,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: state.contextualMenuIsOpen,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: state.confirmCancelOrdersWindowIsVisible,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.allOrderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };
        case 'ON_SELECTION_CHANGE':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: state.confirmDialogIsVisible,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: false,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: action.selectedCustomerOrders,
                contextualMenuIsOpen: state.contextualMenuIsOpen,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: state.confirmCancelOrdersWindowIsVisible,
                selectedCustomerOrderIds: action.selectedCustomerOrderIds,
                allOrderStatuses: state.allOrderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };

        case 'ON_OPEN_CONTEXTUAL_MENU':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: state.confirmDialogIsVisible,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: false,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: true,
                contextualMenuOffset: action.offset,
                confirmCancelOrdersWindowIsVisible: state.confirmCancelOrdersWindowIsVisible,
                selectedCustomerOrderIds: action.selectedCustomerOrderIds,
                allOrderStatuses: state.allOrderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };
        case 'ON_CLOSE_CONTEXTUAL_MENU':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: state.isLoading,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: state.confirmDialogIsVisible,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: false,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: false,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: state.confirmCancelOrdersWindowIsVisible,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.allOrderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };
        case 'ON_SELECT_CONTEXTUAL_MENU_ORDER_STATUS':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: true,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: false,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: action.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: false,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: state.confirmCancelOrdersWindowIsVisible,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.allOrderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };
        case 'OPEN_CONFIRM_UPDATE_ORDER_STATUS_WINDOW':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: true,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: false,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: false,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: state.confirmCancelOrdersWindowIsVisible,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.allOrderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };
        case 'CLOSE_CONFIRM_UPDATE_ORDER_STATUS_WINDOW':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: false,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: false,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: false,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: state.confirmCancelOrdersWindowIsVisible,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.allOrderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };
        case 'ON_CLOSE_CONFIRM_CANCEL_ORDERS_WINDOW':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: false,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: false,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: false,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: false,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.allOrderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };
        case 'ON_OPEN_CONFIRM_CANCEL_ORDERS_WINDOW':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: false,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: false,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: false,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: true,
                selectedCustomerOrderIds: action.selectedCustomerOrderIds,
                allOrderStatuses: state.allOrderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };

        case 'ON_REQUEST_CANCEL_ORDERS':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: true,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: false,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: false,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: false,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: false,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.allOrderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };


        case 'ON_RECEIVE_CANCEL_ORDERS':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: false,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: true,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: false,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: false,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.allOrderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };
        case 'RECEIVE_FETCH_ALL_ORDER_STATUS':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: false,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: true,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: false,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: false,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: action.orderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };
        case 'CUSTOMERORDER/ON_PROMPT_CREATE_ORDER_INTO_CW':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: false,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: false,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: false,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: false,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.orderStatuses,
                confirmCreateOrderIntoCWIsVisible: true,
                
                orderToCreateIntoCW: action.orderToCreateIntoCw
            };
        case 'ON_CLOSE_CREATE_ORDER_INTO_CW_WINDOW':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: false,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: false,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: false,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: false,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.orderStatuses,
                confirmCreateOrderIntoCWIsVisible: false,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };
        case 'CUSTOMERORDER/ON_RECEIVE_CREATE_INTO_CW':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: false,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: true,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: false,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: false,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.orderStatuses,
                confirmCreateOrderIntoCWIsVisible: false,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };
        case 'CUSTOMERORDER/ON_REQUEST_CREATE_INTO_CW':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: true,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: false,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: false,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: false,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: false,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.orderStatuses,
                confirmCreateOrderIntoCWIsVisible: false,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };

        case 'RECEIVE_DEALER_MODULE_PARAMS':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: false,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: false,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: false,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: false,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.orderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCreateOrderIntoCWIsVisible,
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };
        case 'REQUEST_DEALER_MODULE_PARAMS':
            return {
                init: false,
                orderHeaders: state.orderHeaders,
                customerOrders: state.customerOrders,
                total: state.total,
                isLoading: true,
                dataState: state.dataState,
                exportedCustomerOrders: state.exportedCustomerOrders,
                confirmDialogIsVisible: false,
                errorMessage: state.errorMessage,
                reloadCustomerOrders: false,
                updateOrderStatus: false,
                orderStatusId: null,
                orderStatuses: state.orderStatuses,
                orderEditableStates: state.orderEditableStates,
                orderStatus: state.orderStatus,
                updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                selectedCustomerOrders: state.selectedCustomerOrders,
                contextualMenuIsOpen: state.contextualMenuIsOpen,
                contextualMenuOffset: state.contextualMenuOffset,
                confirmCancelOrdersWindowIsVisible: false,
                selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                allOrderStatuses: state.orderStatuses,
                confirmCreateOrderIntoCWIsVisible: state.confirmCancelOrdersWindowIsVisible,
                
                orderToCreateIntoCW: state.orderToCreateIntoCW
            };
            case 'LOADING':
                return {
                    init: false,
                    orderHeaders: state.orderHeaders,
                    customerOrders: state.customerOrders,
                    total: state.total,
                    isLoading: true,
                    dataState: state.dataState,
                    exportedCustomerOrders: state.exportedCustomerOrders,
                    confirmDialogIsVisible: false,
                    errorMessage: state.errorMessage,
                    reloadCustomerOrders: false,
                    updateOrderStatus: false,
                    orderStatusId: null,
                    orderStatuses: state.orderStatuses,
                    orderEditableStates: state.orderEditableStates,
                    orderStatus: state.orderStatus,
                    updateOrderStatusWindowIsVisible: state.updateOrderStatusWindowIsVisible,
                    selectedCustomerOrders: state.selectedCustomerOrders,
                    contextualMenuIsOpen: state.contextualMenuIsOpen,
                    contextualMenuOffset: state.contextualMenuOffset,
                    confirmCancelOrdersWindowIsVisible: false,
                    selectedCustomerOrderIds: state.selectedCustomerOrderIds,
                    allOrderStatuses: state.orderStatuses,
                    confirmCreateOrderIntoCWIsVisible: state.confirmCancelOrdersWindowIsVisible,
                    
                    orderToCreateIntoCW: state.orderToCreateIntoCW
                };
        default:
            return state;
    }
};
