import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { useTranslation } from 'react-i18next';

export const ConfirmDialog = (props : any) => {
  const { t, i18n } = useTranslation();

    return (
      <div>
        <Dialog title={i18n.t("confim")} onClose={props.onPrompt}>
          <p style={{ margin: "25px", textAlign: "center" }}>{props.message}</p>
          <DialogActionsBar>
            <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={props.onPrompt}>{i18n.t("no")}</button>
            <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={props.onConfirm}>{i18n.t("yes")}</button>
          </DialogActionsBar>
        </Dialog>
      </div>
    );
}
