import * as React from 'react';
import ReactDOM from 'react-dom';
import { ContextualMenu, ContextualMenuItemType, IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

export const AppSelectorMenu: React.FunctionComponent = () => {
    const { t, i18n } = useTranslation();
    const linkRef = React.useRef(null);
    const [showContextualMenu, setShowContextualMenu] = React.useState(false);
    const onShowContextualMenu = React.useCallback((ev: React.MouseEvent<HTMLElement>) => {
        setShowContextualMenu(true);
    }, []);
    const onHideContextualMenu = React.useCallback(() => { setShowContextualMenu(false) }, []);
    const menuItems: IContextualMenuItem[] = [
        {
            key: 'lizzy',
            text: i18n.t('comeSeeMeLizzy'),
            href: 'https://lizzy.estaffsoftware.com',
            target: '_blank'
        },
        {
            key: 'div',
            itemType: ContextualMenuItemType.Divider,
        },
        {
            key: 'charlie',
            text: i18n.t('comeSeeMeCharlie'),
            href: 'https://charlie.estaffsoftware.com',
            target: '_blank'
        }
    ];
    return (
        <div>
            <a ref={linkRef} onClick={onShowContextualMenu} className="nav-link dropdown-toggle" href="#"> {i18n.t('changeApp')} <span className="fas fa-chevron-down ml-2 align-middle"></span></a>

            <ContextualMenu
                items={menuItems}
                hidden={!showContextualMenu}
                target={linkRef}
                onItemClick={onHideContextualMenu}
                onDismiss={onHideContextualMenu}
            />
        </div>
    );
};
