import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { toODataString } from '@progress/kendo-data-query';
import * as NotificationsStore from '../../store/Notifications/Notifications';
import { useMsal } from '@azure/msal-react';
import { RouteComponentProps } from 'react-router';
import { CookiesUtil } from '../../Module/CookiesUtil';
import { ApplicationState } from '../../store';
import { connect, useDispatch } from 'react-redux';
import { ReactNotificationOptions, store } from 'react-notifications-component';
import ReactNotification from 'react-notifications-component'
//import '../../../node_modules/react-notifications-component/dist/scss/notification.scss';
import './notifications.css'

import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";
import { Slide } from '@progress/kendo-react-animation';

interface AccessTokenProps {
    onTokenReceived: (token: string) => void
}
let interval: any = null;
interface State {
    success: boolean;
    error: boolean;
    warning: boolean;
    info: boolean;
    none: boolean;
}

export const NotificationWrapper = (props: any) => {
    const [state, setState] = React.useState<State>({
        success: false,
        error: false,
        warning: false,
        info: true,
        none: false,
    });
    const { info } = state;


    React.useEffect(() => {
        const interval = setInterval(() => {
            setState({ ...state, info: false })
        }, props.hideAfter);
        return () => clearInterval(interval);
    }, []);

    return <>
        {info && (
            <Slide direction={"up"}>
                <Notification
                    type={{ style: "info", icon: true }}
                    closable={true}
                    onClose={() => setState({ ...state, info: false })}
                >
                    <span>{props.message}</span>
                </Notification>
            </Slide>

        )}

    </>;
}
