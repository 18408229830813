import React, { useEffect, useRef } from "react";
import i18n from "../../i18n";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import CustomerOrderItems from "./CustomerOrderItems";
import CustomerOrdersGrid from "./CustomerOrdersGrid";
import configureStore from "../../store/configureStore";
import { createBrowserHistory } from 'history';
import { connect, Provider } from "react-redux";
import { ApplicationState } from "../../store";
import { RouteComponentProps } from "react-router";


declare global {
  interface Window { reduxStores: any; }
}

export interface CustomerOrderDetailProps {
  orderHeaderId: number,
}

const CustomerOrderDetail = (props: CustomerOrderDetailProps) => {
  window.reduxStores = window.reduxStores || [];

  if (window.reduxStores[props.orderHeaderId] == null) {
    const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
    const history = createBrowserHistory({ basename: baseUrl });
    const store = configureStore(history);
    
  
    console.log("props==>", props.orderHeaderId);
    console.log("init stores==>", window.reduxStores);
  
    if (window.reduxStores[props.orderHeaderId] == null) {
      console.log("creating store for orderHeaderId==>", props.orderHeaderId);
      window.reduxStores[props.orderHeaderId] = store;
    }
  }


  return (
    <>
      <Provider store={window.reduxStores[props.orderHeaderId]} key={props.orderHeaderId} >
        <CustomerOrderItems orderHeaderId={props.orderHeaderId} key={props.orderHeaderId}/>
      </Provider>
    </>
  );
};


export default CustomerOrderDetail;