
import { Button } from "@progress/kendo-react-buttons";
import * as React from "react";
import { useTranslation } from "react-i18next";

export const SearchProductCommandCell = (props : any) => {
  const { t, i18n } = useTranslation();
  const { dataItem } = props;
  const inEdit = dataItem[props.editField];
  return inEdit ? (
    <td className="k-command-cell">
      <div
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-save-command"
        onClick={() =>
          props.update(dataItem)
        }
      >
        {i18n.t("update")}
      </div>
      <div
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-cancel-command"
        onClick={() =>
          props.cancel(dataItem)
        }
      >
        {i18n.t("cancel")}
      </div>
    </td>
  ) : (
    <td className="k-command-cell">
      <div 
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-grid-edit-command"
        onClick={() => props.edit(dataItem)}
      >
        {i18n.t("edit")}
      </div >
    </td>
  );
};