import * as React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
var typingTimer;                //timer identifier
var doneTypingInterval = 5000;  //time in ms, 5 seconds for example

export const TextFilterCell = props => {
  let hasValue = value => Boolean(value && value !== props.defaultItem);
  let timer;              // Timer identifier
  const waitTime = 500;

  //timer identifier
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const onChange = event => {
      hasValue = hasValue(event.target.value);
      props.onChange({
        value: hasValue ? event.target.value : '',
        operator: hasValue ? 'contains' : '',
        syntheticEvent: event.syntheticEvent
      });
  };

  return <div className="k-filtercell">
        <Input className='custom-filter' onChange={onChange} value={props.value || props.defaultItem} defaultItem={props.defaultItem} />
      </div>;
};