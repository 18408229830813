import { ORDER_STATUS_ID_APPROVED, ORDER_STATUS_ID_COMPLETED, ORDER_STATUS_ID_COMPLETED_OLD, ORDER_STATUS_ID_READY_FOR_PICK_UP, ORDER_STATUS_ID_SHIPPED } from "../store/constants";
import { SelectionState } from "../store/interfaces";

export const isStatusCompleted = (orderStatusId: number | null | undefined) => {
    if ((orderStatusId == ORDER_STATUS_ID_COMPLETED ||
        orderStatusId == ORDER_STATUS_ID_COMPLETED_OLD ||
        orderStatusId == ORDER_STATUS_ID_READY_FOR_PICK_UP ||
        orderStatusId == ORDER_STATUS_ID_SHIPPED)) {
        return true;
    }

    return false
}

export const orderIsEditable = (orderId: number, orderEditableStates: []) : boolean =>  {
    if (orderEditableStates != undefined) {
        var results : any = orderEditableStates.find((e : any) => e.orderId == orderId);
        return results?.isEditable;
    }

    return false;
}

export const getSelectedIds = (selectionState : SelectionState): number[] => {
    var ids: number[] = [];
    for (const k in selectionState) {
        if (selectionState[k]) {
            ids.push(Number(k));
        }
    }

    return ids;
}

export const updateGridFooterPager = (total: number, name: string, parentClassName: string) : void => {
    const parents = document.getElementsByClassName(parentClassName);
    const pagertotoalItems = parents[0].getElementsByClassName("k-pager-info")
    if(pagertotoalItems.length > 0) {
        pagertotoalItems[0].innerHTML = "/Total: "  + total + " " + name;
    }
}