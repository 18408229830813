import React, { useEffect, useRef } from "react";

import {
  Grid,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridSelectionChangeEvent,
  getSelectedState,
  GridHeaderSelectionChangeEvent,
  GridToolbar,
  GridCellProps,
  GridItemChangeEvent
} from "@progress/kendo-react-grid";
import { DataResult, getter, State } from "@progress/kendo-data-query";
import i18n from '../../i18n';
import { Input } from 'reactstrap';
import { SearchProductCommandCell } from '../CommandCell/SearchProductCommandCell';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as SearchProductsStore from '../../store/Product/SearchProducts';
import { RouteComponentProps } from 'react-router';
import { loadingPanel } from '../loadingPanel';
import { CustomerOrderItem, OrderItem } from '../../store/interfaces';
import { fetchUrl } from '../../Module/Fetch';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Spinner } from '@fluentui/react';
import { ConfirmDialog } from '../../Module/Dialog/ConfirmDialog';
import { Console } from 'console';
import { Fade } from "@progress/kendo-react-animation";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { HtmlCell } from "../../Module/renderers";
import { Loader } from "@progress/kendo-react-indicators";

const DATA_ITEM_KEY: string = "product_id";
const SELECTED_FIELD: string = "selected";
const idGetter = getter(DATA_ITEM_KEY);

interface MyComponentOwnProps {
  orderHeaderId: number;
  onAddItem: () => void;
  onClose: () => void;
  init: boolean
}

type SearchProductsProps =
SearchProductsStore.SearchProductsState // ... state we've requested from the Redux store
    & typeof SearchProductsStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps
    & MyComponentOwnProps

const SearchProduct = (props : SearchProductsProps, orderHeaderId : number) => {
  const [noProductSelected, setNoProductSelected] = React.useState<boolean>(false);

  const CommandCell = (props: GridCellProps) => (
    <SearchProductCommandCell
      {...props}
      edit={enterEdit}
      update={update}
      cancel={cancel}
      editField={editField}
    />
  );

  const addItems = () => {
    var noProductSelected : boolean = true;
    for (const [key, value] of Object.entries(selectedState)) {
      if (value) {
        noProductSelected = false;
        
      }
    }

    setNoProductSelected(noProductSelected);
    if (!noProductSelected) {
      props.addItem(selectedState, props.orderHeaderId);
    }
  }

  const itemChange = (event: GridItemChangeEvent) => {
    props.productChange(event);
  };

  const update = (dataItem: any) => {
    props.editProduct(dataItem);
  };
  
  const cancel = (dataItem: any) => {
    props.cancelEditProduct(dataItem);
  };

  const [editField, setEditField] = React.useState<string | undefined>(
    "inEdit"
  );

  const enterEdit = (dataItem: any) => {
    props.enterEditProduct(dataItem);
  };

  const [selectedState, setSelectedState] = React.useState<{
    [id: string]: boolean | number[];
  }>({});

  const [products, setProducts] = React.useState<DataResult>({
    data: [],
    total: 77,
  });

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    props.RequestSearchProducts(e.dataState);
  };

  const dataReceived = (products: DataResult) => {
    setProducts(products);
  };

  const onSelectionChange = React.useCallback(
    (event: GridSelectionChangeEvent) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY
      });

      //setSelectedProductState(selectedProductState);
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  const onHeaderSelectionChange = React.useCallback(
    (event: GridHeaderSelectionChangeEvent) => {
      const checkboxElement: any = event.syntheticEvent.target
      const checked = checkboxElement.checked;
      const newSelectedState = {} as any;

      event.dataItems.forEach(item => {
        newSelectedState[idGetter(item)] = checked;
      });

      setSelectedState(newSelectedState);
    },
    []
  );

  const searchProduct = (e: any) => {
    if (e.target.value.length >= 3) {
      const ds = {
        "filter": {
          "logic": "and",
          "filters": [
            {
              "field": "mfr_part_number",
              "operator": "contains",
              "value": e.target.value
            }
          ]
        }
      };

      const newDs = Object.assign(props.dataState, ds);

      props.RequestSearchProducts(newDs);
    }
  }
  
  useEffect(() => {
    if (props.itemAdded) {
      props.onAddItem();
      props.init();
    }
  });
  


  return (
    <div>
      <Fade>
        {noProductSelected && (
          <Notification
            type={{
              style: "error",
              icon: true,
            }}
            style={{ width: 1200 }}
            closable={true}
            onClose={() => setNoProductSelected(false)}

          >
            <span>{i18n.t("selectAtLeastOneProduct")}</span>
          </Notification>
        )}
      </Fade>
      {props.isLoading &&
        <Dialog>
            <Loader size="large"/>
        </Dialog>
      }
      <Grid
        pageSize={props.total}
        filterable={true}
        sortable={true}
        pageable={true}
        total={props.total}
        {...props.dataState}
        data={props.products?.map(item => ({ ...item, [SELECTED_FIELD]: selectedState[idGetter(item)] }))}
        dataItemKey={DATA_ITEM_KEY}
        onDataStateChange={dataStateChange}
        selectedField={SELECTED_FIELD}
        onSelectionChange={onSelectionChange}
        onHeaderSelectionChange={onHeaderSelectionChange}
        selectable={{
          enabled: true,
          drag: false,
          cell: false,
          mode: "multiple",
        }}
        onItemChange={itemChange}
        editField={editField}
      >
        <GridToolbar>
          <Input type='text' onChange={searchProduct} placeholder={i18n.t('keyWordPlaceHolder')}/>
        </GridToolbar>
        <Column
          editable={false}
          field={SELECTED_FIELD}
          filterable={false}
          width="50px"
          headerSelectionValue={
            products.data.findIndex(item => !selectedState[idGetter(item)]) === -1
          } />
        <Column field="mfr_part_number" filterable={false} title="mfr_part_number" editable={false} />
        <Column field="description" title="description" filterable={false} width={150} editable={false} cell={HtmlCell}  />
        <Column
          editable={false}        
          field="cost"
          filterable={false}
          title="cost"
        />
        <Column
          field="sell_price"
          title="sell_price"
          editor='numeric'
          filterable={false}
        />
        <Column
          field="quantity"
          title="quantity"
          editor='numeric'
          filterable={false}
        />
        <Column
          editable={false}
          field="stockint"
          filterable={false}
          title="stockint"
        />
        <Column
          editable={false}
          field="stockpref"
          filterable={false}
          title="stockpref"
        />
        <Column
          editable={false}
          field="stockint"
          filterable={false}
          title="stockint"
        />
        <Column cell={CommandCell} width="300px" filterable={false} />
      </Grid>
      {props.isLoading && loadingPanel}
      <div className="text-right">
        <button
          onClick={props.onClose}
          type="button"
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
        >
          {i18n.t("cancel")}
        </button>
        <button
          type="button"
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
          onClick={addItems}
        >
          {i18n.t("submit")}
        </button>
      </div>
    </div>
  );
};


export default connect(
  (state: ApplicationState, ownProps: MyComponentOwnProps) => (
    {...state.searchProducts,
      orderHeaderId : ownProps.orderHeaderId,
      onAddItem: ownProps.onAddItem,
      onClose: ownProps.onClose,
    init: ownProps.init}), // Selects which state properties are merged into the component's props
  SearchProductsStore.actionCreators ,// Selects which action creators are merged into the component's props
  
)(SearchProduct as any); // eslint-disable-line @typescript-eslint/no-explicit-any