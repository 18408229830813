let env = process.env["NODE_ENV"];
export const authConfig = {
    auth: {
        clientId: "81461153-08a0-424e-bc92-b96ab1e6b7a8",
        authority: "https://estaffb2c.b2clogin.com/tfp/559b4a74-03af-4d4f-9957-5b46a61dfaf2/B2C_1_estaffLogin",
        knownAuthorities: ["https://estaffb2c.b2clogin.com/tfp/559b4a74-03af-4d4f-9957-5b46a61dfaf2/B2C_1_estaffLogin"],
        redirectUri: process.env.REACT_APP_URL
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["User.Read", ["https://estaffb2c.onmicrosoft.com/2cd6d51d-e79a-4eea-a785-afcb4a65e5f6/read"]]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.