import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { State, toODataString } from '@progress/kendo-data-query';
import { DataRecievedResults, Product } from '../store/interfaces';
import { useMsal } from '@azure/msal-react';

interface AccessTokenProps {
    onTokenReceived: (token: string) => void
}

export const AccessToken = (props: AccessTokenProps) => {

    const { instance, accounts, inProgress } = useMsal();
    const [accessToken, setAccessToken] = React.useState<string>("");

    const requestDataIfNeeded = () => {
        if (accessToken != "") {
            return;
        }
        const request = {
            scopes: ["https://estaffb2c.onmicrosoft.com/2cd6d51d-e79a-4eea-a785-afcb4a65e5f6/read"],
            account: accounts[0]
        };

        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response: any) => {
            props.onTokenReceived(response.accessToken);
            setAccessToken(response);
        }).catch((e: any) => {
            console.log(e);
            instance.acquireTokenPopup(request).then((response: any) => {
                console.log(response);
            });
        });
    }

    React.useEffect(() => {
        requestDataIfNeeded();
    },[]);

    return null;
}
