import * as React from 'react';
import './NavMenu.css';
import { withTranslation } from 'react-i18next';

class Footer extends React.PureComponent<{ t: any, i18n: any }, { isOpen: boolean }> {
   
    public state = {
        isOpen: false
    };

    public render() {
        return (
            <>
                <footer className="bg-blue mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="d-flex justify-content-start">
                                    <div className="p-2">Gerry Version 2.0<br />{this.props.t("productOf")}:</div>
                                    <div className="p-2" style={{ paddingLeft: "3.4rem!important" }} ><a href="index.html" className="text-center"><img src="assets/images/log-estaff.png" className="img-fluid" /></a></div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <strong>{this.props.t("eStaff Management Softwares Inc.")}</strong><br />
                                            {this.props.t("estaffAddressPart1")} <br/> {this.props.t("estaffAddressPart2")}
                                            
                </div>
                            <div className="col-md-2">
                            {this.props.t("tollFree")}: 1-855-853-5775<br />
                            {this.props.t("fax")}: 514 798-6775<br />
                            {this.props.t("email")}: <a href="mailto:support@estaffsoftware.com">support@estaffsoftware.com</a>
                            </div>
                            <div className="col-md-2">
                                <strong>
                                    <u>
                                        <a href="#">{this.props.t("termOfuse")}</a><br />
                                        <a href="#">{this.props.t("privacyPolicy")}</a>
                                    </u>
                                </strong>
                            </div>
                        </div>
                    </div>
                    {/*<a className="help_button" title="Help" href="#"><img src="/assets/images/Icone-Jackie_blanc_64px.png" alt="Help" title="Help" width="50" /></a>*/}
                </footer>

            </>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}

export default withTranslation()(Footer);
