import { CompositeFilterDescriptor, GroupDescriptor, SortDescriptor, State, toDataSourceRequestString } from '@progress/kendo-data-query';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { LOCATION_CHANGE } from 'react-router-redux'
import React from 'react';
import { stat } from 'fs';
import { CustomerOrder, CustomerOrderItem, OrderItem, Product } from '../interfaces';
import { GridExpandChangeEvent, GridItemChangeEvent } from '@progress/kendo-react-grid';
import { fetchUrl, handleLogoutOnSessionExpire } from '../../Module/Fetch';


// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface SearchProductsState {
    isLoading: boolean;
    total: number;
    products: Product[];
    dataState: State,
    itemAdded: boolean
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestSearchProductsAction {
    type: 'REQUEST_SEARCH_PRODUCTS';
    dataState: State
}

interface ReceiveSearchProductsAction {
    type: 'RECEIVE_SEARCH_PRODUCTS';
    products: Product[];
    total: number,
    dataState: State
}

export interface DataRecievedResults {
    data: Product[],
    total: number
}

interface EnterEditProductAction {
    type: 'ENTER_EDIT_PRODUCT';
    products: Product[]
}

interface EditProductAction {
    type: 'EDIT_PRODUCT';
    products: Product[]
}

interface productChangeAction {
    type: 'CHANGE_PRODUCT';
    products: Product[]
}

interface cancelEditAction {
    type: 'CANCEL_EDIT';
    products: Product[]
}

interface requestAddItemAction {
    type: 'REQUEST_ADD_ITEM';
}

interface addItemAction {
    type: 'ADD_ITEM';
    itemAdded: boolean
}

interface initAction {
    type: 'INIT';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestSearchProductsAction | ReceiveSearchProductsAction | EnterEditProductAction |
                EditProductAction | productChangeAction | cancelEditAction | requestAddItemAction | addItemAction | initAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).


export const actionCreators = {
    RequestSearchProducts: (dataState: State, forceReload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        const req = toDataSourceRequestString(dataState);
        if (appState.searchProducts !== undefined) {
            if (forceReload || (appState && appState.searchProducts && appState.searchProducts.isLoading == false)) {
                fetchUrl(`/Products/Search?${req}`, "GET")
                    .then(response => {
                        handleLogoutOnSessionExpire(response);
                        return response.json() as Promise<DataRecievedResults>
                    })
                    .then(data => {
                        data.data.shift();
                        data.data.map((item) => {item.quantity = 1})
                        dispatch({ type: 'RECEIVE_SEARCH_PRODUCTS', products: data.data, total: data.total, dataState: dataState });
                    });

                dispatch({ type: 'REQUEST_SEARCH_PRODUCTS', dataState: dataState });
            }
        }
    },
    editProduct: (dataItem: Product): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState.searchProducts !== undefined) {
            const products = appState.searchProducts.products;
            const newData = products.map((item: Product) => ({
                ...item,
                inEdit: item.product_id === dataItem.product_id ? false : item.inEdit,
            }));
            dispatch({ type: 'EDIT_PRODUCT', products: newData });
        }
    },
    enterEditProduct: (dataItem: Product): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState.searchProducts !== undefined) {
            const products = appState.searchProducts.products;
            const newData = products.map((item: Product) => ({
                ...item,
                inEdit: item.product_id === dataItem.product_id ? true : false,
            }));

            dispatch({ type: 'ENTER_EDIT_PRODUCT', products: newData });

        }
    },
    productChange: (event: GridItemChangeEvent): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState.searchProducts !== undefined) {
            const products = appState.searchProducts.products;
            const field = event.field || "";
            const newData = products.map((item) =>
                item.product_id === event.dataItem.product_id
                    ? { ...item, [field]: event.value }
                    : item
            );

            dispatch({ type: 'CHANGE_PRODUCT', products: newData });
        }
    },
    cancelEditProduct: (dataItem: Product): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // find a way to save orginal item befor edit
        const appState = getState();
        if (appState.searchProducts !== undefined) {
            const products = appState.searchProducts.products;
            const newData = products.map((item: Product) => ({
                ...item,
                inEdit: item.product_id === dataItem.product_id ? false : item.inEdit,
            }));

            dispatch({ type: 'CANCEL_EDIT', products: newData });
        }
    },
    init :() : AppThunkAction<KnownAction> => (dispatch, getState) =>  {
        dispatch({ type: 'INIT'});
    },
    addItem: (selectedState: any, orderId: number) : AppThunkAction<KnownAction> => (dispatch, getState) =>  {
        const appState = getState();
        if (appState.searchProducts !== undefined) {
            const customerOrderItem : CustomerOrderItem[] = [];
            appState.searchProducts.products.map((e) => {
                if (selectedState[e.product_id]) {
                    customerOrderItem.push({
                      qty: e.quantity,
                      product_id: e.product_id,
                      product_description: e.description,
                      unit_sell_price: e.sell_price,
                      mfr_part_number: e.mfr_part_number,
                      order_header_id: orderId,
                      order_item_id: null,
                      line_id: null,
                      is_licence: false,
                      cost: e.cost,
                      evaluated_cost: null,
                      ehf_included: false,
                      inventory: null,
                      unit_reg_price: null,
                      sub_total: null,
                      item_profit : null,
                      evaluated_profit: null,
                      manufacturer_id : e.manufacturer_id,
                      manufacturer_name : e.manufacturer_name,
                      parent_line_id: null,
                      number_of_child: null,
                      eta: null,
                      unit_weight: null,
                      group_name: null,
                      invoice_number: null,
                      invoice_file_name: null,
                      item_status_id: null,
                      item_status_name: null,
                      supplier_order_id: null,
                      eui_company: null,
                      eui_first_name : null,
                      eui_last_name : null,
                      eui_address_line1 : null,
                      eui_address_line2 : null,
                      eui_city : null,
                      eui_state : null,
                      eui_zip : null,
                      eui_country : null,
                      eui_phone : null,
                      eui_email : null,
                      tracking : null,
                      serial_list : null,
                      nb_rec : null,
                      nb_page : null,
                      inEdit: undefined
                    });
                }
              });
          
              fetchUrl("/orders/AddItem", "POST", { body: JSON.stringify(customerOrderItem) }, {'Content-Type': 'application/json'})
              .then((response) => {
                  handleLogoutOnSessionExpire(response);
                  return response.json()
                })
              .then((res) => {
                  dispatch({ type: 'ADD_ITEM', itemAdded: true});
              });

              dispatch({ type: 'REQUEST_ADD_ITEM'});
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: SearchProductsState = { products: [], isLoading: false, total: 0, dataState: {}, itemAdded: false };

export const reducer: Reducer<SearchProductsState> = (state: SearchProductsState | undefined, incomingAction: Action): SearchProductsState => {
    if (state === undefined) {
        return unloadedState;
    }


    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_SEARCH_PRODUCTS':
            return {
                products: state.products,
                total: state.total,
                isLoading: true,
                dataState: state.dataState,
                itemAdded : state.itemAdded
            };
        case 'RECEIVE_SEARCH_PRODUCTS':
            return {
                products: action.products,
                total: action.total,
                isLoading: false,
                dataState: action.dataState,
                itemAdded : state.itemAdded
            };
        case 'ENTER_EDIT_PRODUCT':
            return {
                products: action.products,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                itemAdded : state.itemAdded
            };
        case 'EDIT_PRODUCT':
            return {
                products: action.products,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                itemAdded : state.itemAdded
            };
        case 'CHANGE_PRODUCT':
            return {
                products: action.products,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                itemAdded : state.itemAdded
            };
        case 'CANCEL_EDIT':
            return {
                products: action.products,
                total: state.total,
                isLoading: false,
                dataState: state.dataState,
                itemAdded : state.itemAdded
            };
            case 'REQUEST_ADD_ITEM':
                return {
                    products: state.products,
                    total: state.total,
                    isLoading: false,
                    dataState: state.dataState,
                    itemAdded : false
                };
            case 'ADD_ITEM':
                return {
                    products: state.products,
                    total: state.total,
                    isLoading: true,
                    dataState: state.dataState,
                    itemAdded : action.itemAdded
                };
            case 'INIT':
                return {
                    products: state.products,
                    total: state.total,
                    isLoading: false,
                    dataState: state.dataState,
                    itemAdded : false
                };
        default:
            return state;
    }
};
