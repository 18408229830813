import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { MsalContext, useIsAuthenticated, useMsal } from "@azure/msal-react";
import './NavMenu.css';
import { Trans, useTranslation, withTranslation } from 'react-i18next';
import { CookiesUtil } from '../Module/CookiesUtil';
import Translate from './Translate';
import { AccessToken } from './AccessToken';
import { Contact } from '../store/interfaces';
import { AppSelectorMenu } from './AppSelectorMenu';
import { DropDownButton, DropDownButtonItemClickEvent } from '@progress/kendo-react-buttons';
import i18n from '../i18n';

class ProfileMenu
    extends React.PureComponent<{ t: any, i18n: any, url: any, language: string, accessToken: string | null, contact: Contact }, {}> {
    static contextType = MsalContext;

    constructor(props: any) {
        super(props);
        this.toggleProfileMenu = this.toggleProfileMenu.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.onProfileMenuItemClick = this.onProfileMenuItemClick.bind(this);
    }

    public handleLogout() {
        this.context.instance.logoutRedirect().catch((e: any) => {
            console.error(e);
        });
    }

    public state = {
        url: this.getTargetLangLink(),
        language: this.getTargetLang(),
        contact: this.props.contact,
        accessToken: this.props.accessToken,
        profileMenuIsOpen: false
    };

    private getTargetLangLink() {
        const selectedLanguage = CookiesUtil.getCookieValue("userLang");
        const url = window.location.href;
        const hostAndProtocol = window.location.protocol + "//" + window.location.host;
        const path = url.replaceAll(hostAndProtocol, "")
            .replaceAll("/" + selectedLanguage, "")
            .replace("/" + selectedLanguage + "/", "")
            .replaceAll("/", "");

        var targetLang = "fr";

        if (selectedLanguage == "fr") {
            targetLang = "en";
        }

        return hostAndProtocol + "/" + targetLang + "/" + path
    }

    private getTargetLang() {
        const selectedLanguage = CookiesUtil.getCookieValue("userLang");
        var targetLang = "FR";

        if (selectedLanguage == "fr") {
            targetLang = "EN";
        }

        return targetLang;
    }

    public toggleProfileMenu(e: any) {
        this.setState({ profileMenuIsOpen: !this.state.profileMenuIsOpen });
    }

    public onProfileMenuItemClick(e: DropDownButtonItemClickEvent) {
        this.handleLogout();
    }

    public items = [
        i18n.t("Logout"),
    ];
    public render() {
        this.setState({
            url: this.getTargetLangLink(),
            language: this.getTargetLang(),
        });
        return (
            <>
                <nav className="navbar flex-md-nowrap mb-3 bg-blue height-header shadow-sm fixed-top">
                    <div className="container-fluid">

                        <div className="my-0 d-none d-lg-block">
                            <div className="media align-items-end profile-header">
                                <a href="/"><div className="profile mr-3"><img src="assets/images/Icône-Gerry_blanc_128px.png" className="rounded mb-2 img-fluid" /></div></a>
                                <div className="media-body mb-4 text-white">
                                    <h4 className="mt-0 mb-3">Gerry</h4>
                                    <ul className="nav nav-pills mb-4">
                                        <li key="0" className="nav-item dropdown">
                                            <AppSelectorMenu />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <ul className="nav">

                            <li key="0" className="nav-item">
                                {this.state.contact != undefined ? <a className="pad-icone-2222 hello-text"></a> : "loading"}
                                <div onClick={this.toggleProfileMenu}>
                                    <DropDownButton
                                        opened={this.state.profileMenuIsOpen}
                                        style={{ color: "white", marginTop: -6, paddingTop: 0 }}
                                        text={this.props.t("hello") + " " + this.state.contact.first_name + " " + this.state.contact.last_name}
                                        items={this.items}
                                        fillMode={"flat"}
                                        onItemClick={this.onProfileMenuItemClick}
                                    />
                                    <a className="pad-icone-1 text-dark" href="#"><i className="fas fa-user-circle fa-menu-3"></i></a>
                                    <i className="fas fa-chevron-down" style={{ color: "white" }}></i>
                                </div>
                            </li>
                            <li key="2" className="nav-item">
                                <a className="pad-icone-1 text-dark" href="/support-request"><i className="fas fa-exclamation-triangle fa-menu-3"></i></a>
                            </li>
                            <li key="3" className="nav-item">
                                <a className="pad-icone-1 text-dark" href="#"><i className="fas fa-bell fa-menu-3"></i></a>
                            </li>
                            <li key="7" className="nav-item">
                                <a className="menu-right-text" href={this.state.url}>{this.state.language}</a>
                            </li>
                        </ul>

                    </div>
                </nav>

            </>
        );
    }
}

export default withTranslation()(ProfileMenu);
