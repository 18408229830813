import { State, toDataSourceRequestString } from "@progress/kendo-data-query";
import { Grid, GridCellProps, GridDetailRowProps, GridColumn as Column, GridDataStateChangeEvent, GridExpandChangeEvent } from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";

import { Splitter, SplitterOnChangeEvent } from "@progress/kendo-react-layout";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Spinner } from "reactstrap";
import i18n from "../../i18n";
import { CookiesUtil } from "../../Module/CookiesUtil";
import { MoneyFormat } from "../../Module/CurrencyUtil";
import { DateTimeUtil } from "../../Module/DateTimeUtil";
import { fetchUrl, handleLogoutOnSessionExpire } from "../../Module/Fetch";
import { MoneyCell } from "../../Module/renderers";
import { updateGridFooterPager } from "../../Module/Utils";
import { ApplicationState } from "../../store";
import { CustomerOrder, SupplierOrder, SupplierOrderItem, SupplierOrderShippingAddress, SupplierOrderTotals } from "../../store/interfaces";
import * as SupplierOrderDetailStore from '../../store/Orders/SupplierOrderDetailStore';
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { SvgIcon } from "@progress/kendo-react-common";
import {
  folderIcon,
  calendarIcon,
  arrowLeftIcon,
  arrowRightIcon,
  cancelIcon,
  saveIcon
} from "@progress/kendo-svg-icons";

interface MyComponentOwnProps {
    orderId: number;
}

type SearchProductsProps =
    SupplierOrderDetailStore.SupplierOrderDetailState // ... state we've requested from the Redux store
    & typeof SupplierOrderDetailStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps
    & MyComponentOwnProps

const SupplierOrderitems = (props: SearchProductsProps) => {
    const { t, i18n } = useTranslation();
    const [orderTotals, setOrderTotals] = React.useState<SupplierOrderTotals[]>();
    const [orderItems, setOrderItems] = React.useState<SupplierOrderItem[]>([]);
    const [dataState, setDataState] = React.useState<State>({ take: 10, skip: 0 });
    const [totalRows, setTotalRows] = React.useState<number>(0);

    const pending = React.useRef<string>('');
    const lastSuccess = React.useRef<string>('');

    const dataStateChange = (e: GridDataStateChangeEvent) => {
        setDataState(e.dataState);
    }

    const fetchSupplierOrderItems = () => {
        if (pending.current || toDataSourceRequestString(dataState) === lastSuccess.current) {
            return;
        }

        pending.current = toDataSourceRequestString(dataState);
        fetchUrl(`/orders/GetSupplierOrderItems?` + pending.current + `&orderId=` + props.orderId, "GET")
            .then((response) => {
                handleLogoutOnSessionExpire(response);
                return response.json()
            })
            .then((json) => {
                lastSuccess.current = pending.current;
                pending.current = '';
                setOrderItems(json.data);
                setTotalRows(json.total);
            });
    }

    const fetchOrderTotlas = () => {
        fetchUrl(`/orders/GetSupplierOrderTotals?orderId=` + props.orderId, "GET")
            .then((response) => {
                handleLogoutOnSessionExpire(response);
                return response.json()
            })
            .then((json) => {
                setOrderTotals(json);
            });
    }

    useEffect(() => {
        fetchSupplierOrderItems();
        props.fetchOrderHeader(props.orderId);
        fetchOrderTotlas();
        props.fetchDealerShippingAddress();
    }, []);

    useEffect(() => {
        updateGridFooterPager(totalRows, i18n.t("products"), "supplier-order-details-" + props.orderId)
    });

    useEffect(() => {
        if (props.refresh) {
            props.fetchOrderHeader(props.orderId);
            props.fetchDealerShippingAddress();
            fetchOrderTotlas();
        }
    }, [props.refresh]);

    return (
        <>
            {props.supplierOrder != null && <>
                {props.isLoading && <div style={{ width: "100%", alignContent: "center" }}><Loader /></div>}
                {!props.isLoading && 


                <div className="row">

<div className="container">
                <div className="row">
                    <div className="col-sm">
                    <div className="list-fourn mb-3">
                                <div className="list-header-fourn" style={{ height: 64 }}>
                                    <div className="row">
                                        <div className="col-auto mr-auto title-fourn">{i18n.t("orderDetails")}</div>
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="col-lg-12 col-12">
                                        <div className="list-body-fourn">
                                            <div className="row no-gutters mb-3">
                                                <div className="text-fourn"><b>{i18n.t("orderNumber")}</b> : {props.supplierOrder?.po_number}</div>
                                             </div>
                                            <div className="row no-gutters mb-3">
                                                <div className="text-fourn"><b>{i18n.t("orderStatus")}
                                                    </b> : {props.supplierOrder?.order_status_name}
                                                    {props.supplierOrder.order_status_url_svg != null && 
                                                    <img src={props.supplierOrder.order_status_url_svg} width={"32px"} height={"32px"} />}
                                                </div>
                                            </div>
                                            <div className="row no-gutters mb-3">
                                                <div className="text-fourn"><b>{i18n.t("confirmationNumber")}</b>: {props.supplierOrder?.rtn_order_no}</div>
                                            </div>
                                            <div className="row no-gutters mb-3">
                                                <div className="text-fourn"><b>{i18n.t("orderMethod")}</b>: [{props.supplierOrder.order_source_name}]</div>
                                            </div>
                                            <div className="row no-gutters mb-3">
                                                <div className="text-fourn"><b>{i18n.t("date")}</b>: {DateTimeUtil.toUserLocalDateTIme(props.supplierOrder?.supplier_order_date.toString())}</div>
                                            </div>
                                            <div className="row no-gutters mb-3">
                                                <div className="text-fourn"><b>{i18n.t("buyer")}</b>: {props.supplierOrder.buyer_name}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className="col-sm">
                    <div className="list-fourn mb-3">
                                <div className="list-header-fourn" style={{ height: 64 }}>
                                    <div className="row">
                                        <div className="col-auto mr-auto title-fourn">{props.supplierOrder?.supplier_name}</div>
                                        <div className="col-auto"><img src={props.supplierOrder.supplogo_url} style={{ width: 260, height: 44 }} /></div>
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-lg-12 col-12">
                                        <div className="list-body-fourn">
                                            <div className="row no-gutters mb-3">
                                                <div className="col-6">
                                                    <div className="text-fourn"><b>{i18n.t("contact")}:</b> {props.supplierOrder.salesrep_first_name} {props.supplierOrder.salesrep_last_name}</div>
                                                </div>
                                            </div>
                                            <div className="row no-gutters mb-3">
                                                <div className="col-6">
                                                    <div className="text-fourn"><b>{i18n.t("phone")}:</b> {props.supplierOrder.salesrep_phone}</div>
                                                </div>
                                            </div>
                                            <div className="row no-gutters mb-3">
                                                <div className="col-6">
                                                    <div className="text-fourn"><b>{i18n.t("email")}:</b> {props.supplierOrder.salesrep_email}</div>
                                                </div>
                                            </div>
                                            <div className="row no-gutters mb-3">

                                                <div className="col-6">
                                                    <div className="text-fourn"><b>{i18n.t("accountNumber")}:</b> <b>{props.supplierOrder.account_number}</b></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                                </div>
                    <div className="col-sm">
                    
                    <div className="list-fourn mb-3">
                                <div className="list-header-fourn" style={{ height: 64 }}>
                                    <div className="row">
                                        <div className="col-auto title-fourn">{i18n.t('shipTo')}
                                            {!props.editShipping && <img src="assets/images/edit_icon.png" onClick={props.enterEditShipping} style={{ marginLeft: 20 }} />}
                                        </div>
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-lg-12 col-12">
                                        <div className="list-body-fourn">
                                                    {   
                                                        !props.editShipping && 
                                                        <> {props.updatedShippingAddresses?.map((address: SupplierOrderShippingAddress) => {
                                                            return <>
                                                               <div className="row no-gutters mb-3">{address.company_name} </div>  
                                                               <div className="row no-gutters mb-3"> {address.street1} {address.street2}</div>
                                                               <div className="row no-gutters mb-3">{address.city} {address.province} {address.zip} {address.country} </div>
                                                               <div className="row no-gutters mb-3"> {i18n.t('phone')} : {address.phone} </div>
                                                                <br />
                                                                <div className="row no-gutters mb-3"><b>{i18n.t('contact')}</b>: {address.first_name} {address.last_name} </div>
                                                                <div className="row no-gutters mb-3"><b>{i18n.t('phone')}</b> : {address.phone} </div>
                                                                <div className="row no-gutters mb-3"><b>{i18n.t('email')}</b>: {address.email} </div>
                                                                
                                                            </>
                                                        }
                                                        )}</>
                                                    }
                                                {props.editShipping && <>

                                                <div className="form-group row no-gutters justify-content-end">
       
                                                    <div className="col-md-8">


                                                            <>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="isdropship" id="livraison1" onClick={(e) => { props.onChangeEUI(e); props.onChangeShippingLocation(e) }} value="false" checked={!props.updatedEUI?.isdropship as boolean} />
                                                                    <label className="form-check-label input-label" >{i18n.t("inStore")}</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="isdropship" id="livraison2" checked={props.updatedEUI?.isdropship as boolean} onClick={(e) => { props.onChangeEUI(e); props.onChangeShippingLocation(e) }} value="true" />
                                                                    <label className="form-check-label input-label"  >{i18n.t("directShipping")}</label>
                                                                </div>
                                                            </>
                                                        
                                                    </div>
                                                </div>
                                                            <div className="form-group row no-gutters">
                                                                <label className="col-sm-4 col-form-label input-label">{props.editShipping && <>*</>}{i18n.t("name")}</label>
                                                                <div className="col-sm-8">
                                                                    {props.editShipping &&
                                                                        <input readOnly={props.disableEditShippingAddress} disabled={props.disableEditShippingAddress} type="text" className="form-control form-control-sm input-fourn" id="name" name="name" onChange={e => props.onChangeShippingData(e, props.orderId)} value={props.updatedShippingAddresses != null ? props.updatedShippingAddresses[0].first_name : ""} />
                                                                    }

                                                                </div>
                                                            </div>


                                                            <div className="form-group row no-gutters">
                                                                <label className="col-sm-4 col-form-label input-label">{props.editShipping && <>*</>}{i18n.t("address1")}</label>
                                                                <div className="col-sm-8">
                                                                    {props.editShipping &&
                                                                        <input readOnly={props.disableEditShippingAddress} disabled={props.disableEditShippingAddress} type="text" className="form-control form-control-sm input-fourn" id="adress1" name="street1" onChange={e => props.onChangeShippingData(e, props.orderId)} value={props.updatedShippingAddresses != null ? props.updatedShippingAddresses[0].street1 : ""} />
                                                                    }
                                                                </div>
                                                            </div>


                                                            <div className="form-group row no-gutters">
                                                                <label className="col-sm-4 col-form-label input-label">{props.editShipping && <>*</>}{i18n.t("address2")}</label>
                                                                <div className="col-sm-8">
                                                                    {props.editShipping &&
                                                                        <input readOnly={props.disableEditShippingAddress} disabled={props.disableEditShippingAddress} type="text" className="form-control form-control-sm input-fourn" id="adress2" name="street2" onChange={e => props.onChangeShippingData(e, props.orderId)} value={props.updatedShippingAddresses != null ? props.updatedShippingAddresses[0].street2 : ""} />
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className="form-group row no-gutters">
                                                                <label className="col-sm-4 col-form-label input-label">{props.editShipping && <>*</>}{i18n.t("postalCode")}</label>
                                                                <div className="col-sm-8">
                                                                    {props.editShipping &&
                                                                        <input type="text" readOnly={props.disableEditShippingAddress} disabled={props.disableEditShippingAddress} className="form-control form-control-sm input-fourn" id="code_postal" name="zip" onChange={e => props.onChangeShippingData(e, props.orderId)} value={props.updatedShippingAddresses != null ? props.updatedShippingAddresses[0].zip : ""} />
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className="form-group row no-gutters">
                                                                <label className="col-sm-4 col-form-label input-label">{props.editShipping && <>*</>}{i18n.t("city")}</label>
                                                                <div className="col-sm-8">
                                                                    {props.editShipping &&
                                                                        <input type="text" readOnly={props.disableEditShippingAddress} disabled={props.disableEditShippingAddress} className="form-control form-control-sm input-fourn" id="ville" name="city" onChange={e => props.onChangeShippingData(e, props.orderId)} value={props.updatedShippingAddresses != null ? props.updatedShippingAddresses[0].city : ""} />
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className="form-group row no-gutters">
                                                                <label className="col-sm-4 col-form-label input-label">{props.editShipping && <>*</>}{i18n.t("provnice")}</label>
                                                                <div className="col-sm-3">
                                                                    {props.editShipping &&
                                                                        <input type="text" readOnly={props.disableEditShippingAddress} disabled={props.disableEditShippingAddress} className="form-control form-control-sm input-fourn" id="province" name="province" onChange={e => props.onChangeShippingData(e, props.orderId)} value={props.updatedShippingAddresses != null ? props.updatedShippingAddresses[0].province : ""} />
                                                                    }
                                                                </div>
                                                                <label className="col-sm-2 col-form-label input-label" style={{ textAlign: "right", paddingRight: "7px" }}>{props.editShipping && <>*</>}{i18n.t("country")}</label>
                                                                <div className="col-sm-3">
                                                                    {props.editShipping &&
                                                                        <input type="text" readOnly={props.disableEditShippingAddress} disabled={props.disableEditShippingAddress} className="form-control form-control-sm input-fourn" id="pays" name="country" onChange={e => props.onChangeShippingData(e, props.orderId)} value={props.updatedShippingAddresses != null ? props.updatedShippingAddresses[0].country : ""} />
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className="form-group row no-gutters justify-content-end">
                                                                <div className="col-sm-8 text-center mt-3">
                                                                    {props.editShipping && <>
                                                                        <button type="button" className="btn btn-outline-fourn btn-sm" onClick={(e) => { props.exitEditShipping(); props.onCancelEditShipping() }}>{i18n.t("cancel")}</button>
                                                                        <button type="button" className="btn btn-fourn btn-sm" onClick={(e) => { props.onSaveShipping(); props.onSaveEUI(false) }}>{i18n.t("save")}</button>

                                                                    </>}
                                                                </div>
                                                            </div>
                                                        </>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
                <div className="row">

                                <div className="col-sm">
                                    <div className="list-fourn mb-3">
                                        <div className="list-header-fourn" style={{ height: 64 }}>
                                            <div className="row">
                                                <div className="col-auto title-fourn">{i18n.t("shippingMethod")}
                                                </div>

                                            </div>
                                        </div>


                                    </div>    
                                </div>

    <div className="col-sm">

    <div className="list-fourn mb-3">
                                <div className="list-header-fourn" style={{ height: "64px" }}>
                                    <div className="row">
                                        <div className="col-auto title-fourn">{i18n.t("reference")}</div>
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-lg-12 col-12">
                                        <div className="list-body-fourn">
                                            <div className="row no-gutters mb-3">
                                                <div className="col-12">
                                                    <div className="text-fourn">{i18n.t("estaffNumber")}</div>
                                                    <div className="text-bold">{props.supplierOrder.estaff_order_id}</div>
                                                </div>
                                            </div>
                                            {props.supplierOrder.external_links != null && <>
                                                <div className="row no-gutters">
                                                    <div className="col-12">
                                                        <div className="text-fourn">{i18n.t("externalSystem")}</div>
                                                    </div>
                                                </div>
                                                <div className="row no-gutters mb-3">
                                                    <div className="col-6">
                                                        <div className="text-normal">
                                                            {props.supplierOrder?.external_links_parsed?.map(externaLink => {
                                                                return <>{externaLink.si_name} </>
                                                            })
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="text-normal">
                                                            {props.supplierOrder?.external_links_parsed?.map(externaLink => {
                                                                return <>{externaLink.external_number} </>
                                                            })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </>

                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>    </div>

                                <div className="col-sm">





                                <div className="list-fourn mb-3">
                                <div className="list-header-fourn" style={{ height: 64 }}>
                                    <div className="row">
                                        <div className="col-auto title-fourn">{i18n.t('costSummary')}
                                        </div>
                                    </div>
                                </div>
                                        <div className="cost-summary cost-summary-order-header">
                                        <div className="cost-summary cost-summary-order-header">
                                            {orderTotals?.map(function (t) {
                                                return <div className="cost-summary-row">
                                                    <span className="cost-summar-labels">{t.amount_name}</span>
                                                    <span className="cost-summar-amount">
                                                    {!props.enterEditShippingFees && t.amount_name.replace(":", "") == "Livraison" && t.amount != null ? MoneyFormat(t.amount.toString(), props.supplierOrder?.currency_code) : ""} 
                                                    {t.amount_name.replace(":", "") != "Livraison" && t.amount != null ? MoneyFormat(t.amount.toString(), props.supplierOrder?.currency_code) : ""} 
                                                    {!props.enterEditShippingFees && t.amount_name.replace(":", "") == "Livraison" && 
                                                        <img src="assets/images/edit_icon.png" height="15px" onClick={props.onEnterEditShippingFees} style={{ cursor: "pointer", float: "left" }} />}
                                                    {props.enterEditShippingFees && t.amount_name.replace(":", "") == "Livraison" && 
                                                            <>
                                                                <div style={{float: "left"}}>
                                                                    <NumericTextBox
                                                                            placeholder="please enter value"
                                                                            width={100}
                                                                            defaultValue={props.shippingFees}
                                                                            format="n2"
                                                                            onBlur={props.onChangeShippingFees}
                                                                        />
                                                               </div>
                                                                <div  style={{float: "left", cursor: "pointer"}}>
                                                                    <Button onClick={props.onUpdateShippingFees} svgIcon={saveIcon} />
                                                                </div>
                                                                <div style={{float: "left", cursor: "pointer"}}>
                                                                    <Button onClick={props.OnCancelEditShippingFees}  svgIcon={cancelIcon} />
                                                                </div>
                                                            </>
                                                    }
                                                    </span>
                                                </div>
                                            })}
                                </div>

                                        </div>
                                </div>


                                </div>
                            </div>
                        </div>

                    </div>}

            </>
            }
            <LocalizationProvider language={i18n.language}>
                <IntlProvider locale={i18n.language}>
                    <div className={"supplier-order-details-" + props.orderId + ""}>
                        <Grid
                            resizable={true}
                            style={{ height: "400px" }}
                            data={orderItems}
                            {...dataState}
                            total={totalRows}
                            sortable={true}
                            onDataStateChange={dataStateChange}
                            pageable={{
                                pageSizes: true
                            }}
                            pageSize={10}
                        >
                            
                            <Column field="sku" title={i18n.t("SKU")} />
                            <Column field="mfr_part_number" title={i18n.t("mfrPartNumber")} width={350} />
                            <Column field="supplier_descr" title={i18n.t("supplierDesc")} width={350} />
                            <Column field="mfr_part_number_estaff" title={i18n.t("#estaff")} width={150} />
                            <Column field="manufacturer_name" title={i18n.t("manufacturer")}  width={150}/>
                            <Column field="quantity" title={i18n.t("qty")} />
                            <Column field="cost" title={i18n.t("expectedCost")} width={150} cell={MoneyCell} />
                            <Column field="invoiced_cost" title={i18n.t("invoicedCost")} width={150} cell={MoneyCell} />
                            <Column title={i18n.t("warehouse")}
                                children={[
                                    {
                                        headerClassName: "hidden",
                                        field: "ship_from_warehouse_name"
                                    },
                                    {
                                        headerClassName: "hidden",
                                        field: "ship_from_warehouse_zip"
                                    }
                                ]}
                            />
                            <Column field="serial_list" title={i18n.t("serialList")} width={150} />
                            <Column field="estaff_customer_order_number" title={i18n.t("estaffCustomerOrderNumber")} width={200} />
                            <Column field="comment" title={i18n.t("comment")} width={150} />
                            <Column field="rtn_line_error" title={i18n.t("status")} width={150} />
                        </Grid>
                    </div>

                </IntlProvider>
            </LocalizationProvider>

            <div className="cost-summary">
                <h3>{i18n.t("costSummary")}</h3>
                {orderTotals?.map(function (t) {
                    return <div>
                        <span className="cost-summar-labels">{t.amount_name}</span>
                        <span className="cost-summar-amount">{t.amount != null ? MoneyFormat(t.amount.toString(), props.supplierOrder?.currency_code) : ""} </span></div>
                })}

            </div>

        </>
    )
}

export default connect(
    (state: ApplicationState, ownProps: MyComponentOwnProps) => (
        {
            ...state.SupplierOrderDetail,
            orderId: ownProps.orderId,
        }), // Selects which state properties are merged into the component's props
    SupplierOrderDetailStore.actionCreators,// Selects which action creators are merged into the component's props

)(SupplierOrderitems as any); // eslint-disable-line @typescript-eslint/no-explicit-any