import { NotificationMessage } from "../store/interfaces";

export class CookiesUtil {
    static COOKIE_KEY_SELECTED_PRODUCT = "selectedProduct";
    static COOKIE_KEY_ACCESS_TOKEN = "accessToken";

    static getCookieValue = (name: string) => {
        var cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''

        return decodeURIComponent(cookieValue);
    }
    

    static getNotificationMessgaes = () : NotificationMessage[] => {
        var cookieValue = CookiesUtil.getCookieValue("noticationMessages");
        var notificationMessages : NotificationMessage[] = [];
        if (cookieValue) {
            notificationMessages = JSON.parse(cookieValue);
        }

        return notificationMessages;
    }

    static cleanUpNotificationMessages = () => {
        var messages = CookiesUtil.getNotificationMessgaes();
        
        messages = messages.filter(m => (new Date(m.created).getTime() + m.expire) >= new Date().getTime());

        var messagesStringyFied = JSON.stringify(messages);

        CookiesUtil.setCookie("noticationMessages", messagesStringyFied, 1);

    }

    static setNotificationMessages = (notificationMessage : NotificationMessage) => {
        var messages = CookiesUtil.getNotificationMessgaes();
        notificationMessage.message = encodeURIComponent(notificationMessage.message);
        messages.push(notificationMessage);

        var messagesStringyFied = JSON.stringify(messages);

        CookiesUtil.setCookie("noticationMessages", messagesStringyFied, 1);
    }

    static removeNotificationMessage = (notificationMessage : NotificationMessage) => {
        var messages = CookiesUtil.getNotificationMessgaes();
        messages = messages.filter(e => e.subject != notificationMessage.subject && e.message != e.message);

        var messagesStringyFied = JSON.stringify(messages);

        CookiesUtil.setCookie("noticationMessages", messagesStringyFied, 1);
    }

    static setNotificationMessageAsRead = (notificationMessage : NotificationMessage) => {
        var messages = CookiesUtil.getNotificationMessgaes();

        var newMessages = messages.map(function(m) {
            if (m.message == notificationMessage.message) {
                m.read = true;
            }

            return m;
        });

        var messagesStringyFied = JSON.stringify(newMessages);
        CookiesUtil.setCookie("noticationMessages", messagesStringyFied, 1);

    }

    static removeReadNotifications = () => {
        var messages = CookiesUtil.getNotificationMessgaes();
        messages = messages.filter(e => !e.read);

        var messagesStringyFied = JSON.stringify(messages);

        CookiesUtil.setCookie("noticationMessages", messagesStringyFied, 1);
    }

    static setCookie = (name: string, value: any, days: number) => {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (encodeURI(value) || "") + expires + "; path=/";
    }
}