import React from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { Navbar } from "reactstrap";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export const UserLoginMenu = (props) => {
    const isAuthenticated = useIsAuthenticated();
    const { instance, inProgress, accounts } = useMsal();
    const name = accounts[0] && accounts[0].name;

    React.useEffect(() => {
        if (inProgress === InteractionStatus.None && !isAuthenticated) {
            instance.loginRedirect();
        }
    });

    return (
        <>
           
        </>
    );
};