export const ORDER_STATUS_ID_PENDING = 10;
export const ORDER_STATUS_ID_PROCESSING = 20;
export const ORDER_STATUS_ID_CANCELED = 30;
export const ORDER_STATUS_ID_COMPLETED = 14000;
export const ORDER_STATUS_ID_COMPLETED_OLD = 40;
export const ORDER_STATUS_ID_READY_FOR_PICK_UP = 10000;
export const ORDER_STATUS_ID_SHIPPED = 9000;
export const ORDER_STATUS_ID_ERROR = 50;
export const ORDER_STATUS_ID_INIT = 0;
export const ORDER_STATUS_ID_SUPPLIER_ERROR = 25;
export const ORDER_STATUS_ID_APPROVED = 4000;
export const LANGUAGES = [{id: 1, code: "fr"},{id: 2, code: "en"}];
export const CUSTOMER_ORDER_DETAILS_TAB_ID_PREFIX = "CustomerOrderDetail_";
export const SUPPLIER_ORDER_DETAILS_TAB_ID_PREFIX = "SupplierOrderDetail_";
export const SUPPLIER_ORDER_GRID_TAB_ID_PREFIX = "SupplierOrderGrid_";
export const CUSTOMER_ORDER_GRID_TAB_ID_PREFIX = "CustomerOrderGrid_";




