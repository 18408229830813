export const  MoneyFormat = (price : string, sign = '$') => {
    var priceFloat = parseFloat(price);  
    var isNegative = priceFloat < 0;
    priceFloat = Math.abs(priceFloat)
    const pieces = priceFloat.toFixed(2).split('');
    let ii = pieces.length - 3
    while ((ii-=3) > 0) {
      pieces.splice(ii, 0, ',')
    }
    var leadSign = "";
    if (isNegative) {
      leadSign = "-";
    }
    return leadSign + "" + pieces.join('') + " " + CurrencyCode(sign)
  }

  export const CurrencyCode = (code : string)  => {
      if (code == null || code == "CAD") {
        return "$"
      } else {
        return code;
      }
  }