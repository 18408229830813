import { SpinnerSize } from '@fluentui/react';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';
import i18n from '../i18n';
import * as axios from 'axios';
import { CookiesUtil } from '../Module/CookiesUtil';
import { Contact } from '../store/interfaces';
import {
    Link,
    Stack,
    StackItem,
    MessageBar,
    MessageBarType,
    ChoiceGroup,
    IStackProps,
    MessageBarButton,
    Text,
    IChoiceGroupStyles,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { fetchUrl } from '../Module/Fetch';


export interface ITextFieldCustomRenderExampleState {
    isCalloutVisible: boolean;
}

export interface IButtonExampleProps {
    // These are set based on the toggles shown above the examples (not needed in real code)
    disabled?: boolean;
    checked?: boolean;
}

const SupportRequest: React.FunctionComponent<IButtonExampleProps> = (props: any) => {
    const { t, i18n } = useTranslation();
    const [accessToken, setAccessToken] = React.useState<string>(CookiesUtil.getCookieValue("accessToken"));
    const [contact, setContact] = React.useState<Contact>(JSON.parse(CookiesUtil.getCookieValue("contact")));
    const [submitIsDisabled, setSubmitIsDisabled] = React.useState(false);
    const [requestSubmitted, setRequestSubmitted] = React.useState<boolean>(false);
    const [firstName, setFirstName] = React.useState<string>(contact.first_name);
    const [firstNameError, setFirstNameError] = React.useState<string>();
    const [lastNameError, setLastNameError] = React.useState<string>();
    const [emailError, setEmailError] = React.useState<string>();
    const [requestTypeError, setrequestTypeError] = React.useState<string>();
    const [issueDescriptionError, setIssueDescriptionError] = React.useState<string>();

    const [lastName, setLastName] = React.useState(contact.last_name);
    const [email, setEmail] = React.useState(contact.email);
    const [issueDescription, setIssueDescription] = React.useState<string>("");
    const [requestType, setRequestType] = React.useState<string>(i18n.t("chooseAnOption"));
    const [attachment, setAttachment] = React.useState<Blob | null | undefined>();
    const [attachmentError, setAttachmentError] = React.useState<string>("");
    const [severity, setSeverity] = React.useState<string>(i18n.t("low"));

    var items = []

    const isValidForm = (): boolean => {

        setFirstNameError("");
        setLastNameError("");
        setEmailError("");
        setrequestTypeError("");
        setIssueDescriptionError("");
        setAttachmentError("");
        var formIsValid = true;
        if (attachment != null && (!attachment.type.includes("image") || attachment.size > 10000000)) {
            setAttachmentError(i18n.t("fileSizeValidation"));
            formIsValid = false;
        }

        if (requestType == null || requestType == i18n.t("chooseAnOption")) {
            setrequestTypeError(i18n.t("chooseAnOption"));
            formIsValid = false;
        }

        if (firstName == null) {
            setFirstNameError(i18n.t("thisFieldIsRequired"));
            formIsValid = false;
        }
        if (lastName == null) {
            setLastNameError(i18n.t("thisFieldIsRequired"));
            formIsValid = false;
        }

        if (email == null) {
            setEmailError(i18n.t("thisFieldIsRequired"));
            formIsValid = false;
        }

        if (issueDescription == null || issueDescription == "") {
            setIssueDescriptionError(i18n.t("thisFieldIsRequired"));
            formIsValid = false;
        }

        return formIsValid;

    };

    const onChnageRequestType = (ev: React.FormEvent<HTMLSelectElement>): void => {
        setRequestType(ev.currentTarget.value);
    };

    const onChnageSeverity = (ev: React.FormEvent<HTMLSelectElement>): void => {
        setSeverity(ev.currentTarget.value);
    };

    const onSubmit = (ev: React.MouseEvent<HTMLButtonElement>): void => {
        if (isValidForm()) {
            setSubmitIsDisabled(true);
            const data = new FormData()
            data.append('FirstName', firstName);
            data.append('LastName', lastName)
            data.append('Email', email)
            data.append('RequestType', requestType)
            data.append('severity', severity)
            data.append('IssueDescription', issueDescription)
            data.append('attachment', attachment as (string | Blob))

            const config = {
                headers: { Authorization: `Bearer ${accessToken}` }
            };

            fetchUrl('/Support/SubmitRequest', "POST", { body: data })
                .then(function (response: any) {
                    setSubmitIsDisabled(false);
                    setRequestSubmitted(response.data);
                    setRequestType(i18n.t("chooseAnOption"));
                    setSeverity("low");
                    setIssueDescription("");
                    setAttachment(null);
                    window.scrollTo(0, 0);
                })
                .catch(function (error: any) {
                    setSubmitIsDisabled(false);
                });
        }
    };

    return (
        <>
                <div hidden={!requestSubmitted}><MessageBar messageBarType={MessageBarType.success}> Request submitted successfully.</MessageBar> </div>
                <form>
                    <div className="list mt-4 mb-4 center">
                        <div className="list-header ">
                            <div className="row">
                                <div className="col-md-12 list-title-bold">{i18n.t("supportFormName")}</div>
                            </div>
                        </div>

                        <div className="list-body">

                            <div className="mt-4">
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="nom" className="label-form">{i18n.t("name")}</label>
                                        <input type="text" className="form-control form-input" id="nom" name="nom" placeholder="Nom" value={firstName} readOnly />
                                        {firstNameError}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="prenom" className="label-form">{i18n.t("lastName")}</label>
                                        <input type="text" className="form-control form-input" id="prenom" name="prenom" placeholder="Prénom" value={lastName} readOnly />
                                        {lastNameError}
                                    </div>
                                </div>
                                <div className="form-row">

                                    <div className="form-group col-md-6">
                                        <label htmlFor="email" className="label-form">{i18n.t("email")}</label>
                                        <input type="text" className="form-control form-input" id="email" name="email" placeholder="Email" value={email} readOnly />
                                        {emailError}
                                    </div></div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="RequestType" className="label-form">{i18n.t("requestType")}*</label>
                                        <select value={requestType} id="RequestType" name="RequestType" className="form-control form-input" onChange={(e) => { onChnageRequestType(e); }}>
                                            <option value={i18n.t("chooseAnOption").toString()}>{i18n.t("chooseAnOption")}</option>
                                            <option value={i18n.t("issueTypeBug").toString()}>{i18n.t("issueTypeBug")}</option>
                                            <option value={i18n.t("issueTypeCustomerOrder").toString()}>{i18n.t("issueTypeCustomerOrder")}</option>
                                            <option value={i18n.t("issueTypeSupplierOrder").toString()}>{i18n.t("issueTypeSupplierOrder")}</option>
                                        </select>
                                        <div className="text-danger">{requestTypeError}</div>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="degre" className="label-form">{i18n.t("severity")}</label>
                                        <select value={severity} id="degre" name="degre" className="form-control form-input" onChange={(e) => { onChnageSeverity(e); }}>
                                            <option value={i18n.t("low").toString()}>{i18n.t("low")}</option>
                                            <option value={i18n.t("middle").toString()}>{i18n.t("middle")}</option>
                                            <option value={i18n.t("high").toString()}>{i18n.t("high")}</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="demande" className="label-form">{i18n.t("description")}*</label>
                                    <textarea className="form-control form-input" id="demande" name="demande" rows={5} onChange={event => { setIssueDescription(event.target.value); }} value={issueDescription}>{issueDescription}</textarea>
                                    <div className="text-danger">{issueDescriptionError}</div>
                                </div>

                                <div className="form-group row gutters">
                                    <label htmlFor="file" className="col-sm-3 label-form-small">{i18n.t("ScreenShot")}</label>
                                    <div className="form-group col-md-4">
                                        <input type="file" id="file2" className="custom-file-input form-input" onChange={event => { setAttachment(event.currentTarget.files![0]); }} />
                                        <span className="custom-file-control"></span>
                                        <br/>
                                        <div className="text-danger"> {attachmentError} </div>
                                    </div>
                                </div>
                                <div className="form-row justify-content-end" style={{ marginTop: "150px" }}>
                                    <Spinner size="3" hidden={!submitIsDisabled} />
                                    <button className="btn btn-save-form" type="button">{i18n.t("reset")}</button>
                                    <button className="btn btn-submit-form" type="button" onClick={onSubmit} >{i18n.t("submit")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

        </>

    );
};
export default connect()(SupportRequest);
